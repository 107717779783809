import MyAxiosInstance from "../utils/MyAxiosInstance";
import { USERS_URL, USER_ID_URL, USER_URL } from "../resources/ApiUrls";

const getUsers = () => MyAxiosInstance.get(USERS_URL);

const postUser = (body) =>
    MyAxiosInstance.post(USER_URL, body);
const putUser = (id, body) =>
    MyAxiosInstance.put(USER_ID_URL(id), body);
const deleteUser = (id) =>
    MyAxiosInstance.delete(USER_ID_URL(id));

const exports = {
    getUsers,
    postUser,
    putUser,
    deleteUser,
};
export default exports;
