import { Box, Typography } from "@mui/material"
import { AppColors } from "resources/AppColors"

function NoDataTableBody({ selectedEntity, firstVisitMessage, noDataMessage }) {
  return (
    <Box
      p={10}
      textAlign="center"
      bgcolor={"white"}
      sx={{
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
      }}
    >
      <Typography variant="title_bold" sx={{ color: AppColors.PRIMARY }}>
        {selectedEntity ?
          noDataMessage
          :
          firstVisitMessage
        }
      </Typography>
    </Box>
  )
}

export default NoDataTableBody