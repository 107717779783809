export default class FormModal {

  constructor(title, description, privado, internalCode, tags, id, dateCreation, translations, originalLocale) {
    this.title = title;
    this.description = description;
    this.privado = privado;
    this.id = id;
    this.internalCode = internalCode;
    this.dateCreation = dateCreation;
    this.tags = tags;
    this.translations = translations;
    this.originalLocale = originalLocale
  }

  get createToJSON() {
    const today = new Date()
    today.setHours(today.getHours() + 1)
    //const date = today.getDate() + "/" + parseInt(today.getMonth() + 1) + "/" + today.getFullYear();

    return {
      "internalCode": this.internalCode,
      "dateCreation": today,
      "slug": this.title,
      "periodicity": [{
        "type": "IMMEDIATILLY",
        "isReminder": true,
        "periodicityCronExpression": "Lorem_ipsum",
        "hour": "00:00:00",
        "date": "10/10/2010",
        "maxReminders": 2
      }],
      "finishDate": null,
      "formStatus": "PUBLISHED",
      "visibility": this.privado,
      "tags": this.tags,
      "private": false,
      "personal": true,
      "defaultLocaleId": this.originalLocale,
      "formTranslation": this.translations
    }
  }

  get modifyToJSON() {
    return {
      "id": `${this.id}`,
      "internalCode": this.internalCode,
      "slug": this.title,
      "dateCreation": this.dateCreation,
      "periodicity": [{
        "id": "cd633339-eb93-4ecd-8bd3-22096cee625a",
        "type": "IMMEDIATILLY",
        "isReminder": false,
        "periodicityCronExpression": "PERIOD_Lorem_ipsum",
        "hour": "00:00:00",
        "date": "11/11/2011",
        "maxReminders": 1
      }],
      "finishDate": null,
      "formStatus": "PUBLISHED",
      "visibility": this.privado,
      "tags": this.tags,
      "private": false,
      "personal": false,
      "defaultLocaleId": this.originalLocale,
      "formTranslation": this.translations
    };
  }

}
