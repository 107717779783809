/* eslint-disable no-unused-vars */
// Base
// const _DOMAIN = "https://backend.studies.uhda.health"; // Domain of the STUDIES server
// const _DOMAIN = "https://backend.cbm.uhda.health"; // Domain of the PROD server
const _DOMAIN = "https://test.backend.cbm.uhda.health"; // Domain of the TEST server
// const _DOMAIN = "https://staging.backend.cbm.uhda.health" // * Domain of the STAGE server

const _API_PATH = "api"; // Path to the API
export const BASE_PATH = `${_DOMAIN}/${_API_PATH}`; // Base URL of the backend

// Paths auths
const _LOGIN_PATH = "/auth/login";
const _RESET_STEP1 = "/auth/reset-step1";
const _RESET_STEP2 = "/auth/reset-step2";

// Path studies
const _STUDIES_PATH = "/studies";
const _LOCALES_PATH = "/locales";
const _STUDIES_EN_PATH = "/studies/locale/en";
const _STUDIES_ES_PATH = "/studies/locale/es";
const _STUDY_PATH = "/study";
const _USER_PATH = "/user";
const _USERS_PATH = "/users";
const _TAG_PATH = "/tag";
const _TAGS_PATH = "/tags";
const _STUDY_MANAGER_PATH = "/study-manager";
const _TEAM_MEMBERS_PATH = "/team-members";
const _TEAM_MEMBER_PATH = "/team-member";
const _STUDY_PARTICIPANTS_PATH = "/participant-invitations";
const _STUDY_PARTICIPANT_PATH = "/participant";
const _PARTICIPANTS_PATH = "/participants";
const _PARTICIPANT_PATH = "/participant";
const _SUMARY_FORMS_EN_PATH = "/summary-forms";
const _FORM_PATH = "/form";
const _QUESTIONS_PATH = "/questions";
const _QUESTION_PATH = "/question";
const _ROLES_PATH = "/roles";
const _SECTIONS_PATH = "/sections";
const _SECTION_PATH = "/section";
const _SUMMARY_PATH = "/summary";
const _DUPLICATE_PATH = "/duplicate";
const _EXPORT_PATH = "/export";
const _IMPORT_PATH = "/import";

// Endpoints
export const LOGIN_URL = `${_LOGIN_PATH}`;
export const RESET_STEP1_URL = () => `${_RESET_STEP1}`;
export const RESET_STEP2_URL = () => `${_RESET_STEP2}`;
export const STUDIES_URL = () => `${_STUDIES_PATH}`;
export const LOCALES_URL = `${_LOCALES_PATH}`;
export const STUDIES_EN_URL = () => `${_STUDIES_EN_PATH}`;
export const STUDIES_ES_URL = () => `${_STUDIES_ES_PATH}`;
export const STUDY_URL = `${_STUDY_PATH}`;
export const MOD_STUDY_URL = `${_STUDY_PATH}`;

export const USER_URL = `${_USER_PATH}`
export const USERS_URL = `${_USERS_PATH}`
export const USER_ID_URL = (id) => `${_USERS_PATH}/${id}`

export const TAG_URL = `${_TAG_PATH}`
export const TAGS_URL = `${_TAGS_PATH}`
export const TAG_ID_URL = (id) => `${_TAGS_PATH}/${id}`

export const UPDATE_TEAM_MEMBERS_URL = (studyId, teamMemberId) =>
  `${_STUDY_PATH}/${studyId}${_TEAM_MEMBER_PATH}/${teamMemberId}`;
export const TEAM_MEMBERS_URL = (id) =>
  `${_STUDY_PATH}/${id}${_TEAM_MEMBERS_PATH}`;
export const COLLABORATORS_URL = () => _TEAM_MEMBERS_PATH;
export const POST_TEAM_MEMBERS_URL = (id) =>
  `${_STUDY_PATH}/${id}${_TEAM_MEMBER_PATH}`;
export const PARTICIPANTS_URL = `${_PARTICIPANTS_PATH}`;
export const STUDY_PARTICIPANTS_URL = (id) =>
  `${_STUDY_PATH}/${id}${_PARTICIPANTS_PATH}`;
export const STUDY_PARTICIPANT_URL = (id) =>
  `${_STUDY_PATH}/${id}${_STUDY_PARTICIPANT_PATH}`;
export const DELETE_EDIT_STUDY_PARTICIPANT_URL = (id, participantId) =>
  `${_STUDY_PATH}/${id}${_PARTICIPANT_PATH}/${participantId}`;
export const DELETE_STUDY = (id) => `${_STUDY_PATH}/${id}`;
export const DELETE_FORM = (id) => `${_FORM_PATH}/${id}`;
export const DUPLICATE_FORM = (studyId, formId) => `${_STUDY_PATH}/${studyId}${_FORM_PATH}/${formId}${_DUPLICATE_PATH}`;

export const QUESTIONS = (formId, studyId) =>
  `${_STUDIES_PATH}/${studyId}${_FORM_PATH}/${formId}${_QUESTIONS_PATH}`;
export const POST_QUESTION = `${_QUESTION_PATH}`;

export const POST_QUESTIONS = () => `${_QUESTIONS_PATH}`;

export const PUT_QUESTION = (id) => `${_QUESTION_PATH}/${id}`;

export const DELETE_QUESTION = (id) => `${_QUESTION_PATH}/${id}`;

export const DUPLICATE_QUESTION = (id) => `${_QUESTION_PATH}/${id}${_DUPLICATE_PATH}`;

export const DELETE_QUESTION_SECTION = (sectionId, questionId) =>
  `${_SECTION_PATH}/${sectionId}${_QUESTION_PATH}/${questionId}`;

export const CHANGE_QUESTIONS_ORDER = (formId) => `${_FORM_PATH}/${formId}${_QUESTIONS_PATH}`


export const GET_ROLES_URL = () => `${_ROLES_PATH}`;
//Sections API CALL
export const SECTIONS = (formId) => `${_FORM_PATH}/${formId}${_SECTIONS_PATH}`;
export const POST_SECTION = (studyId, formId) =>
  `${_STUDY_PATH}/${studyId}${_FORM_PATH}/${formId}${_SECTION_PATH}`;
export const PUT_SECTION = (studyId, formId) =>
  `${_STUDY_PATH}/${studyId}${_FORM_PATH}/${formId}${_SECTION_PATH}`;
export const DELETE_SECTION = (studyId, formId, sectionId) =>
  `${_STUDY_PATH}/${studyId}${_FORM_PATH}/${formId}${_SECTION_PATH}/${sectionId}`;

//Summary API CALL
export const GET_SUMMARY = (formId) => `${_FORM_PATH}/${formId}${_SUMMARY_PATH}`;

export const CHANGE_SUMMARY_ORDER = (formId) => `${_FORM_PATH}/${formId}/summary-order`;

export const SUMMARY = (formId) => `${_FORM_PATH}/${formId}${_SUMMARY_PATH}`;
export const DELETE_QUESTION_SUMMARY = (summaryId, questionId) =>
  `${_SUMMARY_PATH}/${summaryId}${_QUESTION_PATH}/${questionId}`;
export const DELETE_SUMMARY = (studyId, formId, summaryId) =>
  `${_STUDY_PATH}/${studyId}${_FORM_PATH}/${formId}${_SUMMARY_PATH}/${summaryId}`;
export const POST_SUMMARY = (studyId, formId) =>
  `${_STUDY_PATH}/${studyId}${_FORM_PATH}/${formId}${_SUMMARY_PATH}`;
export const PUT_SUMMARY = (studyId, formId) =>
  `${_STUDY_PATH}/${studyId}${_FORM_PATH}/${formId}${_SUMMARY_PATH}`;

export const FORMS_STUDY = (id) =>
  `${_STUDY_PATH}/${id}${_SUMARY_FORMS_EN_PATH}`;

export const FORM_STUDY = (studyId) => `${_STUDY_PATH}/${studyId}${_FORM_PATH}`;

export const GET_FORM = (studyId, formId) => `${_STUDY_PATH}/${studyId}${_FORM_PATH}/${formId}`

export const GET_ANSWERS = (formId, page, size) => `/form/${formId}/answers?page=${page}&perpage=${size}`

export const IMPORT_FORM = (studyId) => `${_STUDY_PATH}/${studyId}${_FORM_PATH}${_IMPORT_PATH}`;
export const EXPORT_FORM = (studyId, formId) => `${_STUDY_PATH}/${studyId}${_FORM_PATH}/${formId}${_EXPORT_PATH}`;
