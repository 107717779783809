import { IconButton, Popover } from "@mui/material"
import { Box, Typography } from "@mui/material"
import { LabelsForms } from "locale/en"
import { AppColors } from "resources/AppColors"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState } from "react";

function ConditionPopover() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? "simple-popover" : undefined;

  return <>
    <Typography variant="body_bold">
      Condition
    </Typography>
    <IconButton style={{ color: AppColors.PRIMARY }} onClick={handleClick} size="large">
      <InfoOutlinedIcon />
    </IconButton>
    <Popover
      id={idPopover}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box
        maxWidth={700}
        maxHeight={120}
        m={2}
      >
        <Typography variant="body">
          {LabelsForms.CONDITIONAL_POPOVER}
        </Typography>
      </Box>
    </Popover>
  </>;
}

export default ConditionPopover