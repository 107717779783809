/* eslint-disable camelcase */
import React, { useEffect, useState } from "react"
import { AppColors } from "../resources/AppColors"
import { CommonTexts, TableTexts } from "../locale/en"
import { FormControl, Grid, InputAdornment } from "@mui/material"
import { validateEmail } from "../utils/HelperFunctions"
import PropTypes from "prop-types"
import ErrorIcon from "@mui/icons-material/Error"
import { patientsMock } from "../mocks/patientsMock"
import DialogUhda from "../components/DialogUhda/DialogUhda"
import TextfieldUhda from "../components/TextfieldUhda/TextfieldUhda"
import TableBaseUhda from "../components/TableUhda/TableBaseUhda"
import TextUhda from "../components/TextUdha";
import { textType } from "../resources/AppTexts";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";

function AddModal({ handleAddParticipant, initialValues, values, setValues, showAddModal, setShowAddModal }) {
  const [showEmailError, setShowEmailError] = useState(false)

  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    setValues(initialValues)
  }, [showAddModal])

  const handleClickSubmit = async () => {
    const isEmail = await validateEmail(values["email"])
    if (isEmail) {
      setShowEmailError(false)
      handleAddParticipant()
    } else {
      setShowEmailError(true)
    }
  }

  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={CommonTexts.SAVE}
      textConfirmAndContinue={TableTexts.SAVE_AND_ADD}
      handleShow={setShowAddModal}
      handleConfirm={handleClickSubmit}
      values={values}
      setValues={setValues}
      title={TableTexts.ADD_TEAM_MEMBER_TITLE}
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='name'
              handleChange={handleInputChange}
              label={TableTexts.NAME}
              value={values.name}
            />
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='surname'
              handleChange={handleInputChange}
              label={TableTexts.SURNAME}
              value={values.surname}
            />
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='email'
              handleChange={handleInputChange}
              label={TableTexts.EMAIL}
              value={values.email}
              error={showEmailError}
              helperText={showEmailError && TableTexts.EMAIL_ERROR}
              inputProps={{
                endAdornment: showEmailError && <InputAdornment position="end"><ErrorIcon
                  style={{ color: AppColors.RED }} /></InputAdornment>,
              }}
            />
          </FormControl>
        </Grid>
      }
      show={showAddModal}
    />
  );
}

AddModal.propTypes = {
  setSelectedData: PropTypes.func.isRequired,
  handleAddParticipant: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setShowAddModal: PropTypes.func.isRequired
}

function DeleteModal({ setShowDeleteModal, showDeleteModal, selected }) {

  useEffect(() => {

  }, [showDeleteModal])
  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteModal}
      buttonColor={AppColors.RED}
      handleConfirm={() => {
      }}
      title={
        showDeleteModal === true ?
          selected.length > 1 ? TableTexts.DELETE_TEAM_MEMBER_IDS + selected.toString() : TableTexts.DELETE_TEAM_MEMBER_ID + selected.toString()
          : TableTexts.DELETE_TEAM_MEMBER_ID + showDeleteModal
      }
      body={
        <TextUhda type={textType.BODY} text={showDeleteModal === true ?
          selected.length > 1 ? TableTexts.DELETE_TEAM_MEMBER_TEXT_MULTIPLE : TableTexts.DELETE_TEAM_MEMBER_TEXT
          : TableTexts.DELETE_TEAM_MEMBER_TEXT
        }>

        </TextUhda>

      }
      show={showDeleteModal}
    />
  )
}

DeleteModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired
}

function EditModal({ handleEditParticipant, setShowEditModal, showEditModal, editValues, setEditValues }) {
  const [showEmailError, setShowEmailError] = useState(false)
  useEffect(() => {
    var response = Object.values(patientsMock.data)
    setEditValues(response[showEditModal].participant)
  }, [showEditModal])

  const handleInputChange = e => {
    const { name, value } = e.target
    setEditValues({ ...editValues, [name]: value })
  }

  const handleClickSubmit = async () => {
    const isEmail = await validateEmail(editValues["email"])
    if (isEmail) {
      setShowEmailError(false)
      handleEditParticipant()
    } else {
      setShowEmailError(true)
    }
  }

  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={CommonTexts.SAVE}
      handleShow={setShowEditModal}
      handleConfirm={handleClickSubmit}
      values={editValues}
      setValues={setEditValues}
      title={TableTexts.EDIT_TEAM_MEMBER_TITLE + showEditModal}
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='name'
              handleChange={handleInputChange}
              label={TableTexts.NAME}
              value={editValues.name}
            />
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='surname'
              handleChange={handleInputChange}
              label={TableTexts.SURNAME}
              value={editValues.surname}
            />
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='email'
              handleChange={handleInputChange}
              label={TableTexts.EMAIL}
              value={editValues.email}
              error={showEmailError}
              helperText={showEmailError && TableTexts.EMAIL_ERROR}
              inputProps={{
                endAdornment: showEmailError && <InputAdornment position="end"><ErrorIcon
                  style={{ color: AppColors.RED }} /></InputAdornment>,
              }}
            />
          </FormControl>
        </Grid>
      }
      show={showEditModal}
    />
  );
}

EditModal.propTypes = {
  setShowEditModal: PropTypes.func.isRequired,
  handleEditParticipant: PropTypes.func.isRequired
}

/**
 * @Page
 * Page for the table of the collaborators
 */
const CollaboratorsPage = () => {

  const initialValues = { name: "", surname: "", birthdate: "" }
  const [values, setValues] = useState(initialValues)
  const [editValues, setEditValues] = useState(initialValues)

  const [response, setResponse] = useState()

  const getStudies = async (data) => {
    try {
      const res = await MyAxiosInstance.get(`${BASE_PATH}/studies`);
      data.forEach((participant) => {
        var studyList = ""
        participant["studies_id"].forEach((study, index) => {
          const resultado = res.data.data.find(study_id => study_id.id === study);
          if (index === 0) {
            studyList = resultado["translations"][0]["study_title"]
          } else {
            studyList = studyList + " / " + resultado["translations"][0]["study_title"]
          }
        })
        participant["studies"] = studyList
      })
      setResponse(data)
      // eslint-disable-next-line no-empty
    } catch (error) {

    }
  }

  //Function to get all the collaborators of the user.
  const getCollaborators = async () => {
    try {
      var responseAPI = await MyAxiosInstance.get(`${BASE_PATH}/team-members`);
      responseAPI = Object.values(responseAPI.data.data.values)
      var data = []
      responseAPI.forEach((res) => {
        var studies = []
        res["studies"].forEach((study) => {
          studies.push(study.id)
        })
        res.user["studies_id"] = studies
        data.push(res.user)
      })

      getStudies(data)
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
  }

  useEffect(() => {
    getCollaborators()
  }, [])

  const headCells = [
    { id: "name", numeric: false, disablePadding: false, label: TableTexts.NAME, isSortable: true },
    { id: "surname", numeric: false, disablePadding: false, label: TableTexts.SURNAME, isSortable: false },
    { id: "email", numeric: false, disablePadding: false, label: TableTexts.EMAIL, isSortable: false },
    { id: "studies", numeric: false, disablePadding: false, label: TableTexts.STUDIES, isSortable: false },
  ];

  return (
    <div style={{ paddingLeft: "48px" }}>
      {response && <TableBaseUhda
        tableTitle={TableTexts.COLLABORATORS_TITLE}
        headCells={headCells}
        dataToUse={response}
        initialValues={initialValues}
        values={values}
        setValues={setValues}
        editValues={editValues}
        setEditValues={setEditValues}
        interactive={false}
      />}
    </div>
  )
}

export default CollaboratorsPage;
