import { FORM_STUDY, GET_ANSWERS, GET_FORM } from "../resources/ApiUrls";
import MyAxiosInstance from "../utils/MyAxiosInstance";

const getForm = (studyId, formId) => MyAxiosInstance.get(GET_FORM(studyId, formId));

const getFormAnswers = (formId, page, size) => MyAxiosInstance.get(GET_ANSWERS(formId, page, size))

const postForm = (id, body) => MyAxiosInstance.post(FORM_STUDY(id), body);

const putForm = (id, body) => MyAxiosInstance.put(FORM_STUDY(id), body);

const exports = {
  getForm,
  getFormAnswers,
  postForm,
  putForm,
}
export default exports
