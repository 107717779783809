import { configureStore, combineReducers } from '@reduxjs/toolkit'
import notificationsReducer from './reducers/notificationsReducer';
import studyReducer from './reducers/studyReducer';
import studyFormAnswersReducer from './reducers/studyFormAnswersReducer';
import selectedFormReducer from './reducers/selectedFormReducer';
import formReducer from 'reducers/Form/formReducer';
import localesReducer from 'reducers/localesReducer';

const combinedReducer = combineReducers({
  notification: notificationsReducer,
  study: studyReducer,
  studyFormAnswers: studyFormAnswersReducer,
  selectedForm: selectedFormReducer,
  form: formReducer,
  locales: localesReducer
});

// ** rootReducer is used to reset the store state every time a new patient is selected, see: https://stackoverflow.com/questions/59061161/how-to-reset-state-of-redux-store-when-using-configurestore-from-reduxjs-toolki
// TODO: Reset store on logout (Need to fetch the action from context, maybe is better to pass it to redux?)
const rootReducer = (state, action) => {

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()]
})

export default store