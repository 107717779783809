import { Button, FormControl } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/material";
import BooleanOperator from "./BooleanOperator";
import SingleChoiceDropdown from "components/Dropdowns/SingleChoiceDropdown";
import { AppColors } from "resources/AppColors";
import { useSelector } from "react-redux";
import { findTranslation } from "utils/HelperFunctions";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
        backgroundColor: AppColors.BACKGROUND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      backgroundColor: "transparent"

    },
    "&:before": {
      color: AppColors.WHITE,
    },
    "&:after": {
      borderBottomColor: AppColors.WHITE,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.WHITE,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  }
})

export function SingleConditionalQuestion({
  questionConditionals,
  setQuestionConditionals,
  index,
  conditionalQuestion,
  conditionalOperator,
  handleChangeConditionalOperator,
  possibleConditionalQuestions,
  handleDeleteConditional
}) {
  const classes = useStyles()

  const form = useSelector(({ form }) => form.data[0])

  /**
   * It takes the value of the dropdown and finds the corresponding question in the
   * questions array. It then updates the conditionalList array with the new value and type
   * @param e - the event object
   * @param index - the index of the conditional question in the conditionalList array
   */
  function handleChangeConditionalVariable(e, index) {
    // TODO: Clear Conditional answer when changing variable if the answer is already selected
    const selectedQuestionVariableName = e.target.value
    const selectedQuestion = possibleConditionalQuestions.find(question => question.variableName === selectedQuestionVariableName)

    const newConditionalQuestion = {
      ...questionConditionals[index],
      variable: selectedQuestionVariableName,
      type: selectedQuestion["type"],
      answer: ""
    }

    const updatedConditionalQuestions = [
      ...questionConditionals.slice(0, index),
      newConditionalQuestion,
      ...questionConditionals.slice(index + 1)
    ]

    setQuestionConditionals(updatedConditionalQuestions)
  };

  /**
   * It takes the value of the input and sets it to the state
   * @param e - The event object
   * @param index - The index of the conditional question
   */
  const handleChangeConditionalAnswer = (e, index) => {
    const selectedAnswerId = e.target.value

    const newConditionalQuestion = {
      ...questionConditionals[index],
      answer: selectedAnswerId
    }

    const updatedConditionalQuestions = [
      ...questionConditionals.slice(0, index),
      newConditionalQuestion,
      ...questionConditionals.slice(index + 1)
    ]

    setQuestionConditionals(updatedConditionalQuestions)
  };

  return (
    <Box id="conditional-question-container">
      <BooleanOperator
        index={index}
        conditionalOperator={conditionalOperator}
        handleChangeConditionalOperator={handleChangeConditionalOperator}
        className={classes.select}
      />
      <Box width="100%" display="flex" justifyContent="space-around" alignItems="center" mb={2} id="conditional-form">
        <FormControl
          required
          margin="normal"
          sx={{ minWidth: "35%" }}
          className={classes.select}
        >
          <SingleChoiceDropdown
            label={"Variable"}
            value={questionConditionals[index].variable}
            handleValue={(e) => handleChangeConditionalVariable(e, index)}
            items={possibleConditionalQuestions.map((question) => {
              return {
                name: question.variableName,
                value: question.variableName
              }
            })}
          />
        </FormControl>
        <Box style={{ width: "0" }}>=</Box>
        {/* // * Types of questions that use text fields for their answer values are displayed with text fields to input conditional answer value */}
        {/* // TODO: Enable it when we allow all questions to be conditional */}
        {/* &&
              questionConditionals[index].type !== "INFO" &&
              questionConditionals[index].type !== "FREE TEXT" &&
              questionConditionals[index].type !== "DATE TIME" */}
        {(questionConditionals[index].type !== "CALCULATION" &&
          questionConditionals[index].type !== "RANGE"
        )
          ?
          <FormControl
            required
            margin="normal"
            sx={{ width: "35%" }}
            className={classes.select}
          >
            <SingleChoiceDropdown
              label={"Answer"}
              value={conditionalQuestion.answer}
              handleValue={(e) => handleChangeConditionalAnswer(e, index)}
              items={!!questionConditionals[index]?.variable ? (
                possibleConditionalQuestions
                  .find((question) => question.variableName === questionConditionals[index]?.variable)?.choiceQuestions.map((answer) => {
                    return {
                      name: findTranslation(answer?.translations, form.defaultLocale.value)?.text, // * Use default locale
                      value: answer.value
                    }
                  })
              ) : [{ name: "", value: 0 }] // * If a new conditional question is added, this is the starting value
              }
            />
          </FormControl>
          :
          null // * The texfield at the bottom was rendered in case of choosing a calculation question type, this is not possible now as that type of question is not allowed to be conditional
        }
        {questionConditionals.length > 1 &&
          <Button
            sx={{
              ml: -10,
              mr: -6,
              mt: 1,
              color: AppColors.BLACK
            }}
            onClick={(e) => handleDeleteConditional(index, e)}
          >
            <DeleteIcon />
          </Button>
        }
      </Box>
    </Box>
  );
}

