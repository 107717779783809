import { $generateNodesFromDOM } from "@lexical/html"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $getRoot, $insertNodes, CLEAR_EDITOR_COMMAND } from "lexical"
import { useEffect } from "react"

export default function SerializeHtmlPlugin({ rawHtml, setJsonTextEditor, resetEditorState, setResetEditorState }) {
  const [editor] = useLexicalComposerContext()


  useEffect(() => {
    editor.update(() => {
      const root = $getRoot()
      const textContent = root.getTextContent()

      if (resetEditorState) {
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)

        setResetEditorState(false)
      }

      if (rawHtml && !textContent) {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser();
        const dom = parser.parseFromString(rawHtml, "text/html");

        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);

        // Select the root
        root.select();

        // Insert them at a selection.
        $insertNodes(nodes);

        setJsonTextEditor(nodes)
      }
    })
  }, [rawHtml, resetEditorState])

  return null
}
