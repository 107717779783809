import {MOD_STUDY_URL, STUDIES_URL, STUDY_URL} from "../resources/ApiUrls";
import MyAxiosInstance from "../utils/MyAxiosInstance";

const getStudies = () => MyAxiosInstance.get(STUDIES_URL);

const postStudies = body => MyAxiosInstance.post(STUDY_URL, body);

const putStudies = (body) => MyAxiosInstance.put(MOD_STUDY_URL, body);

const exports = {
  getStudies,
  postStudies,
  putStudies,
}
export default exports
