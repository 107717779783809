import { GET_SUMMARY, CHANGE_SUMMARY_ORDER, POST_SUMMARY, PUT_SUMMARY, DELETE_SUMMARY, DELETE_QUESTION_SUMMARY } from "../resources/ApiUrls";
import MyAxiosInstance from "../utils/MyAxiosInstance";

const get = (formId) => MyAxiosInstance.get(GET_SUMMARY(formId));


const post = (studyId, formId, body) => MyAxiosInstance.post(POST_SUMMARY(studyId, formId), body)

const put = (studyId, formId, body) => MyAxiosInstance.put(PUT_SUMMARY(studyId, formId), body)

const remove = (studyId, formId, summaryId) => MyAxiosInstance.delete(DELETE_SUMMARY(studyId, formId, summaryId))

const deleteQuestionSummary = (summaryId, questionId) => MyAxiosInstance.delete(DELETE_QUESTION_SUMMARY(summaryId, questionId))

const changeOrder = (formId, orderedQuestions) => MyAxiosInstance.put(CHANGE_SUMMARY_ORDER(formId), orderedQuestions)

const exports = {
  get,
  post,
  put,
  remove,
  changeOrder,
  deleteQuestionSummary
}
export default exports
