import { Box, FormControl } from "@mui/material";
import FormSwitchUhda from "components/FormSwitchUhda"
import {
  QUESTION_INFO,
  QUESTION_TEXT,
  questionTypes,
} from "resources/QuestionConstants";

function SwitchesLeftSection({
  switches,
  setSwitches,
  questionType,
  changeMandatory,
  questionsLength,
  formWithSummary,
  isSummaryPage
}) {
  function handleOnConditionalChange() {
    setSwitches({
      ...switches,
      hasConditional: !switches.hasConditional
    });
  };

  function handleOnLongTextChange() {
    setSwitches({
      ...switches,
      longText: !switches.longText,
    });
  };

  function handleOnSummaryChange() {
    setSwitches({
      ...switches,
      summary: !switches.summary,
    });
  };

  return (
    <Box mt={1}>
      {/* {questionType === questionTypes[QUESTION_TEXT].name
        && (
          <FormControl  sx={{ width: "100%", alignItems: "flex-start" }}>
            <FormSwitchUhda
              marginLeft={0}
              label="Long text"
              value={switches.longText}
              handleChange={handleOnLongTextChange}
            />
          </FormControl>
        )} */}
      {questionType !== questionTypes[QUESTION_INFO].name
        && (
          <FormControl sx={{ width: "100%", alignItems: "flex-start" }}>
            <FormSwitchUhda
              label="Mandatory"
              value={switches.mandatory}
              handleChange={changeMandatory} />
          </FormControl>
        )}
      {(questionsLength >= 1)
        &&
        <FormControl sx={{ width: "100%", alignItems: "flex-start" }}>
          <FormSwitchUhda
            label="Conditional"
            value={switches.hasConditional}
            handleChange={handleOnConditionalChange} />
        </FormControl>}
      {formWithSummary && !isSummaryPage &&
        <FormControl sx={{ width: "100%", alignItems: "flex-start" }}>
          <FormSwitchUhda
            label="Include in summary"
            value={switches.summary}
            handleChange={handleOnSummaryChange} />
        </FormControl>}
    </Box>
  );
}

export default SwitchesLeftSection