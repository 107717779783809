import { createSlice } from '@reduxjs/toolkit'
import StudiesService from '../services/StudiesService'
import { toast } from './notificationsReducer'
import { dashboardTexts } from '../locale/en'

const studySlice = createSlice({
  name: 'study',
  initialState: { data: null, loading: false, error: null },
  reducers: {
    setStudy(state, action) {
      const { data } = action.payload

      if (!data) { // ! Temporary fix until we reset states in store.js after implementing studies reducer
        return { data: null, loading: false, error: null }
      }

      const forms = data.formsSummary.map(form => {
        return {
          value: form.id,
          name: form.form_translations[0]?.title,
        }
      })

      const reducerData = {
        id: data.studyData.id,
        title: data.studyData.translations[0]?.study_title,
        permissions: data.studyData.permissions,
        forms
      }

      return { ...state, data: reducerData, loading: false, error: null }
    },
    setStudyLoading(state) {
      state.loading = true;
      state.error = null;
    },
    setStudyError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
})

export const { setStudy, setStudyLoading, setStudyError } =
  studySlice.actions

export const initializeStudy = (studyId) => {
  return async (dispatch) => {
    try {
      dispatch(setStudyLoading());

      const { data: studyData } = await StudiesService.get(studyId) // ! The only useful info from this call is the study title
      const { data: formsSummary } = await StudiesService.getFormsSummary(studyId)

      const dataToDispatch = {
        studyData: studyData.data,
        formsSummary: formsSummary.data.items
      }

      dispatch(setStudy({ data: dataToDispatch }))
    }
    catch (error) {
      console.error(error)
      dispatch(setStudyError({ message: error.message, status: error.response?.status }));
      dispatch(toast(dashboardTexts.STUDY_LOADING_ERROR, 'error'))
    }
  }
}

export default studySlice.reducer