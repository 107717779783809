/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Button, Fade, Menu, MenuItem } from "@mui/material";
import { AppColors } from "../../resources/AppColors";
import { textType } from "../../resources/AppTexts";
import IconProviderUhda from "../IconProviderUhda";
import TextUhda from "../TextUdha";
import { LabelsForms } from "../../locale/en";
import { IMPORT_FORM } from "../../resources/ApiUrls";
import Icons from "../../resources/Icons";
import MyAxiosInstance from "../../utils/MyAxiosInstance";

/**
 * @component
 * Component to create buttons filled with text or icons
 *
 * @param backGroundColor: background color of the button
 * @param width: width of the button
 * @param color: color of the text of the button
 * @param size: size of the button
 * @param onClick: function to use when click
 * @param isDisabled: boolean to dissable the button
 * @param text: text of the button
 * @param textAlign: alignment of the text in the button
 * @param icon: icon if the button not have text
 * @param marginLeft: marginLeft of the text with the icon or button left margin
 * @param margin: margin of all the text with the button
 * @param type: textType of the text
 * @returns {JSX.Element}
 *
 * @example
 * <ButtonFilledUhda marginLeft={'0.2em'} onClick={handleChangeArchived} color={AppColors.WHITE} backGroundColor={AppColors.RED}
 icon={<IconProviderUhdaicon={<Icons.ARCHIVE size={'1.5em'} style={{ color: AppColors.WHITE }} />} />} text={LabelsForm.ARCHIVE}/>
 */

const ButtonFilledUhda = ({
  backGroundColor,
  width,
  color,
  size,
  onClick,
  isDisabled,
  text,
  textAlign,
  icon,
  marginLeft,
  margin,
  type,
  variant,
  border,
  options,
  getForms,
  studyId
}) => {
  if (isDisabled == null) isDisabled = false;
  if (backGroundColor == null) backGroundColor = AppColors.PRIMARY;
  if (color == null) color = AppColors.WHITE;
  if (textAlign == null) textAlign = "center";
  if (type == null) type = textType.BODY_BOLD;
  if (variant == null) variant = "contained";
  //const classes = useTextStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const uploadFile = async (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = async (file) => {
      try {
        const response = await MyAxiosInstance.post(IMPORT_FORM(studyId), file.target.result);
        getForms()
        // eslint-disable-next-line no-empty
      } catch (error) {

      }
    };
    //getForms()
  };
  const uploadInputRef = useRef(null);
  return (
    <>

      <Button
        variant={variant}
        size={size}
        style={{
          boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
          border: border,
          width: width,
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: backGroundColor,
          borderRadius: options ? "4px 0 0 4px" : "4px",

        }}
        disabled={isDisabled}
        onClick={onClick}
      >
        {icon && (
          <IconProviderUhda icon={icon} values={{ color: AppColors.WHITE }} />
        )}
        {text && (
          <TextUhda
            margin={margin}
            marginLeft={marginLeft}
            type={type}
            text={text}
            color={color}
          />
        )}
      </Button>
      {
        options &&
        <>
          <Button style={{
            boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
            width: width,
            paddingTop: 0,
            paddingBottom: 0,
            height: "2.9em",
            backgroundColor: backGroundColor,
            borderRadius: "0px 4px 4px 0px"

          }} aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            <IconProviderUhda icon={<Icons.OPTIONS_HORIZONTAL color={AppColors.WHITE} style={{
              verticalAlign: "middle",
              display: "inline-flex",
              paddingRight: "4px"
            }} size="2rem" />} />
          </Button>
          <Menu
            style={{
              boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)"
            }}
            color={AppColors.WHITE}
            id="fade-menu"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}>
            <input
              ref={uploadInputRef}
              type="file"
              accept=".json"
              hidden
              id="import-file"
              onChange={uploadFile}
            />
            <MenuItem onClick={() => uploadInputRef.current && uploadInputRef.current.click()} style={{ color: AppColors.PRIMARY }}
            >  {LabelsForms.IMPORT_FORM} </MenuItem>
          </Menu>
        </>
      }
    </>
  );
};

ButtonFilledUhda.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  textAlign: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.any,
};

export default ButtonFilledUhda;
