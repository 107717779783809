/* eslint-disable no-unused-vars*/
/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import TableBaseUhda from "../components/TableUhda/TableBaseUhda";
import { patientsMock } from "../mocks/patientsMock"
import { CircularProgress, FormControl, Grid, InputAdornment, List, ListItem, ListItemText, Typography } from "@mui/material"
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types"
import DialogUhda from "../components/DialogUhda/DialogUhda"
import moment from "moment"
import { AppColors } from "../resources/AppColors"
import ErrorIcon from "@mui/icons-material/Error"
import { validateEmail } from "../utils/HelperFunctions"
import { CommonTexts, LabelsForm, SnackBarResponses, TableTexts } from "../locale/en";
import TextfieldUhda from "../components/TextfieldUhda/TextfieldUhda";
import TextUhda from "../components/TextUdha";
import { textType } from "../resources/AppTexts";
import ParticipantsService from "../services/ParticipantsService";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";
import { translationsMock } from "../mocks/translationsMock";
import { useMatomo } from "@jonkoops/matomo-tracker-react"
import { useDispatch } from "react-redux";
import { toast } from "../reducers/notificationsReducer";

function AddModal({
    selectedDate,
    setSelectedDate,
    handleAddParticipant,
    handleAddParticipantContinue,
    initialValues,
    values,
    setValues,
    showAddModal,
    setShowAddModal,
    error
}) {
    const [showLocaleError, setShowLocaleError] = useState(false)
    const [showNameError, setShowNameError] = useState(false)

    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({ ...values, [name]: value })
    }
    useEffect(() => {
        setValues(initialValues)
    }, [showAddModal])

    /**
     * If the locale is empty, show the locale error. If the name is empty, show the name error. If
     * both are filled, add the participant
     */
    const handleClickSubmit = async () => {
        if (values.locale == "") {
            setShowLocaleError(true)
        } else if (values.name == "") {
            setShowNameError(true)
        } else {
            handleAddParticipant()
        }
    }

    /**
     * If the locale is empty, show the locale error. If the name is empty, show the name error. If
     * both are filled, continue to the next step
     */
    const handleClickSubmitContinue = async () => {
        if (values.locale == "") {
            setShowLocaleError(true)
        } else if (values.name == "") {
            setShowNameError(true)
        } else {
            handleAddParticipantContinue()
        }
    }
    return (
        <DialogUhda
            textCancelButton={CommonTexts.CANCEL}
            textConfirmButton={CommonTexts.SAVE}
            textConfirmAndContinue={TableTexts.SAVE_AND_ADD}
            handleShow={setShowAddModal}
            handleConfirm={handleClickSubmit}
            handleConfirmAndContinue={handleClickSubmitContinue}
            values={values}
            setValues={setValues}
            title={TableTexts.ADD_LOCALE_TITLE}
            body={
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <FormControl margin='normal' style={{ width: "100%" }}>
                        <TextfieldUhda
                            name='locale'
                            handleChange={handleInputChange}
                            label={TableTexts.LOCALE_CODE}
                            value={values.locale}

                            helperText={showLocaleError && SnackBarResponses.LOCALE_REQUIRED}
                            inputProps={{
                                endAdornment: showLocaleError && <InputAdornment position="end"><ErrorIcon
                                    style={{ color: AppColors.RED }} /></InputAdornment>,
                            }}
                        />
                    </FormControl>
                    <FormControl margin='normal' style={{ width: "100%" }}>
                        <TextfieldUhda
                            name='name'
                            handleChange={handleInputChange}
                            label={TableTexts.LOCALE_NAME}
                            value={values.name}
                            helperText={showNameError && SnackBarResponses.NAME_REQUIRED}
                            inputProps={{
                                endAdornment: showNameError && <InputAdornment position="end"><ErrorIcon
                                    style={{ color: AppColors.RED }} /></InputAdornment>,
                            }}
                        />
                    </FormControl>
                </Grid>
            }
            show={showAddModal}
        />
    );
}

AddModal.propTypes = {
    setSelectedData: PropTypes.func.isRequired,
    handleAddParticipant: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    setShowAddModal: PropTypes.func.isRequired
}

function DeleteModal({ handleDeleteParticipant, setShowDeleteModal, showDeleteModal, selected }) {
    useEffect(() => {

    }, [showDeleteModal])
    return (
        <DialogUhda
            textCancelButton={CommonTexts.CANCEL}
            textConfirmButton={TableTexts.DELETE_CONFIRM}
            handleShow={setShowDeleteModal}
            buttonColor={AppColors.RED}
            handleConfirm={() => {
                handleDeleteParticipant();

            }}
            title={
                showDeleteModal === true ?
                    selected.length > 1 ? TableTexts.DELETE_LOCALES : TableTexts.DELETE_LOCALE
                    : TableTexts.DELETE_LOCALE
            }
            body={
                <Grid>
                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY} text={selected.length > 1
                        ? TableTexts.DELETE_LOCALE_TEXT_MULTIPLE
                        : showDeleteModal.length > 1 ? TableTexts.DELETE_LOCALE_TEXT : TableTexts.DELETE_LOCALE_TEXTS} />
                    {selected.length > 1 ?
                        <List>
                            {showDeleteModal.map((locale) => (
                                <ListItem key={locale.id} disablePadding>
                                    <ListItemText
                                        primary={<TextUhda margin={0} type={textType.BODY} text={locale.name}></TextUhda>}
                                    />
                                </ListItem>
                            ))}
                        </List>
                        : <TextUhda type={textType.BODY} text={showDeleteModal.length > 1 ? showDeleteModal[1].name : showDeleteModal[0].name} />
                    }
                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                        text={selected.length > 1 ? TableTexts.TEXT_MODAL_DELETE_LOCALES : TableTexts.TEXT_MODAL_DELETE_LOCALE} />

                </Grid>}
            show={showDeleteModal.length != 0}
        />
    )
}

DeleteModal.propTypes = {
    setShowDeleteModal: PropTypes.func.isRequired
}

function EditModal({
    selectedDate,
    setSelectedDate,
    handleEditParticipant,
    setShowEditModal,
    showEditModal,
    editValues,
    setEditValues,
    error
}) {

    const handleInputChange = e => {
        const { name, value } = e.target
        setEditValues({ ...editValues, [name]: value })
    }
    const handleClickSubmit = async () => {
        handleEditParticipant()
    }

    return (
        <DialogUhda
            textCancelButton={CommonTexts.CANCEL}
            textConfirmButton={CommonTexts.SAVE}
            handleShow={setShowEditModal}
            handleConfirm={handleClickSubmit}
            values={editValues}
            setValues={setEditValues}
            title={TableTexts.EDIT_LOCALE}
            body={
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <FormControl margin='normal' style={{ width: "100%" }}>
                        <TextfieldUhda
                            isDisabled={true}
                            name='locale'
                            handleChange={handleInputChange}
                            label={TableTexts.LOCALE_CODE}
                            value={editValues.locale}
                            helperText={(error && `${error}`)}

                        />
                    </FormControl>
                    <FormControl margin='normal' style={{ width: "100%" }}>
                        <TextfieldUhda
                            name='name'
                            handleChange={handleInputChange}
                            label={TableTexts.LOCALE_NAME}
                            value={editValues.name}
                        />
                    </FormControl>
                </Grid>
            }
            show={showEditModal}
        />
    );
}

EditModal.propTypes = {
    setShowEditModal: PropTypes.func.isRequired,
    handleEditParticipant: PropTypes.func.isRequired
}
/**
 * @Page
 * Page that shows the table of participants that a user has
 * @param match
 * @returns {JSX.Element}
 * @constructor
 */
const TranslatePage = () => {
    const initialValues = { locale: "", name: "" }
    const [values, setValues] = useState(initialValues)
    const [editValues, setEditValues] = useState(initialValues)
    const [selectedDate, setSelectedDate] = useState([])
    const [showAddModal, setShowAddModal] = useState(-999)
    const [showEditModal, setShowEditModal] = useState(-999)
    const [showDeleteModal, setShowDeleteModal] = useState(-999)
    const [response, setResponse] = useState()
    const [error, setError] = useState()
    const [loading, setLoading] = useState();
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const headCells = [
        { id: "locale", numeric: false, disablePadding: false, label: TableTexts.LOCALE_CODE, isSortable: true },
        { id: "name", numeric: false, disablePadding: false, label: TableTexts.LOCALE_NAME, isSortable: false },
        { id: "actions", numeric: false, disablePadding: true, label: TableTexts.ACTIONS, isSortable: false, }
    ];
    const { trackPageView } = useMatomo()

    const dispatch = useDispatch()

    useEffect(() => {
        trackPageView()
    }, [])

    /**
     * `getLocales` is an async function that makes a GET request to the `/locales` endpoint, and sets the
     * response to the `response` state variable
     */
    const getLocales = async () => {
        try {
            const response = await MyAxiosInstance.get(`${BASE_PATH}/locales`);
            setResponse(Object.values(response.data.data.items))

            // eslint-disable-next-line no-empty
        } catch {

        }
        setLoading(false)
    }

    /**
     * It takes the values from the form, sends them to the API, and then closes the modal
     */
    const handleAddParticipant = async () => {
        setLoading(true)
        try {
            await MyAxiosInstance.post(
                `${BASE_PATH}/locale`,
                values
            );
            dispatch(toast("Locale added", "success"))

            setShowAddModal(-999)
        } catch (e) {
            dispatch(toast("Locale codes cannot be repeated!", "error"))
            //setError(e.response.data.data.messages.locale[0])
        }
        getLocales()

    }

    /**
     * It takes the values from the form, sends them to the server, and then updates the state of the
     * component
     */
    const handleAddParticipantContinue = async () => {
        setLoading(true)
        try {
            await MyAxiosInstance.post(
                `${BASE_PATH}/locale`,
                values
            );
            dispatch(toast("Locale added", "success"))
        } catch (e) {
            dispatch(toast("Locale codes cannot be repeated!", "error"))
            //setError(e.response.data.data.messages.locale[0])
        }
        getLocales()

    }

    /**
     * It takes the values from the edit form, and sends them to the API
     */
    const handleEditParticipant = async () => {
        setLoading(true)

        try {
            await MyAxiosInstance.put(
                `${BASE_PATH}/locale/${editValues["id"]}`,
                editValues
            );
            dispatch(toast("Locale edited", "success"))
            setShowEditModal(-999)
        } catch (e) {
            dispatch(toast("You can't edit a locale that is in use", "error"))

            setError(e)
        }
        getLocales()

    }
    /**
     * This function is used to delete a locale
     */
    const handleDeleteParticipant = async () => {
        setLoading(true)
        let error = 0
        try {
            if (selectedDate.length != 0) {
                for (let i = 0; i < selectedDate.length; i++) {
                    error = i

                    // eslint-disable-next-line no-await-in-loop
                    await MyAxiosInstance.delete(
                        `${BASE_PATH}/locale/${selectedDate[i].id}`,
                        editValues);
                }
            } else {
                // eslint-disable-next-line no-await-in-loop
                await MyAxiosInstance.delete(
                    `${BASE_PATH}/locale/${showDeleteModal[0]}`,
                    editValues
                );
            }
            dispatch(toast("Locale(s) deleted", "success"))

            setShowDeleteModal(-999)
            getLocales()
        } catch (e) {
            setError(e)
            if (e.response.data.code == 505) {
                if (selectedDate.length != 0) {
                    setShowDeleteModal(-999)
                    dispatch(toast(`The locale (${selectedDate[error].name}) you are trying to delete is currently in use and cannot be deleted.`, "error"))
                    setSelectedDate([])
                } else {
                    setShowDeleteModal(-999)
                    dispatch(toast("The locale you are trying to delete is currently in use and cannot be deleted.", "error"))


                }
            }

            setLoading(false)
        }
    }

    useEffect(() => {
        getLocales()
    }, [])

    return (
        <>
            {loading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div style={{ paddingLeft: "48px", paddingRight: "48px" }}>
                        {response && <TableBaseUhda
                            tableTitle={TableTexts.TRANSLATIONS_TITLE}
                            headCells={headCells}
                            dataToUse={response}
                            addModal={AddModal}
                            deleteModal={DeleteModal}
                            editModal={EditModal}
                            initialValues={initialValues}
                            values={values}
                            setValues={setValues}
                            editValues={editValues}
                            setEditValues={setEditValues}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            handleAddParticipant={handleAddParticipant}
                            handleAddParticipantContinue={handleAddParticipantContinue}
                            handleEditParticipant={handleEditParticipant}
                            handleDeleteParticipant={handleDeleteParticipant}
                            showAddModal={showAddModal}
                            setShowAddModal={setShowAddModal}
                            showEditModal={showEditModal}
                            setShowEditModal={setShowEditModal}
                            showDeleteModal={showDeleteModal}
                            setShowDeleteModal={setShowDeleteModal}
                            interactive={true}
                            canAdd={true}
                            canEdit={true}
                            canDelete={true}
                            error={error}
                            searchable={true}
                            orderByName={"locale"}

                        />}
                    </div>
                </>)}
        </>

    )
}

export default TranslatePage;
