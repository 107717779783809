import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { AppColors } from "resources/AppColors";

export const ButtonToggle = styled(Button)`
  opacity: 1;
  background-color: ${AppColors.BACKGROUND};
  border-color:  ${AppColors.PRIMARY};
  &:hover {
            color: white;
            background-color: ${AppColors.PRIMARY};
            border-color: ${AppColors.PRIMARY};
          };
  color: #C4141B ${({ active }) =>
    active &&
    `opacity: 1;
        background-color: ${AppColors.PRIMARY};
        border-color: ${AppColors.PRIMARY};
        color: white;
        &:hover {
            color: white;
            background-color: ${AppColors.PRIMARY};
            border-color: ${AppColors.PRIMARY};
          }
        `};
`;