import MyAxiosInstance from "../utils/MyAxiosInstance";
import { TAGS_URL, TAG_ID_URL, TAG_URL, } from "../resources/ApiUrls";

const getTags = () => MyAxiosInstance.get(TAGS_URL);

const postTag = (body) =>
    MyAxiosInstance.post(TAG_URL, body);
const putTag = (id, body) =>
    MyAxiosInstance.put(TAG_ID_URL(id), body);
const deleteTag = (id) =>
    MyAxiosInstance.delete(TAG_ID_URL(id));

const exports = {
    getTags,
    postTag,
    putTag,
    deleteTag,
};
export default exports;
