import { CircularProgress, FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import { questionTypes, QUESTION_SINGLE_CHOICE, QUESTION_MULTIPLE_CHOICE } from "resources/QuestionConstants";
import SingleChoiceDropdown from "../Dropdowns/SingleChoiceDropdown";

const singleChoiceValues = [
  {
    type: 'Default',
    title: 'Radio Buttons'
  },
  {
    type: 'Select',
    title: 'Dropdown'
  },

]

const multipleChoiceValues = [
  {
    type: 'Default',
    title: 'Checkbox'
  },
  {
    type: 'Multiple',
    title: 'List'
  },
]

function QuestionsPresentation({ selectedQuestionType, selectedPresentationType, setSelectedPresentationType }) {
  const [values, setValues] = useState(null)
  const [originalSelectedPresentationType] = useState(selectedPresentationType || 'Default')

  const handleChangeValue = (e) => setSelectedPresentationType(e.target.value)

  useEffect(() => {
    if (selectedQuestionType === questionTypes[QUESTION_SINGLE_CHOICE].name) {
      setValues(singleChoiceValues)
      // * If question type is changed between single and multiple, set the corresponding default value (if is edition the originally selected values is set)
      if (!selectedPresentationType || originalSelectedPresentationType === "Multiple") setSelectedPresentationType(singleChoiceValues[0].type)
      else {
        setSelectedPresentationType(originalSelectedPresentationType)
      }
    }
    else if (selectedQuestionType === questionTypes[QUESTION_MULTIPLE_CHOICE].name) {
      setValues(multipleChoiceValues)
      if (!selectedPresentationType || originalSelectedPresentationType === "Select") setSelectedPresentationType(multipleChoiceValues[0].type)
      else {
        setSelectedPresentationType(originalSelectedPresentationType)
      }
    }
  }, [selectedQuestionType])

  if (!values) return <CircularProgress />

  return (
    <FormControl
      margin="normal"
      fullWidth
    >
      <SingleChoiceDropdown
        label={"Presentation Type"}
        value={selectedPresentationType}
        handleValue={handleChangeValue}
        items={values.map(value => { return { name: value.title, value: value.type } })}
        error={!selectedQuestionType}
      />
    </FormControl>
  );
}

export default QuestionsPresentation