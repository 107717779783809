import {LOGIN_URL} from "../resources/ApiUrls";
import MyAxiosInstance from "../utils/MyAxiosInstance";

/**
 * Perform a POST request to the Login URL with the given data.
 * @param {object} params: data to send to the server
 */

const login = body => MyAxiosInstance.post(LOGIN_URL, body);

export default {
  login
};
