
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { useEffect, useState, useRef } from "react";
import { AppColors } from "../resources/AppColors";
import DrawerUhda from "../components/DrawerUhda";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, Grid, InputAdornment, List, ListItem, ListItemText } from "@mui/material";
import { CommonTexts, DashboardTexts, LabelsDrawer, LabelsStudy, TableTexts } from "../locale/en";
import { textType, useTextStyles } from "../resources/AppTexts";
import BreadcrumbsUhda from "../components/BreadcrumbsUhda";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import TextUhda from "../components/TextUdha";
import CardUhda from "../components/CardUhda";
import Box from "@mui/material/Box";
import arrowUp from "../img/arrow_up_icon.svg"
import arrowDown from "../img/arrow_down_icon.svg"
import dataPointsIcon from "../img/dashboard_data_points.svg"
import teamMembersIcon from "../img/dashboard_team_members.svg"
import downloadDisabledIcon from "../img/download_disabled_icon.svg"
import downloadEnabledIcon from "../img/download_enabled_icon.svg"
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DialogUhda from "../components/DialogUhda/DialogUhda"
import TextfieldUhda from "../components/TextfieldUhda/TextfieldUhda";
import Avatar from "@mui/material/Avatar";
import TableBaseUhda from "../components/TableUhda/TableBaseUhda";
import { orderArray, validateEmail } from "../utils/HelperFunctions"
import DateFnsUtils from "@date-io/date-fns";
import ErrorIcon from "@mui/icons-material/Error"
import PropTypes from "prop-types"
import { dashboardMock } from "../mocks/dashboardMock"
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { StorageManager } from "../utils";
import { useMatomo } from "@jonkoops/matomo-tracker-react"

import { DownloadModal } from "../components/QuestionModals/DownloadModal";
import { parseISO } from "date-fns";
const drawerWidth = 150;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: AppColors.BACKGROUND,
    display: "flex",
  },
  content: {
    backgroundColor: AppColors.BACKGROUND,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    flexGrow: 1,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  drawerHeader: {

    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawer: {

    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  iconRoot: {
    textAlign: "center"
  },
  imageIcon: {
    height: "100%"
  },

  hide: {
    display: "none",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,

    }),

  },
  drawerClose: {
    marginTop: "64px",
    marginLeft: "70px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(20) + 1,
    },
  },
  avatar: {
    border: "1px solid #B09C78",
    "&.MuiAvatar-img": {
      width: "25px",
      height: "25px",
    }

  },
  dropdownStyle:
  {
    color: AppColors.PRIMARY,
  },
  formControl:
  {
    color: AppColors.BLACK,
    backgroundColor: AppColors.CBM_SAND
  },

  textFieldLabel: {

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.BLACK,
        opacity: "0.2",
        borderRadius: 10,

      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.BLACK,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },

    color: AppColors.BLACK,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },

}));

function AddModal({
  selectedDate,
  setSelectedDate,
  handleAddParticipant,
  initialValues,
  values,
  setValues,
  showAddModal,
  setShowAddModal
}) {
  const [showEmailError, setShowEmailError] = useState(false)

  const texts = useTextStyles();
  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {

    setValues(initialValues)
    setSelectedDate(null)
  }, [showAddModal])

  const handleClickSubmit = async () => {
    const isEmail = await validateEmail(values["email"])
    if (isEmail) {
      setShowEmailError(false)
      handleAddParticipant()
    } else {
      setShowEmailError(true)
    }
  }

  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={CommonTexts.SAVE}
      textConfirmAndContinue={TableTexts.SAVE_AND_ADD}
      handleShow={setShowAddModal}
      handleConfirm={handleClickSubmit}
      values={values}
      setValues={setValues}
      title={TableTexts.ADD_PARTICIPANT_TITLE}
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='name'
              handleChange={handleInputChange}
              label={TableTexts.NAME}
              value={values.name}
            />
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='surname'
              handleChange={handleInputChange}
              label={TableTexts.SURNAME}
              value={values.surname}
            />
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='email'
              handleChange={handleInputChange}
              label={TableTexts.EMAIL}
              value={values.email}
              error={showEmailError}
              helperText={showEmailError && TableTexts.EMAIL_ERROR}
              inputProps={{
                endAdornment: showEmailError && <InputAdornment position="end"><ErrorIcon
                  style={{ color: AppColors.RED }} /></InputAdornment>,
              }}
            />
          </FormControl>
        </Grid>
      }
      show={showAddModal}
    />
  );
}

AddModal.propTypes = {
  setSelectedData: PropTypes.func.isRequired,
  handleAddParticipant: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setShowAddModal: PropTypes.func.isRequired
}

function DeleteModal({ setShowDeleteModal, showDeleteModal, selected, handleDeleteParticipant }) {
  useEffect(() => {

  }, [showDeleteModal])

  const handleDelete = async () => {
    try {

      var body = { "ids": selected }
      const response = await MyAxiosInstance.post(`${BASE_PATH}/form-answers`, body);
      setShowDeleteModal(-999)
      // eslint-disable-next-line no-empty
    } catch (error) {

    }

  }
  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteModal}
      buttonColor={AppColors.RED}
      handleConfirm={() => {
        handleDeleteParticipant()
      }}
      title={
        showDeleteModal === true ?
          selected.length > 1 ? TableTexts.DELETE_ANSWERS_TITLE : TableTexts.DELETE_ANSWERS_TITLE
          : TableTexts.DELETE_ANSWERS_TITLE
      }
      body={
        <Grid>
          <TextUhda color={AppColors.PRIMARY} type={textType.BODY} text={TableTexts.DELETE_ANSWERS
          } />
          {selected.length > 1 ?
            <List>
              {showDeleteModal.map((data) => (
                <ListItem key={data.id} disablePadding>
                  <ListItemText
                    primary={<TextUhda margin={0} type={textType.BODY} text={data.id}></TextUhda>}
                  />
                </ListItem>
              ))}
            </List>
            : <TextUhda type={textType.BODY} text={showDeleteModal.length > 1 ? showDeleteModal[0] : showDeleteModal[0].id} />}
          <TextUhda type={textType.BODY} color={AppColors.PRIMARY} text={TableTexts.TEXT_MODAL_DELETE_TAG} />

        </Grid>}
      show={showDeleteModal.length != 0}
    />
  )
}

DeleteModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired
}

function EditModal({
  selectedDate,
  setSelectedDate,
  handleEditParticipant,
  setShowEditModal,
  showEditModal,
  editValues,
  setEditValues
}) {
  const [showEmailError, setShowEmailError] = useState(false)
  useEffect(() => {
    var response = Object.values(dashboardMock.data)
    setEditValues(response[showEditModal].participant)
    const date = response[showEditModal].participant.birthdate
    setSelectedDate(moment(date, "DD-MM-YYYY"))
  }, [showEditModal])

  const handleInputChange = e => {
    const { name, value } = e.target
    setEditValues({ ...editValues, [name]: value })
  }

  const handleClickSubmit = async () => {
    const isEmail = await validateEmail(editValues["email"])
    if (isEmail) {
      setShowEmailError(false)
      handleEditParticipant()
    } else {
      setShowEmailError(true)
    }
  }

  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={CommonTexts.SAVE}
      handleShow={setShowEditModal}
      handleConfirm={handleClickSubmit}
      values={editValues}
      setValues={setEditValues}
      title={TableTexts.EDIT_TITLE + showEditModal}
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='name'
              handleChange={handleInputChange}
              label={TableTexts.NAME}
              value={editValues.name}
            />
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='surname'
              handleChange={handleInputChange}
              label={TableTexts.SURNAME}
              value={editValues.surname}
            />
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='email'
              handleChange={handleInputChange}
              label={TableTexts.EMAIL}
              value={editValues.email}
              error={showEmailError}
              helperText={showEmailError && TableTexts.EMAIL_ERROR}
              inputProps={{
                endAdornment: showEmailError && <InputAdornment position="end"><ErrorIcon
                  style={{ color: AppColors.RED }} /></InputAdornment>,
              }}
            />
          </FormControl>
        </Grid>
      }
      show={showEditModal}
    />
  );
}

EditModal.propTypes = {
  setShowEditModal: PropTypes.func.isRequired,
  handleEditParticipant: PropTypes.func.isRequired
}

function DownloadPdfModal({
  selected,
  setShowDownloadPdfModal,
  showDownloadPdfModal,
  handleDownloadPdf
}) {

  useEffect(() => {

  }, [])

  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={CommonTexts.DOWNLOAD}
      handleShow={setShowDownloadPdfModal}
      handleConfirm={handleDownloadPdf}
      title={TableTexts.DOWNLOAD_ANSWER + showDownloadPdfModal}
      body={

        <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
          text={"You are going to download the pdf of the Summary"
          } />
      }
      show={showDownloadPdfModal}
    />
  )
}

DownloadPdfModal.propTypes = {}

/**
 * @Page
 * Page for the dashboard of the study
 */
const DashboardPage = ({ drawer, drawerStudy }) => {
  const storageManager = new StorageManager();
  const [study, setStudy] = useState();
  const [dataDashboard, setDataDashboard] = useState()
  const permissions = useRef();
  const id = useRef();
  const [open, setOpen] = useState(drawer);//false
  // * openStydy means openDrawer
  const [openStudy, setOpenStudy] = useState(drawerStudy);//false
  const [forms, setForms] = useState();
  const [idForm, setIdForm] = useState()
  const initialValues = { name: "", surname: "", birthdate: "", email: "" }
  const [values, setValues] = useState(initialValues)
  const [editValues, setEditValues] = useState(initialValues)
  const [selectedDate, setSelectedDate] = useState(null)
  const [showAddModal, setShowAddModal] = useState(-999)
  const [showEditModal, setShowEditModal] = useState(-999)
  const [showDeleteModal, setShowDeleteModal] = useState(-999)
  const [showDownloadPdfModal, setShowDownloadPdfModal] = useState(-999)
  const [showDownload, setShowDownload] = useState(-999)
  const [loading, setLoading] = useState(false);
  const [allSummaries, setAllSummaries] = useState([])
  // * Sets the size of the cards on top of the table
  const [cardWidth, setCardWidth] = useState("600px")
  const isAdmin = storageManager.getAdmin()
  const [headCells, setHeadCells] = useState([{
    id: "id",
    numeric: true,
    disablePadding: false,
    label: TableTexts.ID,
    isSortable: false
  },
  //{ id: "participant", numeric: true, disablePadding: false, label: TableTexts.PARTICIPANT, isSortable: false },
  { id: "submitted_at", numeric: false, disablePadding: false, label: TableTexts.SUBMITTED, isSortable: true },
  { id: "user", numeric: false, disablePadding: false, label: TableTexts.USER, isSortable: false },
  { id: "actions", numeric: false, disablePadding: false, label: "", isSortable: false },
  ])

  const [questionsNames, setQuestionsNames] = useState([])
  // ! Temporary, holds the data as it comes to the request to prevent an extra request in download modal.
  // TODO: Get rid of code duplication regarding data processing in getAnswers here and in handleClickSubmit in DownloadModal
  const [answersRawData, setAnswersRawData] = useState([])
  const [firstSubmittedAnswerDate, setFirstSubmittedAnswerDate] = useState()
  const [answersData, setAnswersData] = useState([])

  const classes = useStyles();
  const { trackPageView } = useMatomo()

  useEffect(() => {
    trackPageView()
  }, [])
  //This function retrives the stored permissions and id from local storage
  useEffect(() => {
    const newItem = JSON.parse(localStorage.getItem("dashBoardSection"));
    permissions.current = newItem.permissions;
    id.current = newItem.id;

  }, [])

  /**
   * `getStudy` is an async function that makes a GET request to the `/study/:id` endpoint, and sets the
   * response data to the `study` state variable
   */
  const getStudy = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${id.current}`);
      setStudy(response.data.data)
    } catch (err) {
      setStudy(err.message)
    }
  }

  /**
   * It gets the data from the API and sets it to the dataDashboard variable.
   */
  const getDashboard = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/dashboard/${id.current}`);
      setDataDashboard(response.data.data)
    } catch (err) {
      setStudy(err.message)
    }
  }
  /**
   * It gets the forms for the study.
   */
  const getForms = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${id.current}/summary-forms`);
      setForms(response.data.data.items)

    } catch (err) {
      setForms(err.message)
    }
  }

  /**
     * It gets the answers from the API and then it sorts them by the order of the questions in the form
     */
  const getAnswers2 = async () => {
    setLoading(true)
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/form/${idForm}/answers`);

      const uniqueQuestions = response.data.data.item.flatMap(item => item.answers).reduce((questions, currentAnswer) => {
        if (currentAnswer.question && !questions.some(question => question.name === currentAnswer.question.variable_name)) {
          questions.push({
            name: currentAnswer.question.variable_name,
            sectionId: currentAnswer.question.section_id,
            order: currentAnswer.question.order
          })
        }
        return questions
      }, [])

      setQuestionsNames(uniqueQuestions)

      const sortedQuestions = [...uniqueQuestions]
        .sort((a, b) => a.sectionId - b.sectionId || a.order - b.order)
        .map(question => question.name)


      // ! Direct mutation of headCells state
      // TODO: Immutable
      for (var i = 0; i < sortedQuestions.length; i++) {
        headCells.splice(i + 2, 0, {
          id: sortedQuestions[i],
          numeric: false,
          disablePadding: false,
          label: `${sortedQuestions[i]}`,
          isSortable: false
        })
      }

      // TODO: Simplify it
      var data = []
      var allSummaries = []
      var dates = []
      response.data.data.item.forEach((res) => {
        var summary = {}
        summary["id"] = res.id
        summary["uuid"] = res.uuid

        var answers = []
        var entriesValues = {}
        entriesValues["id"] = res.id
        if (res.participant == null) {
          entriesValues["participant"] = "Anonymous"
        } else {
          entriesValues["participant"] = res.participant.uuid
        }
        for (var i = 0; i < res.answers.length; i++) {
          if (res.answers[i].question !== null && res.answers[i].question.type !== "INFO") {
            answers.push(res.answers[i])
          }
        }

        orderArray(sortedQuestions, answers)

        for (var j = 0; j < sortedQuestions.length; j++) {
          if (answers[j] != undefined) {
            if (answers[j].data === answers[j].data_label) {
              entriesValues[`${sortedQuestions[j]}`] = answers[j].data
            } else if (answers[j].question.type.includes("SELECT")) {
              entriesValues[`${sortedQuestions[j]}`] = `[${answers[j].data}] ` + answers[j].data_label
            } else {
              entriesValues[`${sortedQuestions[j]}`] = `[${answers[j].data}] ` + answers[j].data_label
            }
            //entriesValues[`${sortedQuestions[j]}`] = answers[j].data
          } else {
            entriesValues[`${sortedQuestions[j]}`] = "-"
          }
        }


        if (answers.length === 0) {
          for (var y = 0; y < answers.length; y++) {
            entriesValues[`${sortedQuestions[y]}`] = "-"
          }
        }
        moment.locale("es")
        entriesValues["submitted_at"] = moment(res.updated_at).format("DD-MM-YYYY HH:mm")
        entriesValues["compare_submitted_at"] = res.updated_at

        dates.push(new Date(res.updated_at))

        if (res.user == null) {
          entriesValues["user"] = "Anonymous"
        } else {
          entriesValues["user"] = res.user.username
        }
        data.push(entriesValues)
        allSummaries.push(summary)
      });
      data.reverse()
      const minDate = new Date(Math.min(...dates));
      setFirstSubmittedAnswerDate(minDate)
      setAnswersData(data)
      setAllSummaries(allSummaries)
      setAnswersRawData(response.data.data.item)
    } catch (err) {
      setAnswersData(err.message)
      setAnswersData([])
    }
    setLoading(false)
  }

  function debounce(fn, ms) {
    //This will run the code on every 1 second
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }
  useEffect(() => {
    // !  PROBLEM
    const debouncedHandleResize = debounce(function handleResize() {
      //give the paddingLeft size base on drawer open or closed and window size
      if (openStudy) {
        if (document.body.clientWidth >= 640 && document.body.clientWidth < 2560) {
          // setDevicesSize("280px")
          setCardWidth("340px")
          // setTableWidth("100%")
          // setExportButtonMargin("5em")

        } else if (document.body.clientWidth >= 2560) {
          // setDevicesSize("280px")
          // setTableWidth("2000px")
          // setExportButtonMargin("38em")
        }
        else {
          // setDevicesSize("10px")
          setCardWidth("200px")
          // setTableWidth("100%")
          // setExportButtonMargin("3em")

        }

      } else {

        if (document.body.clientWidth >= 2560) {
          // setDevicesSize("150px")
          // setTableWidth("2250px")
          // setExportButtonMargin("38em")

        } else if (document.body.clientWidth >= 640 && document.body.clientWidth < 2560) {
          // setDevicesSize("108px")
          setCardWidth("340px")
          // setTableWidth("100%")
          // setExportButtonMargin("2.9em")

        } else if (document.body.clientWidth <= 500) {
          // setDevicesSize("180px")
          setCardWidth("350px")
          // setTableWidth("100%")
          // setExportButtonMargin("1em")
        }
      }

    }, 300)

    // Add event listener to listen for window sizes 
    window.addEventListener("resize", debouncedHandleResize);
    // Call handler right away so state gets updated with initial window size

    debouncedHandleResize()
    return _ => {
      window.removeEventListener("resize", debouncedHandleResize)

    }

  }, [open, openStudy])

  useEffect(() => {
    getStudy()
    getForms()
    getDashboard()
  }, []);

  useEffect(() => {
    setOpen(drawer)
    setOpenStudy(drawerStudy)

  }, [drawer, drawerStudy])

  useEffect(() => {
    setLoading(false)
    headCells.splice(2, headCells.length - 5)
    if (idForm !== undefined) {
      // getMaxLengthAnswers().then(getAnswers)
      getAnswers2()
    }

  }, [idForm])

  const handleChangeForm = (event) => {
    setIdForm(event.target.value);
  };

  //Function to open the modal of the questions
  const handleOpenModal = () => {
    setShowDownload(1)
  }

  //Function to show or not the addModal
  const handleAddParticipant = () => {
    setShowAddModal(-999)
  }

  //Function to show or not the editModal
  const handleEditParticipant = () => {
    setShowEditModal(-999)
  }

  /**
   * It takes the uuid of the summary that the user wants to download, and then it sends a GET request
   * to the server, which returns a PDF file
   */
  const handleDownloadPdf = async () => {

    const uuid = allSummaries.find(o => o.id === showDownloadPdfModal).uuid;

    try {
      const response = axios(`${BASE_PATH} / print / ${uuid} `, {
        method: "GET",
        responseType: "blob", //Force to receive data in a Blob Format
        headers: {
          "Authorization": `Bearer ${storageManager.getToken()} `
        }
      })
        .then(response => {//Create a Blob from the PDF Stream
          const file = new Blob(
            [response.data],
            { type: "application/pdf" });//Build a URL from the file
          const fileURL = URL.createObjectURL(file);//Open the URL on new Window
          window.open(fileURL);
        })
        .catch(error => {

        });
      setShowDownloadPdfModal(-999)
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
  }

  /**
   * It takes the selected rows from the table and sends them to the backend to be deleted
   */
  const handleDeleteParticipant = async () => {
    setLoading(true)
    let body = null
    if (selectedDate !== null) {
      const result = selectedDate.map(a => a.id);
      body = { "ids": result }
    } else {
      body = { "ids": [showDeleteModal[0]] }
    }

    try {
      const response = await MyAxiosInstance.post(`${BASE_PATH}/form-answers`, body);
      setSelectedDate(null)
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
    headCells.splice(2, headCells.length - 5)
    // getMaxLengthAnswers().then(getAnswers)
    getAnswers2()
    setShowDeleteModal(-999)
  }

  return <>
    {study &&
      <DrawerUhda id={id.current} drawer={open} drawerStudy={openStudy} select={0} settings={study.permissions} />}
    <Grid container style={{ paddingLeft: openStudy ? "190px" : "28px", paddingRight: "24px", minWidth: "100%" }}
      direction={"column"}
      spacing={2}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Grid item>
        {study && <BreadcrumbsUhda routes={
          [
            { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
            {
              id: 1,
              label: `${study.translations[0].study_title}`,
              studyId: id.current,
              permissions: permissions.current,
              disabled: true
            },
            { id: 2, label: LabelsStudy.DASHBOARD },
          ]
        } />}
      </Grid>

      {dataDashboard && <Grid container >
        <Grid item style={{ maxWidth: cardWidth }}>
          <CardUhda width={"303px"} height={"150px"} bg={AppColors.WHITE} children={
            <Grid container
              xs={8}
              sm={5}
              md={8}
              lg={5}
              xl={10} spacing={1}
              style={{ position: "relative", margin: "1em", display: "flex", minWidth: 500 }}
            >
              <Box>
                <Grid item xs={6} sm container>
                  <Grid container direction="column" spacing={1}>
                    <Grid item xs>
                      <TextUhda margin={0} text={DashboardTexts.STUDY_TEAM_MEMBERS}
                        color={AppColors.SECONDARY_TITLE}
                        type={textType.SUBTITLE} />
                    </Grid>
                    <Grid item xs>

                      <TextUhda margin={0} text={dataDashboard.team_members_current}
                        color={AppColors.BLACK}
                        type={textType.NUMBER} />
                    </Grid>

                    {dataDashboard.team_members_percent_lastweek < 0 && <Grid container item>
                      <TextUhda margin={0}
                        text={`${(dataDashboard.team_members_percent_lastweek).toFixed(1).replace(".", ",")}% `}
                        color={AppColors.RED}
                        type={textType.SUBTITLE_BOLD} />
                      <img alt='icon' style={{ height: 16, width: 16, paddingTop: 2 }}
                        className={classes.imageIcon} src={arrowDown} />

                      <TextUhda margin={2} text={"Since last week"}
                        color={AppColors.SECONDARY_SUBTEXT}
                        type={textType.BODY_SUBTEXT} />

                    </Grid>}

                    {dataDashboard.team_members_percent_lastweek > 0 && <Grid container item>
                      <TextUhda margin={0}
                        text={`${(dataDashboard.team_members_percent_lastweek).toFixed(1).replace(".", ",")}% `}
                        color={AppColors.GREEN}
                        type={textType.SUBTITLE_BOLD} />
                      <img alt='icon' style={{ height: 16, width: 16, paddingTop: 2 }}
                        className={classes.imageIcon} src={arrowUp} />

                      <TextUhda margin={2} text={"Since last week"}
                        color={AppColors.SECONDARY_SUBTEXT}
                        type={textType.BODY_SUBTEXT} />

                    </Grid>}
                  </Grid>
                </Grid>
              </Box>
              <Grid item xs={1} style={{ marginLeft: dataDashboard.team_members_percent_lastweek == "n/a" ? "5em" : "2em", marginTop: "30px" }} container>
                <Avatar
                  className={classes.avatar}
                  sx={{ width: 62, height: 62, bgcolor: AppColors.CBM_SAND_OPACITY }}>
                  <img alt='icon' style={{ height: 25, width: 25 }} className={classes.imageIcon}
                    src={teamMembersIcon} />
                </Avatar>

              </Grid>
            </Grid>

          } />
        </Grid>

        <Grid item style={{ maxWidth: cardWidth, marginLeft: "70px" }}>
          <CardUhda width={"303px"} height={"150px"} bg={AppColors.WHITE} children={
            <Grid container
              xs={8}
              sm={5}
              md={8}
              lg={5}
              xl={10} spacing={1}
              style={{ position: "relative", margin: "1em", display: "flex", minWidth: 500 }}
            >
              <Box>
                <Grid item xs={6} sm container>
                  <Grid container direction="column" spacing={1}>
                    <Grid item xs>
                      <TextUhda margin={0} text={DashboardTexts.DATA_POINTS_COLLECTED}
                        color={AppColors.SECONDARY_TITLE}
                        type={textType.SUBTITLE} />
                    </Grid>
                    <Grid item xs>
                      <TextUhda margin={0} text={dataDashboard.data_points_current}
                        color={AppColors.BLACK}
                        type={textType.NUMBER} />
                    </Grid>

                    {dataDashboard.data_points_percent_lastweek < 0 && <Grid container item>
                      <TextUhda margin={0}
                        text={`${(dataDashboard.data_points_percent_lastweek).toFixed(1).replace(".", ",")}% `}
                        color={AppColors.RED}
                        type={textType.SUBTITLE_BOLD} />
                      <img alt='icon' style={{ height: 16, width: 16, paddingTop: 2 }}
                        className={classes.imageIcon} src={arrowDown} />

                      <TextUhda margin={2} text={"Since last week"}
                        color={AppColors.SECONDARY_SUBTEXT}
                        type={textType.BODY_SUBTEXT} />

                    </Grid>}
                    {dataDashboard.data_points_percent_lastweek > 0 && <Grid container item>
                      <TextUhda margin={0}
                        text={`${(dataDashboard.data_points_percent_lastweek).toFixed(1).replace(".", ",")}% `}
                        color={AppColors.GREEN}
                        type={textType.SUBTITLE_BOLD} />
                      <img alt='icon' style={{ height: 16, width: 16, paddingTop: 2 }}
                        className={classes.imageIcon} src={arrowUp} />

                      <TextUhda margin={2} text={"Since last week"}
                        color={AppColors.SECONDARY_SUBTEXT}
                        type={textType.BODY_SUBTEXT} />

                    </Grid>}

                  </Grid>
                </Grid>
              </Box>
              <Grid item xs={1} style={{ marginLeft: dataDashboard.data_points_percent_lastweek == "n/a" ? "2em" : "2em", marginTop: "30px" }} container>
                <Avatar
                  className={classes.avatar}
                  sx={{ width: 62, height: 62, bgcolor: AppColors.CBM_SAND_OPACITY }}>
                  <img alt='icon' style={{ height: 25, width: 25 }} className={classes.imageIcon}
                    src={dataPointsIcon} />
                </Avatar>

              </Grid>
            </Grid>

          } />
        </Grid>
      </Grid>}

      <Grid container style={{ paddingTop: "2em" }} direction={"column"}>

        <Box style={{ display: "flex", justifyContent: "space-between" }} >
          <FormControl
            style={{ width: "272px" }}
            variant="outlined"
            className={classes.textFieldLabel}
          >
            <InputLabel
              style={{ color: AppColors.PRIMARY, paddingTop: 0 }}
            >
              {DashboardTexts.CHOOSE_DATA}
            </InputLabel>
            {forms && <Select

              // className={classes.textFieldLabel}
              label={DashboardTexts.CHOOSE_DATA}
              // MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              onChange={handleChangeForm}>
              {forms.map(form => (
                // eslint-disable-next-line react/jsx-key
                <MenuItem value={form.id}>{form.form_translations[0].title}</MenuItem>))}

            </Select>}
          </FormControl>

          {(idForm && answersData.length !== 0) &&
            <div><ButtonFilledUhda margin={"10px"} marginLeft={"0.7em"} type={textType.SUBTITLE_BOLD}
              onClick={handleOpenModal}
              icon={<img alt='icon' style={{ height: 25, width: 25 }}
                className={classes.imageIcon} src={downloadEnabledIcon} />}
              text={CommonTexts.EXPORT} /></div>}

          {(!idForm || answersData.length === 0) &&
            <div><ButtonFilledUhda margin={"10px"} marginLeft={"0.7em"} type={textType.SUBTITLE_BOLD}
              isDisabled={true}
              backGroundColor={AppColors.BACKGROUND} color={AppColors.SUBTEXT}
              border={"2px solid"}
              icon={<img alt='icon' style={{ height: 25, width: 25 }}
                className={classes.imageIcon} src={downloadDisabledIcon} />}
              text={CommonTexts.EXPORT} /></div>}
        </Box>

        {
          loading ?
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
            :
            <Grid item style={{ maxWidth: "100%" }}
            >
              {idForm && <TableBaseUhda
                tableTitle={TableTexts.FORM_RESPONSES}
                headCells={headCells}
                dataToUse={answersData}
                addModal={AddModal}
                deleteModal={DeleteModal}
                editModal={EditModal}
                initialValues={initialValues}
                values={values}
                setValues={setValues}
                editValues={editValues}
                setEditValues={setEditValues}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                handleAddParticipant={handleAddParticipant}
                handleEditParticipant={handleEditParticipant}
                handleDeleteParticipant={handleDeleteParticipant}
                handleDownloadPdf={handleDownloadPdf}
                showAddModal={showAddModal}
                setShowAddModal={setShowAddModal}
                showEditModal={showEditModal}
                setShowEditModal={setShowEditModal}
                showDeleteModal={showDeleteModal}
                downloadPdfModal={DownloadPdfModal}
                setShowDeleteModal={setShowDeleteModal}
                showDownloadPdfModal={showDownloadPdfModal}
                setShowDownloadPdfModal={setShowDownloadPdfModal}
                interactive={true}
                edit={false}
                paperMargin="8px"
                canDelete={isAdmin == 1 || study.permissions.includes("delete-data")}
                canDownload={false}
                textDataEmpty={TableTexts.NO_ELEMENTS}
                orderByName={"submitted_at"}
              />}

              {!idForm && <TableBaseUhda
                tableTitle={TableTexts.FORM_RESPONSES}
                headCells={headCells}
                dataToUse={null}
                addModal={AddModal}
                deleteModal={DeleteModal}
                editModal={EditModal}
                initialValues={initialValues}
                values={values}
                setValues={setValues}
                editValues={editValues}
                setEditValues={setEditValues}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                handleAddParticipant={handleAddParticipant}
                handleEditParticipant={handleEditParticipant}
                handleDeleteParticipant={handleDeleteParticipant}
                showAddModal={showAddModal}
                setShowAddModal={setShowAddModal}
                showEditModal={showEditModal}
                setShowEditModal={setShowEditModal}
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                interactive={true}
                edit={false}
                paperMargin="8px"
                canDownload={false}
                textDataEmpty={TableTexts.SELECT_FORM}
                orderByName={"submitted_at"}
              />}

            </Grid>}

      </Grid>
    </Grid>
    {showDownload >= 0 &&
      <DownloadModal
        questionsNames={questionsNames}
        answersData={answersRawData}
        minDate={firstSubmittedAnswerDate}
        formId={idForm}
        studyId={id.current}
        initialValues={initialValues}
        values={values}
        setValues={setValues}
        showDownload={showDownload}
        setShowDownload={setShowDownload}
      />
    }
  </>;
}
export default DashboardPage;
