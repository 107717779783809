import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

function DatePickerUhda({ value, setValue, label, minDate, maxDate, ...other }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={setValue}
        label={label}
        minDate={minDate}
        maxDate={maxDate}
        inputFormat="dd/MM/yyyy"
        renderInput={(params) => <TextField variant="outlined" {...params} />}
        {...other}
      />
    </LocalizationProvider>
  );
}

export default DatePickerUhda