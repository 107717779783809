/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    Button,
    Checkbox, Fade,
    Grid,
    IconButton, Menu, MenuItem,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import { AppColors } from "../../resources/AppColors";
import { QUESTION_INFO, questionTypes, questionTypesByName } from "../../resources/QuestionConstants";
import { menuOptions, SnackBarResponses } from "../../locale/en";
import IconProviderUhda from "../IconProviderUhda";
import Icons from "../../resources/Icons";
import parse from "html-react-parser"
import Moment from "moment";
import { useHistory } from "react-router-dom";
import { StorageManager } from "../../utils";
import { SearchBar } from "../SearchBar/SearchBar";
const useStyles = makeStyles(() => ({
    table: {
        minWidth: 650,
        borderCollapse: "separate",
        borderSpacing: "0px 4px",
        width: "97.4%"
    },
    tableHead: {
        backgroundColor: AppColors.CBM_SAND
    },
    tableCell: {
        height: "auto",
        paddingLeft: 0,
        fontSize: "16px",
        color: AppColors.WHITE,

    },
    tableCellInit: {
        padding: 0,
        paddingLeft: "16px",
        borderTopLeftRadius: "5px!important",
        borderBottomLeftRadius: "5px!important",
        borderLeft: "2px solid #B09C78",
        borderTop: "2px solid #B09C78",
        borderBottom: "2px solid #B09C78",
        backgroundColor: AppColors.WHITE,
        color: AppColors.BLACK,
    },
    tableCellDraggable: {
        padding: 0,
        paddingRight: 20,
        borderRadius: "2px solid #B09C78",
        borderTop: "2px solid #B09C78",
        borderBottom: "2px solid #B09C78",
        backgroundColor: AppColors.WHITE,
        color: AppColors.BLACK,
        whiteSpace: "normal",
        wordBreak: "break-word"
    },
    tableCellDraggableDisabled: {
        padding: 0,
        paddingRight: 20,
        borderRadius: "2px solid #B09C78",
        borderTop: "2px solid #B09C78",
        borderBottom: "2px solid #B09C78",
        backgroundColor: AppColors.WHITE,
        color: AppColors.GREY,
        whiteSpace: "normal",
        wordBreak: "break-word",
        fontStyle: "italic"
    },
    tableCellFinish: {
        padding: 0,
        borderTopRightRadius: "5px!important",
        borderBottomRightRadius: "5px!important",
        borderRight: "2px solid #B09C78",
        borderTop: "2px solid #B09C78",
        borderBottom: "2px solid #B09C78",
        backgroundColor: AppColors.WHITE,
        color: AppColors.PRIMARY,

    },
    switch_track: {
        backgroundColor: AppColors.SECONDARY,
    },
    switch_base: {
        color: AppColors.SECONDARY,
        "&.Mui-disabled": {
            color: AppColors.PRIMARY_WITH_OPACITY
        },
        "&.Mui-checked": {
            color: AppColors.PRIMARY
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: AppColors.PRIMARY,
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: AppColors.PRIMARY
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: AppColors.PRIMARY,
        },
    },

}));

const CheckBoxStudyUhda = ({
    headCells,
    userData,
    loading,
    showDeleteStudies,
    handleClickEdit,
    selectedStudies,
    setSelectedStudies,
}) => {
    const [tableData, setTableData] = useState([])
    const [selected, setSelected] = useState([]);

    const classes = useStyles();

    const getData = async () => {
        var response = Object.values(userData)
        var data = []
        response.forEach((res) => {
            data.push(res)
        })
        setTableData(data)
    }
    const [studies, setStudies] = useState(Object(userData));

    useEffect(() => {
        getData()

    }, [])

    //Function to select all the items on the table from the button upside
    const handleSelectAllClick = (event) => {
        const questionsFinal = [];
        let a = []
        if (event.target.checked && tableData) {
            const newSelected = tableData.map((n) => n.id);
            a = tableData.map((n) => n.id);
            setSelected(newSelected);
            for (let i = 0; i < newSelected.length; i++) {
                questionsFinal.push(studies.find(x => x.id === a[i]))
            }
            setSelectedStudies(questionsFinal)
            return;
        }
        setSelectedStudies([])
        setSelected([]);
    };

    //Function to select one item of the table
    const handleSelectClick = (event, question) => {
        const selectedIndex = selected.indexOf(question.id);
        let newSelected = [];
        const questionsFinal = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, question.id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        for (let i = 0; i < newSelected.length; i++) {
            questionsFinal.push(studies.find(x => x.id === newSelected[i]))
        }
        setSelected(newSelected);
        setSelectedStudies(questionsFinal)

    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
        <>
            <TableContainer>
                <SearchBar
                    entities={userData}
                    setFilteredValues={setStudies}
                    placeholder={"Search by title"}
                    searchKey={"title"}
                />
                <Table className={classes.table} aria-label={"simple table"} style={{ marginLeft: 0, marginRight: -15 }}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell padding='checkbox'>
                                {tableData.length !== 0 && <Checkbox
                                    style={{ marginLeft: "0.55em", color: AppColors.PRIMARY }}
                                    color="primary"
                                    indeterminate={selected.length > 0 && selected.length < tableData.length}
                                    checked={tableData.length > 0 && selected.length === tableData.length}
                                    onChange={handleSelectAllClick}
                                    inputProps={{ "aria-label": "select all" }}
                                />}
                            </TableCell>

                            {headCells.map((headCell) => (
                                <TableCell className={classes.tableCell} key={headCell.id}> {headCell.label}</TableCell>
                            ))}
                            <TableCell />
                        </TableRow>
                    </TableHead>

                    <TableBody
                        className={classes.tableCell}>
                        {(studies && !loading) && studies.map((study, index) => {
                            const isItemSelected = isSelected(study.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (

                                <TableRow hover
                                    role="checkbox"
                                    aria-checked={false}
                                    tabIndex={-1}
                                    key={study.id}
                                    selected={false}

                                >
                                    <TableCell padding={"checkbox"} className={classes.tableCellInit} style={{ width: 50 }}>
                                        <Checkbox
                                            style={{ color: AppColors.PRIMARY }}
                                            onClick={(event) =>
                                                handleSelectClick(event, study)
                                            }
                                            checked={isItemSelected}
                                            inputProps={{ "aria-labelledby": labelId }}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableCellDraggable} style={{ width: 10 }}>

                                    </TableCell>
                                    <TableCell data-testId={"studyRow"} id="studyTitle"
                                        className={classes.tableCellDraggable}>{study.translations[0].study_title}</TableCell>
                                    <TableCell style={{ width: "35em" }}
                                        className={classes.tableCellDraggable}>{study.translations[0].short_description}</TableCell>
                                    <TableCell
                                        className={classes.tableCellDraggable}>{study.tags != "" ? study.tags : "-"}</TableCell>
                                    <TableCell
                                        className={classes.tableCellDraggable}>{study.user_created ? study.user_created.username : "-"}</TableCell>
                                    <TableCell
                                        className={classes.tableCellDraggable}>{Moment(study.created_at).format("DD-MM-YYYY HH:mm")}</TableCell>

                                    <TableCell data-testId={"menuButton"} className={classes.tableCellFinish} align='left'>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                        </Grid>

                                    </TableCell>
                                </TableRow>

                            )
                        })}
                    </TableBody>

                </Table>

                {studies.length === 0 &&
                    <Grid
                        style={{ height: "200px" }}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography>
                            Nothing to see here yet...
                        </Typography>
                    </Grid>
                }
            </TableContainer >

            <Grid container justifyContent={"flex-end"} style={{ paddingRight: "1.5em" }}>
                <Typography style={{ color: AppColors.PRIMARY }}>
                    Total: {studies.length} questions
                </Typography>
            </Grid>
        </>
    )

}

export default CheckBoxStudyUhda
