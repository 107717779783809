/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef } from "react";
import {
  CommonTexts as CommonsText,
  ErrorTexts,
  LabelsDrawer,
  LabelsSettings,
  LabelsStudy,
  SnackBarResponses
} from "../locale/en";
import { CardHeader, Checkbox, Divider, FormControl, Grid, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import BreadcrumbsUhda from "../components/BreadcrumbsUhda";
import makeStyles from '@mui/styles/makeStyles';
import { AppColors } from "../resources/AppColors";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import { Alert } from '@mui/material';
import CardUhda from "../components/CardUhda";
import { textType, useTextStyles } from "../resources/AppTexts";
import StudyModal from "../modal/StudyModal"
import StudiesService from "../services/StudiesService";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";
import { useHistory, useLocation } from "react-router-dom";
import TextUhda from "../components/TextUdha";
import DrawerUhda from "../components/DrawerUhda";
import TeamMembersService from "../services/TeamMembersService";
import TagsService from "../services/TagsService";
import { orderArrayAlphabetical } from "../utils/HelperFunctions";
import { StorageManager } from "../utils";
import { useMatomo } from "@jonkoops/matomo-tracker-react"
import { useDispatch } from "react-redux";
import { toast } from "../reducers/notificationsReducer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const useStyles = makeStyles((theme) => ({
  title: {
    font: "Roboto",
    color: AppColors.PRIMARY
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },

  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
        backgroundColor: AppColors.BACKGROUND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      backgroundColor: "transparent"

    },
    "&:before": {
      color: AppColors.WHITE,
    },
    "&:after": {
      borderBottomColor: AppColors.WHITE,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.WHITE,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  }
}));

/**
 * @Page
 * Settings page of a study that exits when a new study is created or when this study is modified
 */
const SettingsPage = ({ drawer, drawerStudy }) => {
  const location = useLocation();
  const texts = useTextStyles();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [privado, setPrivado] = useState();
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [study, setStudy] = useState();
  const classes = useStyles();
  const permissions = useRef();//location.state.permissions
  const id = useRef();
  const [idStudy, setId] = useState(id.current);
  const [labels, setLabels] = useState()
  const history = useHistory()
  const [, setOpenSnackPermissionsEdit] = React.useState(false);
  const [isTypingTitle, setIsTypingTitle] = useState(false)
  const [isTypingDesc, setIsTypingDesc] = useState(false)
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [optionsByName, setOptionsByName] = useState({});
  const [options, setOptions] = useState([]);
  const storageManager = new StorageManager()
  const isAdmin = storageManager.getAdmin()
  const isAllSelected =
    options.length > 0 && selectedRoles.length === options.length;
  const [permissionsNew] = useState([
    "study-duplicate",
    "study-delete",
    "study-edit-settings",
    "teammember-create",
    "teammember-edit",
    "teammember-delete",
    "access-dissemination",
    "participants-study-view",
    "participant-create",
    "participant-edit",
    "participant-delete",
    "forms-view",
    "form-create",
    "form-edit",
    "form-duplicate",
    "form-delete",
    "question-create",
    "question-edit",
    "question-duplicate",
    "question-delete",
    "collect-data",
    "access-data"
  ])

  const [labelNew] = useState([
    "Dashboard",
    "Forms",
    "Participants",
    "Dissemination",
    "Team Members",
    "Settings"
  ])

  const { trackPageView } = useMatomo()

  const dispatch = useDispatch()

  useEffect(() => {
    trackPageView()
  }, [])

  /**
   * This function gets the route from local storage and sets the permissions and id state variables
   * accordingly
   */
  const getRoute = () => {
    const route = localStorage.getItem("route");

    if (route === "edit") {

      //This user wants to edit a study
      const newItem = JSON.parse(localStorage.getItem("sectionDetails"));
      permissions.current = newItem.permissions;
      id.current = newItem.detail;

    } else {
      //This user wants to create a study
      const n = JSON.parse(localStorage.getItem("createStudy"));
      permissions.current = n.permissions;
      id.current = n.detail;

    }

  }

  useEffect(() => {
    getRoute()
  }, [])

  /**
   * If the last value in the array is "all", then set the selected roles to either all of the options
   * or an empty array. Otherwise, set the selected roles to the value
   * @param event - The event that triggered the change.
   * @returns The value of the selected roles.
   */
  const handleChangeRole = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedRoles(selectedRoles.length === options.length ? [] : options);
      return;
    }
    setSelectedRoles(value);
  };

  /**
   * It gets the tags from the API and then sets the options and optionsByName state variables
   */
  const getTags = async () => {
    try {
      const responseTags = await TagsService.getTags();
      var roleList = [];
      var options = {};
      responseTags.data.data.items.forEach((role) => {
        options = { ...options, [role["name"]]: role.id };
        roleList.push(role.name);
      });
      orderArrayAlphabetical(roleList)
      setOptionsByName(options);
      setOptions(roleList);
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };

  /**
   * It takes the current permissions and checks if they include any of the permissions listed in the
   * if statements. If they do, it pushes the corresponding label to the label array
   */
  const knowPermissions = () => {
    const label = [];
    if (permissions.current.includes("access-data")) {
      label.push(LabelsStudy.DASHBOARD)
    }
    if (permissions.current.includes("forms-view")) {
      label.push(LabelsStudy.FORMS)
    }
    if (permissions.current.includes("participant-create") || permissions.current.includes("participant-edit") || permissions.current.includes("participant-delete")) {
      label.push(LabelsStudy.PARTICIPANTS)
    }
    if (permissions.current.includes("access-dissemination")) {
      label.push(LabelsStudy.DISSEMINATION)
    }
    if (permissions.current.includes("teammember-create") || permissions.current.includes("teammember-edit") || permissions.current.includes("teammember-delete")) {
      label.push(LabelsStudy.TEAM_MEMBERS)
    }
    if (permissions.current.includes("study-edit-settings")) {
      label.push(LabelsStudy.SETTINGS)
    }
    setLabels(label)

  };

  /**
   * If the user is an admin or has the permission to edit the study, then if the title or description is
   * empty, show a validation alert, otherwise, modify the study
   */
  const checkTextInputModify = () => {
    if (isAdmin == 1 || study.permissions.includes("study-edit-settings")) {
      if (title === "" || description === "") {
        setShowValidationAlert(true);
      } else {
        setShowValidationAlert(false);
        modifyStudy().then()
      }
    } else {
      dispatch(toast(SnackBarResponses.NOT_PERMISSION, "error"))
    }
  };

  /**
   * If the title or description is empty, show the validation alert. If not, create the study
   */
  const checkTextInputCreate = () => {
    if
      (title === "" || description === "" || title === undefined || description === undefined) {
      setShowValidationAlert(true);
    } else {
      dispatch(toast(SnackBarResponses.CHANGES_SAVED, "success"))
      createStudyEmpty().then()

    }
  };

  /**
   * It creates a new study and then redirects the user to the forms page of the study
   */
  const createStudyEmpty = async () => {
    const study = new StudyModal(title, description, privado, selectedRoles);
    try {
      const studyCreated = await StudiesService.postStudies(study.createToJSON)
      const id = studyCreated.data.data.item.id

      var label = [
        "Dashboard",
        "Forms",
        "Participants",
        "Dissemination",
        "Team Members",
        "Settings"
      ]

      const newObj = { "id": id, "value": 5, "permissions": label }
      //localStorage.setItem("sectionDetails", JSON.stringify(newObj));

      localStorage.setItem("dashBoardSection", JSON.stringify(newObj));

      history.push({
        pathname: `/study/${id}/forms`,
        state: { detail: id, value: labelNew.indexOf("Forms"), permissions: labelNew }
      })
      setId(id)
      getStudy()
      dispatch(toast(SnackBarResponses.CHANGES_SAVED, "success"))
      // eslint-disable-next-line no-empty
    } catch (error) {

    }

  }

  /**
   * It takes the values of the form and creates a new StudyModal object, then it calls the putStudies
   * method from the StudiesService class, and finally it calls the getStudy function to update the state
   * of the component
   */
  const modifyStudy = async () => {
    const study = new StudyModal(title, description, privado, selectedRoles, id.current);
    try {
      await StudiesService.putStudies(study.modifyToJSON)
      // eslint-disable-next-line no-empty
    } catch (error) {

    }
    getStudy()
    dispatch(toast(SnackBarResponses.CHANGES_SAVED, "success"))
  }

  /**
   * It gets the study from the backend and sets the state of the study, title, description, and selected
   * roles
   */
  const getStudy = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${id.current}`);
      setStudy(response.data.data)
      setTitle(response.data.data.translations[0]?.study_title)
      setDescription(response.data.data.translations[0]?.short_description)
      const tags = []
      response.data.data.tags.forEach((tag) => {
        tags.push(tag.name)
      })
      setSelectedRoles(tags);

    } catch (err) {
      setStudy(err.message)
    }
  }

  useEffect(() => {
    if (window.location.pathname != "/study") {
      id.current = window.location.pathname.split("/")[2]
    }
    //if (!location.state.detail) {
    if (id.current === null) {
      id.current = null
      const study = new StudyModal("", "", false);
    } else {

      getStudy()
    }
    getTags();

  }, [id.current])

  useEffect(() => {
    knowPermissions()
    //permissions.current.indexOf('Settings')
    localStorage.removeItem("dashBoardSection")

    if (study) {
      const newObj = { "id": id.current, "permissions": study.permissions }
      localStorage.setItem("dashBoardSection", JSON.stringify(newObj));
    } else {
      const newObj = { "id": id.current, "permissions": permissionsNew }
      localStorage.setItem("dashBoardSection", JSON.stringify(newObj));
    }
  }, [])

  const titleChange = (e) => {
    setTitle(e.target.value)
    setIsTypingTitle(true)
  }

  const descriptionChanged = (e) => {
    setDescription(e.target.value)
    setIsTypingDesc(true)
  }
  return (
    <div style={{ marginTop: "16px" }}>

      {study && <DrawerUhda id={id.current} drawer={drawer} drawerStudy={drawerStudy} select={3}
        settings={study.permissions} />}
      {!study &&
        <DrawerUhda id={id.current} drawer={drawer} drawerStudy={drawerStudy} select={5} settings={permissionsNew}
          newStudy={true} />}
      <Grid
        container
        style={{ marginLeft: drawerStudy ? "190px" : "28px", maxWidth: "500px" }}>
        <Grid item>
          {study && <BreadcrumbsUhda routes={
            [
              { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
              {
                id: 1, label: `${study.translations[0]?.study_title}`,
                disabled: true,
                studyId: id.current, permissions: permissions.current
              },
              { id: 2, label: LabelsStudy.SETTINGS },
            ]
          } />}
        </Grid>

        <Grid item >
          <CardUhda bg={AppColors.WHITE} children={
            <Grid container
              style={{ position: "relative", margin: "1em", display: "grid" }}
            >
              <Grid item xs zeroMinWidth>
                <CardHeader
                  classes={{
                    title: texts.title_bold,
                    root: classes.cardHeaderRoot,
                    content: classes.cardHeaderContent
                  }}
                  style={{
                    width: "232px",
                    color: AppColors.PRIMARY,
                    paddingBottom: "8px",
                    paddingLeft: 0,
                    paddingTop: 0
                  }}
                  title={
                    <TextUhda margin={0} style={"break-word"} type={textType.SUBTITLE_BOLD}
                      text={LabelsSettings.STUDY_DETAILS} />}
                />
              </Grid>
              <Divider style={{ marginLeft: "-32px", marginBottom: "20px" }} />
              <Grid container style={{ paddingRight: "2em", flexDirection: "column" }}>
                {study && <TextField
                  data-testId="studyTitle"
                  defaultValue={study.translations[0]?.study_title}
                  fullWidth
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                  label={LabelsSettings.STUDY_TITLE}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldLabel}
                  inputProps={{ maxLength: 500 }}

                />}
                {study && <TextField
                  data-testId="studyDescription"
                  defaultValue={study.translations[0]?.short_description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  label={LabelsSettings.STUDY_DESCRIPTION}
                  margin="normal"
                  variant="outlined"
                  multiline
                  className={classes.textFieldLabel}
                />}
                {!id.current && !study && <TextField
                  fullWidth
                  onChange={(e) => titleChange(e)}
                  data-testId="studyTitle"
                  type="text"
                  label={LabelsSettings.STUDY_TITLE}
                  // margin="normal"
                  variant="outlined"
                  className={classes.textFieldLabel}
                  inputProps={{ maxLength: 500 }}

                />}

                {!id.current && !study && <TextField
                  data-testId="studyDescription"
                  onChange={(e) => descriptionChanged(e)}
                  type="text"
                  label={LabelsSettings.STUDY_DESCRIPTION}
                  margin="normal"
                  variant="outlined"
                  multiline
                  className={classes.textFieldLabel}
                />}
              </Grid>
              <Grid container
                style={{
                  paddingRight: "2em",
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "row",
                  minWidth: "500px"
                }}>
                <Grid container spacing-xs-1 direction="column"
                  alignItems="flex-end"
                  justifyContent="center">
                  <Grid item style={{ maxWidth: "100%" }}>
                    <FormControl
                      margin="normal"
                      style={{ minWidth: 80, maxWidth: "100%" }}
                      variant="outlined"
                      className={classes.select}
                    >
                      <InputLabel
                        id="multiple-select-label">Tags</InputLabel>
                      <Select

                        data-testId={"selectTags"}
                        label="Role"
                        labelId="multiple-select-label"
                        multiple
                        value={selectedRoles}
                        onChange={handleChangeRole}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}>
                        <MenuItem
                          value="all"
                          classes={{ root: isAllSelected ? classes.selectedAll : "" }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{ indeterminate: classes.indeterminateColor }}
                              checked={isAllSelected}
                              indeterminate={
                                selectedRoles.length > 0 &&
                                selectedRoles.length < options.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: classes.selectAllText }}
                            primary="Select All"
                          />
                        </MenuItem>
                        {options.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ListItemIcon>
                              <Checkbox
                                checked={selectedRoles.indexOf(option) > -1}
                              />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item data-testId={"saveStudy"}>
                    {study &&
                      <ButtonFilledUhda onClick={checkTextInputModify} color={AppColors.WHITE}
                        margin={"0.7em"}
                        text={LabelsSettings.SAVE_CHANGES}
                      />}

                    {!id.current && !study &&
                      <ButtonFilledUhda onClick={checkTextInputCreate} color={AppColors.WHITE}
                        margin={"0.7em"}
                        text={CommonsText.CREATE}
                      />}
                  </Grid>
                  {showValidationAlert && (
                    <Grid item style={{ marginTop: "0.5em" }}><Alert
                      severity="error">{ErrorTexts.SETTINGS}</Alert></Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>} />
        </Grid>
      </Grid>

    </div>
  );
}

export default SettingsPage;
