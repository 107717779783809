// import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StyledMenu from './StyledMenu';
import { IconButton } from '@mui/material';
import { AppColors } from 'resources/AppColors';
import { MenuItem } from '@mui/material';

function RowMenuListActions({ children }) {
  return <>
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <IconButton
            variant="contained"
            {...bindTrigger(popupState)}
            // data-cy="row-menu-list-actions"
            data-testId='menuButton'
            size="large">
            <MoreHorizIcon sx={{ fill: AppColors.PRIMARY }} />
          </IconButton>
          <StyledMenu {...bindMenu(popupState)}>
            {children.map((menuOption, i) =>
              <MenuItem key={i} onClick={popupState.close} sx={{ p: 0 }} >
                {menuOption}
              </MenuItem>
            )}
          </StyledMenu>
        </>
      )}
    </PopupState>
  </>;
}

export default RowMenuListActions