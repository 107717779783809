import MyAxiosInstance from "../utils/MyAxiosInstance";
import {GET_ROLES_URL, POST_TEAM_MEMBERS_URL, TEAM_MEMBERS_URL, UPDATE_TEAM_MEMBERS_URL,} from "../resources/ApiUrls";

const updateTeamMember = (studyId, teamMemberId, body) =>
  MyAxiosInstance.put(UPDATE_TEAM_MEMBERS_URL(studyId, teamMemberId), body);

const getTeamMembers = (id) => MyAxiosInstance.get(TEAM_MEMBERS_URL(id));

const postTeamMember = (body, id) =>
  MyAxiosInstance.post(POST_TEAM_MEMBERS_URL(id), body);

const deleteTeamMember = (studyId, teamMemberId) =>
  MyAxiosInstance.delete(UPDATE_TEAM_MEMBERS_URL(studyId, teamMemberId));

const getRoles = () => MyAxiosInstance.get(GET_ROLES_URL());

const exports = {
  updateTeamMember,
  getTeamMembers,
  postTeamMember,
  deleteTeamMember,
  getRoles,
};
export default exports;
