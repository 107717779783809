import { Box, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AppColors } from "resources/AppColors";
import { findTranslation } from "utils/HelperFunctions";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
        backgroundColor: AppColors.BACKGROUND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
    marginTop: "16px"
  },
}));

export function SecondPageTranslateQuestionForm({ choiceQuestionsTranslations, handleTranslateAnswers, selectedQuestion, selectedLocale }) {
  const form = useSelector(({ form }) => form.data[0])

  const classes = useStyles()

  return (
    <Box width="100%" id="second-page-container">
      <Box display="flex" justifyContent="space-around">
        <TextField
          defaultValue={form.defaultLocale.name}
          disabled
          className={classes.textField}
          label={"Original Locale"}
          sx={{ minWidth: "45%" }}
        />
        <TextField
          defaultValue={form.locales.find(locale => locale.value === selectedLocale)?.name}
          disabled
          className={classes.textField}
          label={"Current Locale"}
          sx={{ minWidth: "45%" }}
        />
      </Box>
      <Typography
        variant="h6"
        sx={{ color: AppColors.PRIMARY, mt: 4, ml: 2, fontSize: "16px" }}
      >
        Answer Options
      </Typography>
      {selectedQuestion.choiceQuestions.map((answer, i) => (
        <Box
          display="flex"
          justifyContent="space-around"
          mt={2}
          key={answer.id}
        >
          <TextField
            defaultValue={findTranslation(answer?.translations, form.defaultLocale.value)?.text}
            disabled
            className={classes.textField}
            label={`Answer ${i + 1}`}
            sx={{ minWidth: "30%" }}
          />
          <TextField
            value={choiceQuestionsTranslations[i].text}
            onChange={(e) => handleTranslateAnswers(e, i)}
            className={classes.textField}
            label={`Translated Answer ${i + 1}`}
            sx={{ minWidth: "30%" }}
          />
          <TextField
            defaultValue={answer.value}
            disabled
            className={classes.textField}
            label={`Value ${i + 1}`}
            sx={{ minWidth: "30%" }}
          />
        </Box>
      ))}
    </Box>
  )
}