import { IconButton } from "@mui/material"
// import { useNavigate } from "react-router-dom"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AppColors } from "resources/AppColors";

function CreateButton({ entity }) {
  // TODO: Upgrade to use react-router-dom v6 or set this to use v5
  // const navigate = useNavigate()

  function handleAddForm() {
    // navigate(`create`, { state: { entity } })
  }

  return (
    <IconButton onClick={handleAddForm} size="large" data-cy="table-add-button">
      <AddCircleIcon sx={{ fill: AppColors.PRIMARY }} />
    </IconButton>
  )
}

export default CreateButton