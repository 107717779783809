import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $setBlocksType } from '@lexical/selection';
import { $createParagraphNode, $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, createCommand, ElementNode } from 'lexical';

// * Node Logic, to be added to the nodes list on initialConfig
export class TextareaNode extends ElementNode {
  // constructor(key) {
  //   super(key)
  // }

  static getType() {
    return 'textarea' // * Made up a name that doesn't collide with other names already in the editor
  }

  static clone(node) {
    return new TextareaNode(node.__key)
  }

  // * Creates a DOM div node
  createDOM(config) {
    const element = document.createElement('textarea')
    element.className = config.theme.textarea

    return element
  }

  // * Can change what is rendered, e.g. another class if certains conditions are met
  updateDOM() {
    return false
  }

  // * Controls what happens when backspace is pressed when the cursor is at the beginning of the node
  collapseAtStart() {
    const paragraph = $createParagraphNode();
    const children = this.getChildren();
    children.forEach(child => paragraph.append(child));
    this.replace(paragraph)

    return true
  }

  // * Hitting enter, the cursor escapes from the textarea. Shift + Enter adds a new line inside textarea
  insertNewAfter(selection, restoreSelection) {
    const newBlock = $createParagraphNode()
    const direction = this.getDirection()
    newBlock.setDirection(direction)
    this.insertAfter(newBlock, restoreSelection)

    return newBlock
  }
}

// * Lexical convention exports
export function $createTextBoxNode() {
  return new TextareaNode()
}

export function $isTextBoxNode(node) {
  return node instanceof TextareaNode
}

// * Can be used to override a command
export const INSERT_TEXTAREA_COMMAND = createCommand('insertTextarea')

// * REACT component to be returned as children of LexicalComposer
export function TextareaPlugin() {
  const [editor] = useLexicalComposerContext()
  if (!editor.hasNodes([TextareaNode])) { // * Assertion to check if node exists
    throw new Error('TextareaPlugin: TextareaNode not registered on editor')
  }
  editor.registerCommand(INSERT_TEXTAREA_COMMAND, () => {
    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
      $setBlocksType(selection, $createTextBoxNode)
    }
    return true // * Stops propagation to other command listeners (prevents overriding)
  }, COMMAND_PRIORITY_LOW) // * COMMAND PRIORITY OVERRIDES STUFF (EDITOR is the lowest priority)

  return null
}
