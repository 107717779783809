/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from "react";
import { AppBar, Box, Checkbox, Grid, TextField } from "@mui/material";
import { AppColors } from "../resources/AppColors";
import { StorageManager } from "../utils";
import AuthContext from "../context/authentication/authContext"
import makeStyles from '@mui/styles/makeStyles';
import CardUhda from "../components/CardUhda";
import NavbarUhda from "../components/NavbarUhda";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import ButtonUnfilledUhda from "../components/ButtonUnfilledUhda";
import { CommonTexts, SnackBarResponses } from "../locale/en";
import { textType } from "../resources/AppTexts";
import TextUhda from "../components/TextUdha";
import { useMatomo } from "@jonkoops/matomo-tracker-react"
import { useDispatch } from "react-redux";
import { toast } from "../reducers/notificationsReducer";

const useStyles = makeStyles((theme) => ({

  subText: {
    color: AppColors.SUBTEXT,
    backgroundColor: "red"
  },
  button: {
    textTransform: "none",
    color: AppColors.PRIMARY
  },
  title: {
    color: AppColors.PRIMARY
  },
  background: {
    backgroundColor: AppColors.BACKGROUND
  },
  backgroundBox: {
    backgroundColor: AppColors.WHITE
  },
  colorLabel: {
    color: AppColors.PRIMARY,
    opacity: "0,2",

  },
  textField: {
    border: "1px solid blue",
    borderRadius: "8px"
  },

  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },
    "& :-webkit-autofill": {
      transitionDelay: "9999s",
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

}));

/**
 * @page
 * Login page where the user enters the data and will be logged in
 */
export default function Login(props) {
  const storageManager = new StorageManager();
  const [checked, setChecked] = useState(false);
  const [username, setUsername] = useState(storageManager.getUsername());
  const [password, setPassword] = useState(storageManager.getPwd());
  const authContext = useContext(AuthContext)
  const { message, authenticated, login, checkAuth, change_req } = authContext;
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { trackPageView } = useMatomo()

  const dispatch = useDispatch()

  useEffect(() => {
    trackPageView()
  }, [])

  useEffect(() => {

    checkAuth()
    if (authenticated) {
      props.history.push("/studies")
    }
    if (change_req) {
      props.history.push("/change")
    }
    if (message) {
      dispatch(toast(message, "error"))
    }
  }, [message, authenticated, props.history, change_req])

  /**
   * `handleChange` is a function that takes an event as an argument and sets the state of `checked` to
   * the value of the event's target's checked property
   * @param event - The event that triggered the change.
   */
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  /**
   * It takes the username and password from the state and sends it to the login function
   * @param e - the event object
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username === "" || password === "") setShowValidationAlert(true);
    else {
      const params = {
        username,
        password,
        "remember-me": checked,
      };
      setShowValidationAlert(false);
      login(params)
    }
  };

  const handleForgot = async () => {
    props.history.push("/forgot")
  }
  const usernameChange = (e) => {
    setUsername(e.target.value)
  }

  const passwordChanged = (e) => {
    setPassword(e.target.value)

  }

  const classes = useStyles();
  return (
    <Grid container style={{ minHeight: "40%" }} alignItems={"center"}
      direction={"column"}>

      <Grid item>
        <NavbarUhda open={false} setOpen={false} setSelected={null} logged={false} />
      </Grid>
      <Grid item style={{ marginTop: "15vh", marginBottom: "15vh" }}>
        <CardUhda bg={AppColors.WHITE} children={
          <Grid
            className="card"
            container
            item
            alignItems="center"
            direction="column"
            justifyContent="center"

          >
            <div style={{ margin: "5vh", display: "flex", flexDirection: "column", minWidth: 300 }}
            >

              <div style={{ textAlign: "center" }}>
                <TextUhda type={textType.TITLE_MEDIUM} text={CommonTexts.WELCOME_BACK_UPPER} />
              </div>

              <div style={{ textAlign: "center", height: 40 }} />

              <TextField
                onChange={(e) => usernameChange(e)}
                data-testId="username"
                type="email"
                label="Username"
                margin="normal"
                variant="outlined"
                className={classes.textFieldLabel}

              />

              <TextField
                onChange={(e) => passwordChanged(e)}
                data-testId="password"
                type="password"
                label="Password"
                margin="normal"
                variant="outlined"
                className={classes.textFieldLabel}
              />
              <TextUhda color={AppColors.SUBTEXT} type={textType.BODY} text={CommonTexts.INFO_LOGIN} />
              <Grid container spacing-xs-1 wrap="nowrap" spacing={4} direction="column"
                alignItems="center"
                justifyContent="center">
                <Grid container item alignItems="center" justifyContent="center">
                  <Checkbox
                    style={{
                      color: AppColors.PRIMARY,
                    }}
                    checked={checked}
                    onChange={handleChange}
                  />
                  <TextUhda type={textType.BODY} text={CommonTexts.REMEMBER_ME} margin={"0.7em"} />
                </Grid>
                <Grid data-testId="login" container item alignItems="center" justifyContent="center">
                  <ButtonFilledUhda text={CommonTexts.LOGIN} onClick={handleSubmit} margin={"0.7em"} />
                </Grid>
                <ButtonUnfilledUhda onClick={handleForgot}
                  children={<TextUhda type={textType.BODY}
                    text={CommonTexts.FORGOT_PASS} />}
                  className={classes.button} />
              </Grid>
            </div>

          </Grid>} />
      </Grid>

      <AppBar position="fixed" className={classes.appBar}
        style={{
          top: "auto",
          bottom: 0,
          color: AppColors.WHITE,
          background: AppColors.PRIMARY
        }}>
        <Box display="flex" justifyContent="center" m={3}>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href="https://docs.google.com/document/u/1/d/e/2PACX-1vQ3NIwP7OMOI-3DeN-KBumIStXruEyp85ACtno3Qv5xLUMMyvN4XC_p72aQCGK1p0lFRzQ00xNZk0DA/pub"
              target="_blank" rel="noreferrer">{CommonTexts.ABOUT}</a>

          </Box>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vQkQFg4O7erLNHlJTlXpthfriz1iWRWOQjWAgxGgux2grq1MNOw0zp1dGlZRUvUyy6C2t8-m4sgYtrW/pub'
              target="_blank" rel="noreferrer">{CommonTexts.USER_GUIDE}</a>
          </Box>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vS6H9drn7R70m0Ftgjow7aejc8QGNuObZfvVAFzFqphoftVxA5qlDZz4664WURVoVbTSH-H-93YBgN0/pub'
              target="_blank" rel="noreferrer">{CommonTexts.TERMS}</a>
          </Box>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vQ6Sh5WmhnQjMMo28czb6kJbb00JVZnrAR1yB6Lngdg8d_lz_H1R2VxLDM1fMBmWzCAbL6cJVvc4Puc/pub'
              target="_blank" rel="noreferrer">{CommonTexts.PRIVACY}</a>
          </Box>
        </Box>
      </AppBar>

    </Grid>
  );
}
