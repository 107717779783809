import { MenuItem } from "@mui/material"
import { Select, InputLabel } from "@mui/material"

function SingleChoiceDropdown({ label, value, handleValue, items, ...other }) {
  return <>
    <InputLabel id="single-choice-dropdown">{label}</InputLabel>
    <Select
      label={label}
      labelId="single-choice-select-label"
      id="single-choice-select"
      value={value}
      onChange={handleValue}
      {...other}
    >
      {items.map(item =>
        <MenuItem key={item.value} value={item.value} data-testId={item.testId} >
          {item.name}
        </MenuItem>
      )}
    </Select>
  </>;
}

export default SingleChoiceDropdown