import { Typography } from "@mui/material";
import ConditionPopover from "./ConditionPopover";
import { useDispatch } from "react-redux";
import { toast } from "reducers/notificationsReducer";
import { SingleConditionalQuestion } from "./ConditionalQuestion/SingleConditionalQuestion";

function ConditionalQuestions({
  hasConditional,
  questionConditionals,
  setQuestionConditionals,
  conditionalOperator,
  handleChangeConditionalOperator,
  possibleConditionalQuestions,
}) {
  const dispatch = useDispatch()

  /**
 * If the last item in the conditionalList array has a variable and answer, and the first item in the
 * conditionalList array has a variable and answer, then add a new item to the conditionalList array
 */
  const handleAddConditional = () => {
    const questionValuesAreFilled = questionConditionals.every(question => !!question.variable && !!question.answer)

    if (!questionValuesAreFilled) {
      dispatch(toast("Make sure you fill all the conditions before proceeding.", "error"))
      return
    }

    setQuestionConditionals([...questionConditionals, { variable: "", answer: "", type: "" }])
  }

  /**
* It deletes the conditional from the conditionalList array
* @param index - the index of the conditional that you want to delete
* @param e - the event
*/
  const handleDeleteConditional = (index, e) => {
    const removedConditionalQuestion = questionConditionals.filter((_, i) => i !== index);

    setQuestionConditionals(removedConditionalQuestion)
  }

  return (
    <>
      {hasConditional &&
        <>
          <ConditionPopover />
          {questionConditionals.map((conditionalQuestion, index) => (
            <SingleConditionalQuestion
              key={index}
              index={index}
              questionConditionals={questionConditionals}
              setQuestionConditionals={setQuestionConditionals}
              conditionalQuestion={conditionalQuestion}
              conditionalOperator={conditionalOperator}
              handleChangeConditionalOperator={handleChangeConditionalOperator}
              possibleConditionalQuestions={possibleConditionalQuestions}
              handleDeleteConditional={handleDeleteConditional}
            />
          ))}

          <Typography
            paragraph
            onClick={handleAddConditional}
            variant="subtitle_bold"
            sx={{ cursor: "pointer" }}
          >
            + Add condition
          </Typography>
        </>
      }
    </>
  )
}

export default ConditionalQuestions