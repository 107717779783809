import { Box, Button, FormControl, Typography } from "@mui/material"
import { CommonTexts } from "locale/en"
import { AppColors } from "resources/AppColors";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LabelsForm } from "locale/en";
import Icons from "resources/Icons";
import ListAltIcon from '@mui/icons-material/ListAlt';
import SingleChoiceDropdown from "components/Dropdowns/SingleChoiceDropdown";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { changeSelectedLocaleAction } from "reducers/Form/formActions";

const useStyles = makeStyles((theme) => ({
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.CBM_SAND_OPACITY,
    },
    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    },
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
        backgroundColor: AppColors.BACKGROUND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      backgroundColor: "transparent"

    },
    "&:before": {
      color: AppColors.WHITE,
    },
    "&:after": {
      borderBottomColor: AppColors.WHITE,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.WHITE,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },
}));

function Options({
  checkboxSelection,
  setCheckboxSelection,
  handleOpenQuestionModal,
  handleShowBulkDeleteQuestionsModal,
  summary,
  handleCreateSummary,
  handleGoSummary,
  handleIncludeInSummary,
  handleShowNewSectionModal,
  handleShowMoveQuestions,
  isSummaryPage,
  handleDeleteSummary,
  studyPermissions
}) {
  // ! We access index 0 because there is only one section in CBM. Fix it for studies
  const form = useSelector(({ form }) => form.data?.length > 0 && form.data[0])

  const [selectedLocale, setSelectedLocale] = useState(form?.defaultLocale.value)

  const dispatch = useDispatch()
  const classes = useStyles()

  function handleCheckboxSelection() {
    setCheckboxSelection(!checkboxSelection)
  }

  function handleChangeLocale(event) {
    const {
      target: { value },
    } = event;

    setSelectedLocale(value);

    const selectedLocale = form.locales.find(locale => locale.value === value).value

    dispatch(changeSelectedLocaleAction(selectedLocale))
  }

  // * Commented out studies functionality not present in CBM
  return (
    <Box p={1} py={2} maxWidth="1000px" display="flex" justifyContent="space-between">
      {checkboxSelection ?
        <>
          {/* {!isSummaryPage && studyPermissions.includes("question-create") &&
            <Button
              variant="contained"
              onClick={handleShowMoveQuestions}
              style={{
                backgroundColor: AppColors.PRIMARY,
                padding: "4px 8px",
                boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
                borderRadius: "5px",
                minWidth: "150px",
              }}
            >
              <Box display="flex" alignItems="center" >
                <Icons.MOVE size={"1.5em"} style={{ color: AppColors.WHITE, marginRight: "8px" }} />
                <Typography variant="subtitle_bold" color="white">
                  {CommonTexts.MOVE}
                </Typography>
              </Box>
            </Button>
          } */}
          {studyPermissions.includes("question-delete") &&
            <Button
              variant="contained"
              onClick={handleShowBulkDeleteQuestionsModal}
              sx={{
                backgroundColor: AppColors.RED,
                padding: "4px 8px",
                boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
                borderRadius: "5px",
                minWidth: "150px"
              }}
            >
              <Box display="flex" alignItems="center" >
                <Icons.DELETE size={"1.5em"} style={{ color: AppColors.WHITE, marginRight: "8px" }} />
                <Typography variant="subtitle_bold" color="white">
                  {CommonTexts.DELETE}
                </Typography>
              </Box>
            </Button>
          }
          {!isSummaryPage && summary && studyPermissions.includes("question-create") &&
            <Button
              variant="contained"
              onClick={handleIncludeInSummary}
              sx={{
                backgroundColor: AppColors.PRIMARY,
                padding: "4px 8px",
                boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
                borderRadius: "5px",
                minWidth: "150px",
              }}
            >
              <Box display="flex" alignItems="center" >
                <ListAltIcon style={{ color: "white", marginRight: "8px" }} />
                <Typography variant="subtitle_bold" color="white">
                  {CommonTexts.INCLUDE_SUMMARY}
                </Typography>
              </Box>
            </Button>
          }
        </>
        :
        <>
          {studyPermissions.includes("question-create") &&
            <Button
              variant="contained"
              onClick={() => handleOpenQuestionModal(true)}
              data-testId="newQuestion"
              sx={{
                backgroundColor: AppColors.PRIMARY,
                padding: "4px 8px",
                boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
                borderRadius: "5px",
                minWidth: "150px"
              }}
            >
              <Box display="flex" alignItems="center" >
                <AddCircleOutlineIcon style={{ color: "white", marginRight: "8px" }} />
                <Typography variant="subtitle_bold" color="white">
                  {CommonTexts.NEW_QUESTION}
                </Typography>
              </Box>
            </Button>
          }
          {/* {!isSummaryPage && studyPermissions.includes("question-create") &&
            <Button
              variant="contained"
              onClick={handleShowNewSectionModal}
              data-testId="newSection"
              sx={{
                backgroundColor: AppColors.PRIMARY,
                padding: "4px 8px",
                boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
                borderRadius: "5px",
                minWidth: "150px",
              }}
            >
              <Box display="flex" alignItems="center" >
                <AddCircleOutlineIcon style={{ color: "white", marginRight: "8px" }} />
                <Typography variant="subtitle_bold" color="white">
                  {CommonTexts.NEW_SECTION}
                </Typography>
              </Box>
            </Button>
          } */}
          {!summary ?
            <>
              {/* {studyPermissions.includes("question-create") && !isSummaryPage &&
                <Button
                  variant="contained"
                  onClick={handleCreateSummary}
                  data-testId="addSummary"
                  sx={{
                    backgroundColor: AppColors.PRIMARY,
                padding: "4px 8px",
                    boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
                    borderRadius: "5px",
                    minWidth: "150px",
                  }}
                >
                  <Typography variant="subtitle_bold" color="white">
                    {CommonTexts.ADD_SUMMARY}
                  </Typography>
                </Button>
              } */}
            </>
            :
            <>
              {studyPermissions.includes("question-create") && !isSummaryPage &&
                <Button
                  variant="contained"
                  onClick={handleGoSummary}
                  sx={{
                    backgroundColor: AppColors.PRIMARY,
                    padding: "4px 8px",
                    boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
                    borderRadius: "5px",
                    minWidth: "150px",
                  }}
                >
                  <Typography variant="subtitle_bold" color="white">
                    {CommonTexts.GO_SUMMARY}
                  </Typography>
                </Button>
              }
            </>
          }
        </>
      }
      <Button
        variant="contained"
        onClick={handleCheckboxSelection}
        sx={{
          backgroundColor: AppColors.SUBTEXT,
          padding: "4px 8px",
          boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
          borderRadius: "5px",
          minWidth: "150px"
        }}
      >
        <Typography variant="subtitle_bold" color="white">
          {checkboxSelection ? CommonTexts.CANCEL : CommonTexts.SELECT_QUESTIONS}
        </Typography>
      </Button>
      {isSummaryPage &&
        <Button
          variant="contained"
          onClick={handleDeleteSummary}
          data-testId='deleteSummary'
          sx={{
            backgroundColor: AppColors.RED,
            padding: "4px 8px",
            boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
            borderRadius: "5px",
            minWidth: "150px"
          }}
        >
          <Typography variant="subtitle_bold" color="white">
            {LabelsForm.DELETE_SUMMARY}
          </Typography>
        </Button>
      }
      <FormControl
        sx={{ width: "30%" }}
        className={classes.textFieldLabel}
      >
        <SingleChoiceDropdown
          label={"View content in:"}
          value={selectedLocale}
          handleValue={handleChangeLocale}
          items={form?.locales}
          className={classes.select}
        />
      </FormControl>
    </Box>
  )
}

export default Options