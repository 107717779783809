/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import GridFormsUhda from "../components/GridFormsUhda";
import { Grid } from "@mui/material";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH, FORMS_STUDY } from "../resources/ApiUrls";
import DrawerUhda from "../components/DrawerUhda";
import { useMatomo } from "@jonkoops/matomo-tracker-react"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { initializeLocales } from "reducers/localesReducer";

/**
 * @Page
 * Page to show all the forms of the study
 */
const FormsPage = ({ drawer, drawerStudy }) => {
  const [forms, setForms] = useState();
  const permissions = useRef();
  const id = useRef();
  const [study, setStudy] = useState();
  const { trackPageView } = useMatomo()

  const dispatch = useDispatch()

  const { data: localesData, loading: localesLoading, error: localesError } = useSelector(({ locales }) => locales)


  useEffect(() => {
    trackPageView()
  }, [])

  useEffect(() => {
    if (!localesData && !localesLoading) {
      dispatch(initializeLocales())
    }
  }, [])

  /**
   * It retrieves the data from the local storage and assigns it to the variables
   */
  const retriveData = () => {
    const newItem = JSON.parse(localStorage.getItem("dashBoardSection"));
    permissions.current = newItem.permissions;
    id.current = newItem.id;
  }

  useEffect(() => {
    retriveData();
  }, [])

  /**
   * It makes a GET request to the API and sets the response to the study state.
   */
  const getStudy = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${id.current}`);
      setStudy(response.data.data)
    } catch (err) {
      setStudy(err.message)
    }
  }

  /**
   * It gets the forms for a study and then it sets the forms state to the data that it gets back
   */
  const getForms = async () => {
    try {
      const response = await MyAxiosInstance.get(FORMS_STUDY(id.current));

      const mappedForms = response.data.data.items.map(form => {
        return {
          ...form,
          title: form.form_translations[0]?.title,
          tags: form.tags.reduce((tags, currentTag, i) => {
            if (i === 0 && currentTag.name) {
              tags = currentTag.name
              return tags
            }
            tags = `${tags} / ${currentTag.name}`
            return tags
          }, "")
        }
      });
      mappedForms.reverse()
      setForms(mappedForms)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getForms()
    getStudy()
  }, []);

  return (
    <>
      {study &&
        <DrawerUhda id={id.current} drawer={drawer} drawerStudy={drawerStudy} select={1} settings={study.permissions} />}
      <Grid container alignItems="center" style={{ paddingLeft: drawerStudy ? "190px" : "28px" }}
        direction={"column"}>
        {forms && <GridFormsUhda studies={study} forms={forms} id={id.current} permissions={permissions.current} drawer={drawer} />}
      </Grid>
    </>
  )
}

export default FormsPage;
