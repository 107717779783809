/* eslint-disable camelcase */
export const QUESTION_INFO = 0;
export const QUESTION_TEXT = 1;
export const QUESTION_RANGE = 2;
export const QUESTION_CALENDAR = 3;
export const QUESTION_SINGLE_CHOICE = 4;
export const QUESTION_NUMERIC = 5;
export const QUESTION_MULTIPLE_CHOICE = 6;
export const QUESTION_CALCULATION = 7;

export const questionTypes = {
  [QUESTION_INFO]: {
    id: QUESTION_INFO,
    label: "Info",
    name: "INFO",
    visible: true,
    selectObj: {value: QUESTION_INFO, label: "Info"}
  },
  [QUESTION_TEXT]: {
    id: QUESTION_TEXT,
    label: "Text",
    name: "FREE TEXT",
    visible: true,
    selectObj: {value: QUESTION_TEXT, label: "Text"}
  },
  [QUESTION_SINGLE_CHOICE]: {
    id: QUESTION_SINGLE_CHOICE,
    label: "Single choice",
    name: "SINGLE SELECT",
    visible: true,
    selectObj: {value: QUESTION_SINGLE_CHOICE, label: "Single Choice"}
  },
  [QUESTION_MULTIPLE_CHOICE]: {
    id: QUESTION_MULTIPLE_CHOICE,
    label: "Multiple choice",
    name: "MULTIPLE SELECT",
    visible: true,
    selectObj: {value: QUESTION_MULTIPLE_CHOICE, label: "Multiple choice"}
  },
  [QUESTION_NUMERIC]: {
    id: QUESTION_NUMERIC,
    label: "Numeric",
    name: "NUMERIC",
    visible: true,
    selectObj: {value: QUESTION_NUMERIC, label: "Numeric"}
  },
  [QUESTION_CALENDAR]: {
    id: QUESTION_CALENDAR,
    label: "Date",
    name: "DATE TIME",
    visible: true,
    selectObj: {value: QUESTION_CALENDAR, label: "Date"}
  },
  [QUESTION_RANGE]: {
    id: QUESTION_RANGE,
    label: "Scale",
    name: "RANGE",
    min_scale: [0, 1],
    max_scale: [2, 10],
    visible: true,
    selectObj: {value: QUESTION_RANGE, label: "Scale"}
  },
  [QUESTION_CALCULATION]: {
    id: QUESTION_CALCULATION,
    label: "Calculation",
    name: "CALCULATION",
    visible: true,
    selectObj: {value: QUESTION_CALCULATION, label: "Calculation"}
  }
};
export const questionTypesByName = {
  "INFO": {
    id: QUESTION_INFO,
    label: "Info",
    name: "INFO",
    visible: true,
    selectObj: {value: QUESTION_INFO, label: "Info"}
  },
  "FREE TEXT": {
    id: QUESTION_TEXT,
    label: "Text",
    name: "FREE TEXT",
    visible: true,
    selectObj: {value: QUESTION_TEXT, label: "Text"}
  },
  "SINGLE SELECT": {
    id: QUESTION_SINGLE_CHOICE,
    label: "Single choice",
    name: "SINGLE SELECT",
    visible: true,
    selectObj: {value: QUESTION_SINGLE_CHOICE, label: "Single Choice"}
  },
  "MULTIPLE SELECT": {
    id: QUESTION_MULTIPLE_CHOICE,
    label: "Multiple choice",
    name: "MULTIPLE SELECT",
    visible: true,
    selectObj: {value: QUESTION_MULTIPLE_CHOICE, label: "Multiple choice"}
  },
  "NUMERIC": {
    id: QUESTION_NUMERIC,
    label: "Numeric",
    name: "NUMERIC",
    visible: true,
    selectObj: {value: QUESTION_NUMERIC, label: "Numeric"}
  },
  "DATE TIME": {
    id: QUESTION_CALENDAR,
    label: "Date",
    name: "DATE TIME",
    visible: true,
    selectObj: {value: QUESTION_CALENDAR, label: "Date"}
  },
  "RANGE": {
    id: QUESTION_RANGE,
    label: "Scale",
    name: "RANGE",
    min_scale: [0, 1],
    max_scale: [2, 10],
    visible: true,
    selectObj: {value: QUESTION_RANGE, label: "Scale"}
  },
  "CALCULATION": {
    id: QUESTION_CALCULATION,
    label: "Calculation",
    name: "CALCULATION",
    visible: true,
    selectObj: {value: QUESTION_CALCULATION, label: "Calculation"}
  }
};

export const questionTypesConditionals = {
  
  "SINGLE SELECT": {
    id: QUESTION_SINGLE_CHOICE,
    label: "Single choice",
    name: "SINGLE SELECT",
    visible: true,
    selectObj: { value: QUESTION_SINGLE_CHOICE, label: "Single Choice" }
  },
  "MULTIPLE SELECT": {
    id: QUESTION_MULTIPLE_CHOICE,
    label: "Multiple choice",
    name: "MULTIPLE SELECT",
    visible: true,
    selectObj: { value: QUESTION_MULTIPLE_CHOICE, label: "Multiple choice" }
  },
  "RANGE": {
    id: QUESTION_RANGE,
    label: "Scale",
    name: "RANGE",
    min_scale: [0, 1],
    max_scale: [2, 10],
    visible: true,
    selectObj: { value: QUESTION_RANGE, label: "Scale" }
  },
};
