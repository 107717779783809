import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, IconButton, Typography } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { AppColors } from 'resources/AppColors';

function DuplicateMenuOption({ questionId, showText, handleShowDuplicateModal, setQuestionToDuplicateId }) {
  // const navigate = useNavigate()

  function handleDuplicateContent() {
    // navigate(`${entity.id}/duplicate`, { state: { entity } })
    handleShowDuplicateModal()
    setQuestionToDuplicateId(questionId)
  }

  return (
    <Box onClick={handleDuplicateContent} sx={{ width: "100%" }} data-testId='duplicateOption' >
      <IconButton size="large">
        <ContentCopyIcon sx={{ fill: AppColors.PRIMARY }} />
        {showText &&
          <Typography variant="menu_list_text" >
            {showText}
          </Typography>
        }
      </IconButton>
    </Box>
  );
}

export default DuplicateMenuOption