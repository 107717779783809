import { Box, Typography } from "@mui/material"
import TableMonitoring from "components/TableMonitoring/TableMonitoring"
import NoDataTableBody from "components/TableMonitoring/Body/NoDataTableBody"
import useSelection from "hooks/useSelection"
import TableHeaderStatic from "components/TableMonitoring/Header/Static/TableHeaderStatic"
import TableRowStatic from "components/TableMonitoring/Body/TableRowStatic"
import QuestionsTableRows from "./Rows/QuestionsTableRows"
import RowMenuListActions from "components/TableMonitoring/Body/RowMenuListActions/RowMenuListActions"
import EditMenuOption from "components/TableMonitoring/Body/RowMenuListActions/EditMenuOption"
import DeleteMenuOption from "components/TableMonitoring/Body/RowMenuListActions/DeleteMenuOption"
import DuplicateMenuOption from "components/TableMonitoring/Body/RowMenuListActions/DuplicateMenuOption"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch } from "react-redux"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { DeleteQuestionModal } from "components/Modals/QuestionModals/DeleteQuestionModal"
import { DuplicateQuestionModal } from "components/Modals/QuestionModals/DuplicateQuestionModal"
import { SearchBar } from "components/SearchBar/SearchBar"
import { changeQuestionsOrderAction, deleteQuestionAction, duplicateQuestionAction } from "reducers/Form/formActions"
import TranslateMenuOption from "components/TableMonitoring/Body/RowMenuListActions/TranslateMenuOption"

const tableHeaders = ["Order", "Variable name", "Question Type", "Question Title", "Mandatory", "Conditional", "Original Locale", "Translations"]

const QuestionsTable = forwardRef(({
  questions,
  sectionId,
  selectedSectionIndex,
  isCheckboxSelection,
  handleOpenQuestionModal,
  handleShowTranslateQuestionModal,
  handleSelectQuestion,
  isSummaryPage,
  formId,
  studyPermissions
}, refs) => {
  const [filteredQuestions, setFilteredQuestions] = useState(null)
  const [selectedQuestionId, setSelectedQuestionId] = useState(null)
  const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false)
  const [showDuplicateQuestionModal, setShowDuplicateQuestionModal] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setFilteredQuestions(questions)
  }, [questions])

  const {
    selectedItems,
    setSelectedItems,
    handleSelectAllClick,
    handleSelectOneClick,
    isSelected
  } = useSelection(filteredQuestions)

  useImperativeHandle(refs, () => {
    return {
      selectedItems,
      setSelectedItems
    }
  })

  function onDragEnd(result) {
    dispatch(changeQuestionsOrderAction(formId, questions, result, isSummaryPage))
  };

  function handleShowDeleteModal() {
    setShowDeleteQuestionModal(!showDeleteQuestionModal)
  }

  function handleDeleteQuestion() {
    dispatch(deleteQuestionAction(sectionId, selectedQuestionId, isSummaryPage))
    setShowDeleteQuestionModal(false)
    setSelectedQuestionId(null)
  }

  function handleShowDuplicateModal() {
    setShowDuplicateQuestionModal(!showDuplicateQuestionModal)
  }

  function handleDuplicateQuestion() {
    dispatch(duplicateQuestionAction(selectedQuestionId, selectedSectionIndex))
    setSelectedQuestionId(null)
    setShowDuplicateQuestionModal(false)
  }

  return (
    <>
      <Box sx={{ width: '100%', my: 2 }}>
        <SearchBar
          entities={questions}
          setFilteredValues={setFilteredQuestions}
          placeholder={"Search by variable name"}
          searchKey={"variableName"}
        />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="questions">
            {(provided) => (
              <TableMonitoring
                droppable={provided}
                tableHeader={
                  <TableHeaderStatic
                    tableHeaders={tableHeaders}
                    entity={questions}
                    numSelected={selectedItems.length}
                    rowCount={filteredQuestions?.length}
                    onSelectAllClick={isCheckboxSelection && handleSelectAllClick}
                    isSummaryPage={isSummaryPage}
                  />
                }
              >
                {filteredQuestions?.length > 0 && filteredQuestions?.map((question, index) => (
                  <Draggable
                    key={question.id}
                    draggableId={question.id.toString()}
                    index={index}
                    isDragDisabled={isCheckboxSelection}
                  >
                    {(provided) => (
                      <TableRowStatic
                        key={question.id}
                        entity={question}
                        droppable={provided}
                        loaded={true}
                        isItemSelected={isSelected(question.id)}
                        onSelectOneClick={isCheckboxSelection && handleSelectOneClick}
                        actions={
                          <RowMenuListActions>
                            {studyPermissions.includes("question-edit") &&
                              <EditMenuOption
                                showText
                                question={question}
                                handleOpenQuestionModal={handleOpenQuestionModal}
                                handleSelectQuestion={handleSelectQuestion}
                              />
                            }
                            <TranslateMenuOption
                              showText
                              question={question}
                              handleShowTranslateModal={handleShowTranslateQuestionModal}
                              handleSelectQuestion={handleSelectQuestion}
                            />
                            {!isSummaryPage && studyPermissions.includes("question-duplicate") &&
                              <DuplicateMenuOption
                                showText={"Duplicate"}
                                questionId={question.id}
                                handleShowDuplicateModal={handleShowDuplicateModal}
                                setQuestionToDuplicateId={setSelectedQuestionId}
                              />
                            }
                            {studyPermissions.includes("question-delete") &&
                              <DeleteMenuOption
                                showText
                                questionId={question.id}
                                handleModalOpen={handleShowDeleteModal}
                                setQuestionToDeleteId={setSelectedQuestionId}
                              />
                            }
                          </RowMenuListActions>
                        }
                      >
                        <QuestionsTableRows
                          question={question}
                          selectedSectionIndex={selectedSectionIndex}
                          isSummaryPage={isSummaryPage}
                        />
                      </TableRowStatic>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableMonitoring>
            )}
          </Droppable>
        </DragDropContext>
        {filteredQuestions?.length === 0 &&
          <NoDataTableBody
            selectedEntity={filteredQuestions}
            noDataMessage={"No questions found for this form"}
          />
        }
      </Box>
      <Box sx={{ textAlign: "right" }} mt={1}>
        <Typography variant="body_bold" >Total: {questions.length} questions</Typography>
      </Box>
      {showDeleteQuestionModal &&
        <DeleteQuestionModal
          show={showDeleteQuestionModal}
          handleShow={handleShowDeleteModal}
          handleDeleteQuestion={handleDeleteQuestion}
          isSummaryPage={isSummaryPage}
        />
      }
      {showDuplicateQuestionModal &&
        <DuplicateQuestionModal
          show={showDuplicateQuestionModal}
          setShow={handleShowDuplicateModal}
          handleDuplicateQuestion={handleDuplicateQuestion}
        />
      }
    </>
  )
})

export default QuestionsTable