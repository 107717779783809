import "./styles.scss";
import Editor from "./Editor";

export default function RichTextEditorLexical({
  jsonTextEditor,
  setJsonTextEditor,
  rawHtmlTextEditor,
  setRawHtmlTextEditor,
  setPlainTextEditor,
  isEditable,
  resetEditorState,
  setResetEditorState
}) {
  return (
    <div className="App">
      <Editor
        jsonTextEditor={jsonTextEditor}
        setJsonTextEditor={setJsonTextEditor}
        rawHtmlTextEditor={rawHtmlTextEditor}
        setRawHtmlTextEditor={setRawHtmlTextEditor}
        setPlainTextEditor={setPlainTextEditor}
        isEditable={isEditable}
        resetEditorState={resetEditorState}
        setResetEditorState={setResetEditorState}
      />
    </div>
  );
}
