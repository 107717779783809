import {
  CHANGE_PASS_REQ,
  IS_AUTH,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGIN_SUCCESS_REMEMBER,
  LOGOUT,
  REMOVE_FIRST_TIME,
  RESET1_SUCCESS,
  RESET2_SUCCESS,
} from "../../types";
import {StorageManager} from "../../utils/index";

const authReducer = (state, action) => {
  const storageManager = new StorageManager();
  switch (action.type) {
  case IS_AUTH:
    return {
      ...state,
      authenticated: true,
      message: null,
    };
  case LOGIN_SUCCESS:
    sessionStorage.setItem("user", JSON.stringify(action))
    storageManager.storeTokenOnSession(action.payload.data.access_token_user);
    storageManager.storeName(action.payload.data.name);
    storageManager.storeSurname(action.payload.data.surname);
    storageManager.storeEmail(action.payload.data.email)
    storageManager.storeUsername(action.payload.data.username)
    storageManager.storeId(action.payload.data.id)
    return {
      ...state,
      authenticated: true,
      message: null,
    };
  case LOGIN_SUCCESS_REMEMBER:
    localStorage.setItem("user", JSON.stringify(action))
    storageManager.storeToken(action.payload.data.access_token_user);
    storageManager.storeName(action.payload.data.name);
    storageManager.storeSurname(action.payload.data.surname);
    storageManager.storeEmail(action.payload.data.email)
    storageManager.storeUsername(action.payload.data.username)
    storageManager.storeId(action.payload.data.id)

    return {
      ...state,
      authenticated: true,
      message: null,
    };
  case CHANGE_PASS_REQ:
    storageManager.storeTokenTemporal(action.payload.data.access_token_user);
    return {
      ...state,
      //authenticated: true,
      // eslint-disable-next-line camelcase
      change_req: true,
      message: null,
    };
  case RESET1_SUCCESS:
    return {
      ...state,
    };
  case RESET2_SUCCESS:
    return {
      ...state,
    };

  case REMOVE_FIRST_TIME:
    return {
      ...state,
      // eslint-disable-next-line camelcase
      change_req: false,
    };
  case LOGOUT:
    localStorage.removeItem("user")
    return {
      ...state,
      token: null,
      authenticated: null,
      message: action.payload,
    };

  case LOGIN_ERROR:
    storageManager.removeToken();
    return {
      ...state,
      token: null,
      authenticated: null,
      message: action.payload,
    };
  default:
    return state;
  }
};

export default authReducer;
