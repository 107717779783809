import MyAxiosInstance from "../utils/MyAxiosInstance";
import {
  DELETE_EDIT_STUDY_PARTICIPANT_URL,
  PARTICIPANTS_URL,
  STUDY_PARTICIPANT_URL,
  STUDY_PARTICIPANTS_URL,
} from "../resources/ApiUrls";

const getParticipants = () => MyAxiosInstance.get(PARTICIPANTS_URL);

const getStudyParticipants = (id) =>
  MyAxiosInstance.get(STUDY_PARTICIPANTS_URL(id));
const postStudyParticipant = (id, body) =>
  MyAxiosInstance.post(STUDY_PARTICIPANT_URL(id), body);
const deleteStudy = (id, studyId) =>
  MyAxiosInstance.delete(DELETE_EDIT_STUDY_PARTICIPANT_URL(id, studyId));

const exports = {
  getParticipants,
  getStudyParticipants,
  postStudyParticipant,
  deleteStudy,
};
export default exports;
