import { AppColors } from "resources/AppColors";
import { FormControl, InputAdornment } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import {
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_SINGLE_CHOICE,
  questionTypes,
} from "resources/QuestionConstants";
// import FileInput from "components/FileInput/FileInput";
import QuestionsPresentation from "components/NewQuestionsManagement/QuestionsPresentation";
import { Box, TextField } from "@mui/material";
import { validateVarName } from "utils/HelperFunctions";
import { useState } from "react";
import SelectQuestionTypes from "components/NewQuestions/SelectQuestionTypes";
import SwitchesLeftSection from "components/NewQuestions/SwitchesLeftSection";
import SingleChoiceDropdown from "components/Dropdowns/SingleChoiceDropdown";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
        backgroundColor: AppColors.BACKGROUND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },
}));

function LeftSection({
  formTitle,
  sectionId,
  handleChangeSectionId,
  sections,
  variableName,
  setVariableName,
  questionType,
  setQuestionType,
  selectedPresentationType,
  setSelectedPresentationType,
  changeMandatory,
  formWithSummary,
  image,
  handleImage,
  questions,
  switches,
  setSwitches,
  questionToEdit,
  isSummaryPage
}) {
  const [invalidVariableName, setInvalidVariableName] = useState(false)
  const [existingVariableName, setExistingVariableName] = useState(false)

  const classes = useStyles()

  const handleChangeVariableName = (e) => {
    const { value } = e.target;

    const variableNameExists = sections.some(section => section.questions.some(question => questionToEdit ?
      question.id !== questionToEdit.id && question.variableName === value :
      question.variableName === value))

    setInvalidVariableName(false)
    setExistingVariableName(false)

    if (!validateVarName(value)) {
      setInvalidVariableName(true)
      return
    }

    if (variableNameExists) {
      setExistingVariableName(true)
    }

    setVariableName(value);
  };

  return (
    <Box
      id="left-section"
      sx={{ maxWidth: "40%" }}
      mr={2}
    >
      <TextField
        name="form"
        type="text"
        required
        disabled={true}
        label={"Survey"}
        value={formTitle}
        variant='outlined'
        margin="normal"
        sx={{ width: "100%" }}
        className={classes.textField}
      />
      <TextField
        name="order"
        type="text"
        required
        disabled={true}
        label={"Order"}
        value={questions.length + 1}
        variant='outlined'
        margin="normal"
        sx={{ width: "100%" }}
        className={classes.textField}
      />
      {/* <FormControl
        margin="normal"
        fullWidth
      >
        <SingleChoiceDropdown
          label={"Section"}
          value={sectionId}
          handleValue={handleChangeSectionId}
          items={sections.map((section) => {
            return {
              name: section.title,
              value: section.id
            }
          })}
        />
      </FormControl> */}
      <TextField
        name="variableName"
        data-testId="variableName"
        type="text"
        required
        label={"Variable Name"}
        value={variableName}
        onChange={handleChangeVariableName}
        variant='outlined'
        margin="normal"
        sx={{ width: "100%" }}
        className={classes.textField}
        error={existingVariableName || invalidVariableName}
        helperText={
          (existingVariableName && "This variable name is already being used in this form. Please, choose a different one") ||
          (invalidVariableName && "Variable name only supports alphanumeric characters")}
        inputProps={{
          endAdornment: (existingVariableName || invalidVariableName) && (
            <InputAdornment position="end">
              <ErrorIcon style={{ color: AppColors.RED }} />
            </InputAdornment>
          )
        }}
      />
      {/* <FileInput
        label="Image"
        onChange={handleImage}
        existingFile={image}
        acceptedFileTypes={"image/*"}
      /> */}
      <SelectQuestionTypes
        questionType={questionType}
        setQuestionType={setQuestionType}
      />
      {/* {(questionType === questionTypes[QUESTION_SINGLE_CHOICE].name ||
        questionType === questionTypes[QUESTION_MULTIPLE_CHOICE].name)
        && (
          <QuestionsPresentation
            selectedQuestionType={questionType}
            selectedPresentationType={selectedPresentationType}
            setSelectedPresentationType={setSelectedPresentationType}
          />
        )} */}
      <SwitchesLeftSection
        switches={switches}
        setSwitches={setSwitches}
        questionType={questionType}
        changeMandatory={changeMandatory}
        questionsLength={questions.length}
        formWithSummary={formWithSummary}
        isSummaryPage={isSummaryPage}
      />
    </Box>
  );
}

export default LeftSection