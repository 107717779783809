import React, { useContext, useEffect, useState } from "react";
import { AppBar, Box, Grid, TextField } from "@mui/material";
import { AppColors } from "../resources/AppColors";
import AuthContext from "../context/authentication/authContext";
import makeStyles from '@mui/styles/makeStyles';
import NavbarUhda from "../components/NavbarUhda";
import CardUhda from "../components/CardUhda";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import { CommonTexts, SnackBarResponses } from "../locale/en";
import IconProviderUhda from "../components/IconProviderUhda";
import Icons from "../resources/Icons";
import TextUhda from "../components/TextUdha";
import { textType } from "../resources/AppTexts";
import { useParams } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react"
import { useDispatch } from "react-redux";
import { toast } from "../reducers/notificationsReducer";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: AppColors.BACKGROUND,
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        marginTop: "4px",
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    }, "& label.Mui-focused": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
  },

  textFieldLabelWithText: {

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        marginTop: "4px",
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE

    }, "& label.Mui-focused": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE,
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,

  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

/**
 * @Page
 * Page to change the password of the user when is not logged
 */
export default function ForgotPassSecondPage(props) {
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const authContext = useContext(AuthContext);
  const { message, authenticated, login, checkAuth } = authContext;

  const { id } = useParams();

  const [isTypingOldP, setIsTypingOldP] = useState(false)
  const [isTypingNewP, setIsTypingNewP] = useState(false)
  const [isTypingRepeatP, setIsTypingRepeatP] = useState(false)
  const { trackPageView } = useMatomo()

  const dispatch = useDispatch()

  useEffect(() => {
    trackPageView()
  }, [])

  useEffect(() => {
    checkAuth();
    if (authenticated) {
      props.history.push("/home");
    }
    if (message) {
      dispatch(toast(message, "error"))
    }
  }, [message, authenticated, props.history]);

  //Function called when the user submit the change password
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (oldPassword === "" || password === "" || rePassword === "") {
      dispatch(toast(SnackBarResponses.EMPTY_FIELDS, "error"))
    } else if (password !== rePassword) {
      dispatch(toast(SnackBarResponses.PASWORD_NOT_MATCH, "error"))
    } else {
      const params = id.split("&email=");
      // eslint-disable-next-line no-unused-vars
      const body = {
        email: params[1],
        token: params[0],
        password: password,
        // eslint-disable-next-line camelcase
        password_confirmation: rePassword,
      };
      login(params);
    }
  };

  const classes = useStyles();

  const oldPasswordChange = (e) => {
    setOldPassword(e.target.value)
    setIsTypingOldP(true)
  }

  const newPasswordChanged = (e) => {
    setPassword(e.target.value)
    setIsTypingNewP(true)

  }

  const repeatPasswordChanged = (e) => {
    setRePassword(e.target.value)
    setIsTypingRepeatP(true)

  }

  return (
    <Grid
      className={classes.background}
      container
      style={{ minHeight: "100vh" }}
      alignItems={"center"}
      direction={"column"}
    >
      <Grid item>
        <NavbarUhda
          open={false}
          setOpen={false}
          setSelected={null}
          logged={false}
        />
      </Grid>
      <Grid item style={{ marginTop: "15vh", marginBottom: "15vh" }}>
        <CardUhda
          bg={AppColors.WHITE}
          children={
            <Grid
              container
              item
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <div
                style={{
                  margin: "5vh",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 300,
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <IconProviderUhda
                    icon={<Icons.VERIFIED style={{ fontSize: 50 }} />}
                  />
                  <TextUhda
                    type={textType.TITLE}
                    text={CommonTexts.WELCOME.toUpperCase() + "!"}
                  />
                  <TextUhda
                    type={textType.SUBTITLE}
                    text={CommonTexts.PLEASE_CHANGE_PASS}
                  />
                </div>

                <TextField
                  onChange={(e) => oldPasswordChange(e)}
                  type="password"
                  label="Old Password"
                  margin="normal"
                  variant="outlined"
                  className={isTypingOldP ? classes.textFieldLabelWithText : classes.textFieldLabel}

                />
                <TextField
                  onChange={(e) => newPasswordChanged(e)}
                  type="password"
                  label="New Password"
                  margin="normal"
                  variant="outlined"
                  className={isTypingNewP ? classes.textFieldLabelWithText : classes.textFieldLabel}
                />
                <TextField
                  onChange={(e) => repeatPasswordChanged(e)}
                  type="password"
                  label="Repeat your new password"
                  margin="normal"
                  variant="outlined"
                  className={isTypingRepeatP ? classes.textFieldLabelWithText : classes.textFieldLabel}
                />
                <Grid
                  container
                  style={{ paddingTop: "20px" }}
                  spacing-xs-1
                  wrap="nowrap"
                  spacing={3}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid container item alignItems="center" justifyContent="center">
                    <ButtonFilledUhda
                      text={CommonTexts.CHANGE_PASS}
                      onClick={handleSubmit}
                      margin={"0.7em"}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          }
        />
      </Grid>
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{
          top: "auto",
          bottom: 0,
          color: AppColors.WHITE,
          background: AppColors.PRIMARY,
        }}
      >
        <Box display="flex" justifyContent="center" m={3}>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href="https://docs.google.com/document/u/1/d/e/2PACX-1vQ3NIwP7OMOI-3DeN-KBumIStXruEyp85ACtno3Qv5xLUMMyvN4XC_p72aQCGK1p0lFRzQ00xNZk0DA/pub"
              target="_blank" rel="noreferrer">{CommonTexts.ABOUT}</a>

          </Box>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vQkQFg4O7erLNHlJTlXpthfriz1iWRWOQjWAgxGgux2grq1MNOw0zp1dGlZRUvUyy6C2t8-m4sgYtrW/pub'
              target="_blank" rel="noreferrer">{CommonTexts.USER_GUIDE}</a>
          </Box>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vS6H9drn7R70m0Ftgjow7aejc8QGNuObZfvVAFzFqphoftVxA5qlDZz4664WURVoVbTSH-H-93YBgN0/pub'
              target="_blank" rel="noreferrer">{CommonTexts.TERMS}</a>
          </Box>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vQ6Sh5WmhnQjMMo28czb6kJbb00JVZnrAR1yB6Lngdg8d_lz_H1R2VxLDM1fMBmWzCAbL6cJVvc4Puc/pub'
              target="_blank" rel="noreferrer">{CommonTexts.PRIVACY}</a>
          </Box>
        </Box>
      </AppBar>
    </Grid>
  );
}
