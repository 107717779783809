// eslint-disable-next-line no-unused-vars
const { Component } = require("react");

export default class StudyModal {

  constructor(title, description, privado, tags, id) {
    this.title = title;
    this.description = description;
    this.privado = privado;
    this.tags = tags;
    this.id = id;
  }

  get modifyToJSON() {
    return {
      "id": this.id,
      "status": "RECRUITING",
      "studyType": "OBSERVATIONAL",
      "published": true,
      "collectionTool": true,
      "principalResearcher": "",
      "secondaryResearcher": "",
      "studyProvider": "",
      "populationLabel": [
        "20-30 years old",
        "30-40 years old"
      ],
      "defaultLocale": "en",
      "private": this.privado,
      "openData": false,
      "studyImg": "binary file (image)",
      "webPage": "https//:www.example.com",
      "results": "binary file (image)",
      "video": "https//:www.youtube.com",
      "protocol": "",
      "paper": "binary file (pdf)",
      "openDataFiles": "json/csv (String)",
      "socialMediaTwitter": "",
      "socialMediaLinkedin": "",
      "socialMediaInstagram": "",
      "tags": this.tags,
      "studyTranslation": [
        {
          "locale": "en",
          "studyTitle": this.title,
          "studyPopulation": "Lorem ipsum...",
          "design": "Lorem ipsum...",
          "methods": "Lorem ipsum...",
          "shortDescription": this.description,
          "keyWords": "Lorem ipsum...",
          "principalObjective": "Lorem ipsum...",
          "secondaryObjective": "Lorem ipsum...",
          "scientificContext": "Lorem ipsum...",
          "addedValue": "Lorem ipsum...",
          "dataCollected": "weight,height,blood pressure",
          "dataJustification": "Lorem ipsum..."
        }
      ]
    };
  }

  get createToJSON() {
    return {
      "status": "RECRUITING",
      "studyType": "OBSERVATIONAL",
      "published": true,
      "collectionTool": true,
      "principalResearcher": "",
      "secondaryResearcher": "",
      "studyProvider": "",
      "populationLabel": [
        "20-30 years old",
        "30-40 years old"
      ],
      "defaultLocale": "en",
      "private": this.privado,
      "openData": false,
      "studyImg": "binary file (image)",
      "webPage": "https//:www.example.com",
      "results": "binary file (image)",
      "video": "https//:www.youtube.com",
      "protocol": "",
      "paper": "binary file (pdf)",
      "openDataFiles": "json/csv (String)",
      "socialMediaTwitter": "",
      "socialMediaLinkedin": "",
      "socialMediaInstagram": "",
      "tags": this.tags,
      "studyTranslation": [
        {
          "locale": "en",
          "studyTitle": this.title,
          "studyPopulation": "Lorem ipsum...",
          "design": "Lorem ipsum...",
          "methods": "Lorem ipsum...",
          "shortDescription": this.description,
          "keyWords": "Lorem ipsum...",
          "principalObjective": "Lorem ipsum...",
          "secondaryObjective": "Lorem ipsum...",
          "scientificContext": "Lorem ipsum...",
          "addedValue": "Lorem ipsum...",
          "dataCollected": "weight,height,blood pressure",
          "dataJustification": "Lorem ipsum..."
        }
      ]
    };
  }

}
