import React, { useContext, useEffect, useState } from "react";
import { AppBar, Box, Grid, TextField } from "@mui/material";
import { AppColors } from "../resources/AppColors";
import AuthContext from "../context/authentication/authContext";
import makeStyles from '@mui/styles/makeStyles';
import { CommonTexts, ErrorTexts, SnackBarResponses } from "../locale/en";
import { Alert } from '@mui/material';
import CardUhda from "../components/CardUhda";
import NavbarUhda from "../components/NavbarUhda";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import ButtonUnfilledUhda from "../components/ButtonUnfilledUhda";
import TextUhda from "../components/TextUdha";
import { textType } from "../resources/AppTexts";
import { useMatomo } from "@jonkoops/matomo-tracker-react"
import { useDispatch } from "react-redux";
import { toast } from "../reducers/notificationsReducer";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: AppColors.BACKGROUND,
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },

    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

/**
 * @Page
 * Page for when the user have forgot the password and want to change it
 */
export default function ForgotPass(props) {
  const [email, setEmail] = useState("");

  const authContext = useContext(AuthContext);
  const { message, authenticated, reset, checkAuth } = authContext;

  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { trackPageView } = useMatomo()

  const dispatch = useDispatch()

  useEffect(() => {
    trackPageView()
  }, [])
  useEffect(() => {
    checkAuth();
    if (authenticated) {
      props.history.push("/home");
    }
    if (message) {
      setAlertMessage(message);
      setShowValidationAlert(true);
    }
  }, [message, authenticated, props.history]);

  //Function to reset the password
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") setShowValidationAlert(true);
    else {
      const params = {
        email: email,
      };

      setShowValidationAlert(false);
      reset(params);
      dispatch(toast(SnackBarResponses.EMAIL_SEND, "info"))
    }
  };

  //Function that will be called when the user wants to go to the login Page
  const handleLogin = async () => {
    props.history.push("/login");
  };

  const classes = useStyles();
  return (
    <Grid
      className={classes.background}
      container
      style={{ minHeight: "100vh" }}
      alignItems={"center"}
      direction={"column"}
    >
      <Grid item>
        <NavbarUhda
          open={false}
          setOpen={false}
          setSelected={null}
          logged={false}
        />
      </Grid>

      <Grid item style={{ marginTop: "25vh", marginBottom: "15vh" }}>
        <CardUhda
          height={"338px"}
          width={"582px"}
          //style={{marginLeft: "5px"}}
          bg={AppColors.WHITE}
          children={
            <Grid
              container
              item
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <div
                style={{
                  margin: "5vh",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 300,

                }}
              >
                <div style={{ textAlign: "center" }}>
                  <TextUhda
                    type={textType.TITLE}
                    text={CommonTexts.FORGOT_PASS.toUpperCase()}
                  />
                </div>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  label="E-mail"
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldLabel}
                />

                <Grid
                  container
                  style={{ paddingTop: "10px" }}
                  spacing-xs-1
                  wrap="nowrap"
                  spacing={3}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid container item alignItems="center" justifyContent="center">
                    <ButtonFilledUhda
                      margin={"0.7em"}
                      text={CommonTexts.RESET_PASS}
                      onClick={handleSubmit}
                    />
                  </Grid>
                  <Grid container item alignItems="center" justifyContent="center">
                    <ButtonUnfilledUhda
                      onClick={handleLogin}
                      children={
                        <TextUhda
                          color={AppColors.PRIMARY}
                          type={textType.BODY}
                          text={CommonTexts.LOGIN}
                        />
                      }
                    />
                    {showValidationAlert &&
                      (alertMessage ? (
                        <Alert severity="error">{alertMessage.message}</Alert>
                      ) : (
                        <Alert severity="error">{ErrorTexts.LOGIN}</Alert>
                      ))}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          }
        />
      </Grid>
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{
          top: "auto",
          bottom: 0,
          color: AppColors.WHITE,
          background: AppColors.PRIMARY,
        }}
      >
        <Box display="flex" justifyContent="center" m={3}>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href="https://docs.google.com/document/u/1/d/e/2PACX-1vQ3NIwP7OMOI-3DeN-KBumIStXruEyp85ACtno3Qv5xLUMMyvN4XC_p72aQCGK1p0lFRzQ00xNZk0DA/pub"
              target="_blank" rel="noreferrer">{CommonTexts.ABOUT}</a>

          </Box>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vQkQFg4O7erLNHlJTlXpthfriz1iWRWOQjWAgxGgux2grq1MNOw0zp1dGlZRUvUyy6C2t8-m4sgYtrW/pub'
              target="_blank" rel="noreferrer">{CommonTexts.USER_GUIDE}</a>
          </Box>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vS6H9drn7R70m0Ftgjow7aejc8QGNuObZfvVAFzFqphoftVxA5qlDZz4664WURVoVbTSH-H-93YBgN0/pub'
              target="_blank" rel="noreferrer">{CommonTexts.TERMS}</a>
          </Box>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vQ6Sh5WmhnQjMMo28czb6kJbb00JVZnrAR1yB6Lngdg8d_lz_H1R2VxLDM1fMBmWzCAbL6cJVvc4Puc/pub'
              target="_blank" rel="noreferrer">{CommonTexts.PRIVACY}</a>
          </Box>
        </Box>
      </AppBar>
    </Grid>
  );
}
