import { Box, Card, Divider, Typography, TextField, FormControl, InputAdornment, IconButton, Button } from "@mui/material";
import { CommonTexts, LabelsForm } from "locale/en";
import { useEffect, useState } from "react";
import { AppColors } from "resources/AppColors";
import SingleChoiceDropdown from "components/Dropdowns/SingleChoiceDropdown";
import { useDispatch } from "react-redux";
import { toast } from "reducers/notificationsReducer";
import copyIcon from "assets/img/copy_icon.svg";
import FormModal from "modal/FormModal";
import FormModalStatus from "modal/FormModalStatus";
import FormService from "services/FormsService";
import TagsService from "services/TagsService";
import Icons from "resources/Icons";
import { makeStyles } from "@mui/styles";
import { useLocation, useHistory } from "react-router-dom";
import DrawerUhda from "components/DrawerUhda/DrawerUhda";
import { orderArrayAlphabetical } from "utils/HelperFunctions";
import MultipleChoiceCheckboxesDropdown from "components/Dropdowns/MultipleChoiceCheckboxesDropdown";
import MyAxiosInstance from "utils/MyAxiosInstance";
import { BASE_PATH } from "resources/ApiUrls";
import { createFormAction, updateFormAction } from "reducers/Form/formActions";
import { useSelector } from "react-redux";
import { initializeLocales } from "reducers/localesReducer";

const useStyles = makeStyles((theme) => ({
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.CBM_SAND_OPACITY,
    },
    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    },
  },
  textFieldLabelDisabled: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.SUBTEXT,
      backgroundColor: AppColors.PIRMARY_WITH_OPACITY,
    },
    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    },
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
        backgroundColor: AppColors.BACKGROUND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      backgroundColor: "transparent"

    },
    "&:before": {
      color: AppColors.WHITE,
    },
    "&:after": {
      borderBottomColor: AppColors.WHITE,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.WHITE,
    // backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },
}));

const privacyDropdownItems = [
  {
    name: CommonTexts.PUBLIC,
    value: "PUBLIC",
    testId: "public"
  },
  {
    name: CommonTexts.PRIVATE,
    value: "PRIVATE",
    testId: "private"
  }
]

export function FormCreateOrEdit({ isCreateNewForm, drawer, drawerStudy }) {
  const { studyId, studyPermissions, permissions } = useLocation().state

  // ! We access index 0 because there is only one section in CBM. Fix it for studies
  const form = useSelector(({ form }) => form.data?.length > 0 && form.data[0])

  const { data: localesData, loading: localesLoading, error: localesError } = useSelector(({ locales }) => locales)

  const [title, setTitle] = useState(form?.title || "")
  const [description, setDescription] = useState(form?.description || "")
  const [view, setView] = useState(form?.view || "PRIVATE")
  const [tags, setTags] = useState(undefined)
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedLocales, setSelectedLocales] = useState(form?.locales || [])

  const dispatch = useDispatch()
  const classes = useStyles();
  const history = useHistory()

  useEffect(() => {
    if (isCreateNewForm && form?.id) {
      setTimeout(() =>
        history.push({
          pathname: `/study/${studyId}/form/${form.id}`,
          state: {
            studyId,
            formId: form.id,
            value: 1,
            permissions,
            studyPermissions
          },
        }),
        1000)
    }
  }, [form])

  useEffect(() => {
    if (!localesData && !localesLoading) {
      dispatch(initializeLocales())
    }
  }, [])

  useEffect(() => {
    getTags()
    // getLocales()
  }, [])

  async function handleSubmit(e) {
    e.preventDefault()
    if (studyPermissions.includes("form-edit")) {
      try {
        const internal_code = camelTo_snake(title)

        if (isCreateNewForm) {
          const newForm = new FormModal(
            title,
            description,
            "PUBLIC", // ! CBM forms are always public
            internal_code,
            selectedTags.map(tag => tag.value),
            null,
            null,
            selectedLocales.map(locale => {
              return {
                locale: locale.value,
                title,
                description,
              }
            }),
            selectedLocales[0].id
          )

          dispatch(createFormAction(studyId, newForm.createToJSON))

          return
        }

        const editForm = new FormModal(
          title,
          description,
          "PUBLIC", // ! CBM forms are always public
          internal_code,
          selectedTags.map(tag => tag.value),
          form?.id,
          form?.creationDate,
          selectedLocales.map(locale => {
            return {
              locale: locale.value,
              title,
              description,
            }
          }),
          form.defaultLocale.id
        );

        dispatch(updateFormAction(studyId, editForm.modifyToJSON))
      } catch (error) {
        console.error(error)
        dispatch(toast("Error while updating the form. Please try again", "error"))
      }

    } else {
      dispatch(toast("You don't have permission to edit this form", "error"))
    }
  }

  // async function handleChangeArchivedForm(e) {
  //   e.preventDefault()
  //   if (studyPermissions.includes("form-edit")) {
  //     try {
  //       const internal_code = camelTo_snake(title)

  //       const editedForm = new FormModalStatus(
  //         title,
  //         description,
  //         view,
  //         form.id,
  //         form.archived ? "PUBLISHED" : "ARCHIVED",
  //         internal_code,
  //         form.creationDate
  //       );

  //       const { data } = await FormService.putForm(studyId, editedForm.statusToJSON);

  //       // ! API returns 1 or 2 instead of 0 or 1 for this field
  //       setForm({ ...form, archived: data.data.item.form_status === 1 ? false : true })
  //     } catch (error) {
  //       console.error(error)
  //       dispatch(toast("Error while updating the form. Please try again"))
  //     }

  //   } else {
  //     dispatch(toast("You don't have permission to edit this form", "error"))
  //   }
  // }

  async function getTags() {
    try {
      const { data } = await TagsService.getTags();

      const mappedTags = data.data.items?.map(tag => {
        return {
          name: tag.name,
          value: tag.name
        }
      }).sort((a, b) =>
        a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

      setTags(mappedTags)

      if (!isCreateNewForm) {
        // * Set same tags objects from the API response to be the already selected objects so the options and the previous values of the Select component share the same object reference to ensure its correct behavior
        setSelectedTags(mappedTags.filter(mappedTag => form.tags.some(formTag => formTag.value === mappedTag.value)))
      }
    } catch (error) {
      console.error(error)
      dispatch(toast("Unable to get the tags list", "error"))
    }
  };

  function camelTo_snake(str) {
    return str &&
      str.match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
        .map((x) => x.toLowerCase())
        .join("_");
  }

  function handletitle(e) {
    setTitle(e.target.value) // TODO: Form title must be unique within the study
  }

  function handleFormDescription(e) {
    setDescription(e.target.value)
  }

  function handleChangePrivateForm(e) {
    setView(e.target.value)
  }

  // function handleCopyURLToClipboard() {
  //   navigator.clipboard.writeText(form?.url);
  //   dispatch(toast("Link copied to clipboard!", "success"))
  // }

  function handleChangeTags(event) {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === "all") {
      setSelectedTags(selectedTags.length === tags.length ? [] : tags);
      return;
    }

    setSelectedTags(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  function handleChangeLocales(event) {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === "all") {
      // * If all locales are deselected, the default locale remains in the state
      setSelectedLocales(selectedLocales.length === localesData.length
        ? localesData.filter(locale => locale.value === form.defaultLocale.value)
        : localesData);
      return;
    }

    setSelectedLocales(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  return (
    <>
      {isCreateNewForm &&
        <DrawerUhda
          id={studyId}
          drawer={drawer}
          drawerStudy={drawerStudy}
          select={1}
          settings={studyPermissions}
        />}
      <Card
        sx={{
          p: 4,
          maxWidth: "1000px",
          marginLeft: drawerStudy ? "190px" : 0,
          paddingRight: "24px",
          marginTop: drawerStudy ? 2 : 0
        }}
      >
        <Box id="form-details-title" >
          <Typography variant="subtitle_bold" paragraph >
            {LabelsForm.FORM_DETAIL}
          </Typography>
          <Divider />
        </Box>
        <Box
          id="form-details-form"
          component="form"
          onSubmit={handleSubmit}
          mt={2}
          maxWidth={"800px"}
        >
          <Box
            id="form-details-form-first-section"
            display="flex"
            justifyContent="space-around"
            alignItems="center"
          >
            <TextField
              data-testId={"formTitle"}
              value={title}
              required
              onChange={handletitle}
              label={LabelsForm.TITLE}
              variant="outlined"
              sx={{ minWidth: "45%" }}
              className={classes.textFieldLabel}
              disabled={!studyPermissions.includes("form-edit")}
              inputProps={{ maxLength: 100 }}
            />
            {/* <FormControl
              required
              className={classes.select}
              sx={{ minWidth: "45%" }}
              data-testId="privacy"
            >
              <SingleChoiceDropdown
                label={"Privacy"}
                value={view}
                handleValue={handleChangePrivateForm}
                items={privacyDropdownItems}
                className={classes.select}
              />
            </FormControl> */}
            <FormControl
              className={classes.textFieldLabel}
              sx={{ minWidth: "45%", maxWidth: "0%" }}
              data-testId="tags-dropdown"
            >
              <MultipleChoiceCheckboxesDropdown
                label={"Tags"}
                value={selectedTags}
                handleValue={handleChangeTags}
                items={!!tags ? tags : []}
                disabled={!studyPermissions.includes("form-edit")}
              />
            </FormControl>
          </Box>
          <Box
            id="form-details-form-second-section"
            display="flex"
            justifyContent="space-around"
            mt={4}
          >
            <TextField
              data-testId={"formDescription"}
              value={description}
              required
              onChange={handleFormDescription}
              multiline
              minRows={4}
              label={LabelsForm.DESCRIPTION}
              variant="outlined"
              sx={{ minWidth: "45%" }}
              className={classes.textFieldLabel}
              disabled={!studyPermissions.includes("form-edit")}
              inputProps={{ maxLength: 200 }}
            />
            {/* <TextField
              sx={{ minWidth: "45%", mt: 0 }}
              label={LabelsForm.LINK}
              defaultValue={form?.url || "Form URL placeholder"}
              margin="normal"
              disabled
              variant="outlined"
              className={classes.textFieldLabelDisabled}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleCopyURLToClipboard}
                      size="large">
                      <img
                        alt='icon'
                        src={copyIcon} />
                    </IconButton>
                  </InputAdornment>

                ),
              }}
            /> */}
            <FormControl
              required
              className={classes.textFieldLabel}
              sx={{ minWidth: "45%", maxWidth: "0%" }}
              data-testId="privacy"
            >
              <MultipleChoiceCheckboxesDropdown
                label={"Locales"}
                value={selectedLocales}
                handleValue={handleChangeLocales}
                defaultLocale={form.defaultLocale?.value}
                items={!!localesData ? localesData : []}
                className={classes.select}
                disabled={!studyPermissions.includes("form-edit")}
              />
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={-5} maxWidth="780px" >
            <Button
              variant='contained'
              type='submit'
              sx={{ minWidth: { xs: "100px", sm: "125px" }, p: 1 }}
              style={{ backgroundColor: AppColors.PRIMARY }}
              data-testId="saveForm"
            >
              <Typography variant='button_text' sx={{ color: "white" }}>
                {CommonTexts.SAVE}
              </Typography>
            </Button>
            {/* <Button
              variant='contained'
              onClick={handleChangeArchivedForm}
              sx={{ minWidth: { xs: "100px", sm: "125px" }, ml: 4 }}
              style={{ backgroundColor: form?.archived ? AppColors.PRIMARY : AppColors.RED, color: AppColors.WHITE }}
              data-testId="archiveForm"
            >
              {form?.archived === 1 ?
                <Icons.ARCHIVE
                  size={"1.5em"}
                  style={{ color: AppColors.WHITE }}
                />
                :
                <Icons.UNARCHIVE
                  size={"1.5em"}
                  style={{ color: AppColors.WHITE }}
                />
              }
              <Typography variant='button_text' sx={{ color: "white", marginLeft: "8px" }}>
                {form?.archived ? LabelsForm.PUBLISH : LabelsForm.ARCHIVE}
              </Typography>
            </Button> */}
          </Box>
        </Box>
      </Card>
    </>
  );
}