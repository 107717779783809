import React from "react"
import { FormControlLabel, Switch } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types"
import { AppColors } from "../../resources/AppColors";
import { alpha } from "@mui/material/styles";
const useStyles = makeStyles((theme) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: AppColors.PRIMARY,
    "&:hover": {
      backgroundColor: alpha(AppColors.PRIMARY, theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: AppColors.PRIMARY,
  },
}));
/**
 * @component
 * Component to create a switch option on the form
 * @param {string} label: title of the switch
 * @param {boolean} value: state of the switch
 * @param {function} handleChange: handle to change the state of the switch
 * @constructor
 * <FormSwitchUhda label={"Mandatory"} handleChange={handleChange} value={true} />

 */
const FormSwitchUhda = ({ label, value, handleChange }) => {
  const classes = useStyles();

  return (
    <FormControlLabel style={{ minWidth: "140px", justifyContent: "space-between" }}
      control={
        <Switch
          className={classes.switch}
          checked={value}
          onChange={e => handleChange(e.target.checked)}
          label={label}
          color="error"
        />
      }
      labelPlacement="start"
      label={label}
    />
  )
}

FormSwitchUhda.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default FormSwitchUhda;
