import { CommonTexts, LinksInfo } from "locale/en";
import { Box, Typography, FormControl } from "@mui/material";
import SingleChoiceDropdown from "../Dropdowns/SingleChoiceDropdown";
import {
  QUESTION_CALCULATION,
  QUESTION_CALENDAR,
  QUESTION_INFO,
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_NUMERIC,
  QUESTION_RANGE,
  QUESTION_SINGLE_CHOICE,
  QUESTION_TEXT,
  questionTypesByName,
  questionTypes,
} from "resources/QuestionConstants";
import { makeStyles } from "@mui/styles";
import { AppColors } from "resources/AppColors";

const useStyles = makeStyles({
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
        backgroundColor: AppColors.BACKGROUND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      backgroundColor: "transparent"

    },
    "&:before": {
      color: AppColors.WHITE,
    },
    "&:after": {
      borderBottomColor: AppColors.WHITE,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.WHITE,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  }
})

const dropdownItems = [
  {
    name: "Info",
    value: QUESTION_INFO
  },
  {
    name: "Text",
    value: QUESTION_TEXT
  },
  {
    name: "Date",
    value: QUESTION_CALENDAR
  }, {
    name: "Numerical",
    value: QUESTION_NUMERIC
  }, {
    name: "Scale",
    value: QUESTION_RANGE
  },
  {
    name: "Single select",
    value: QUESTION_SINGLE_CHOICE
  },
  {
    name: "Multiple select",
    value: QUESTION_MULTIPLE_CHOICE
  },
  // {
  //   name: "Calculation",
  //   value: QUESTION_CALCULATION
  // },
]

function SelectQuestionTypes({ questionType, setQuestionType }) {
  const classes = useStyles()
  /**
 * It sets the questionType state to the value of the selected option in the dropdown menu
 * @param e - the event object
 */
  const handleChangeQuestionType = (e) => {
    setQuestionType(questionTypes[e.target.value].name);
  };

  return <>
    <FormControl
      margin="normal"
      fullWidth
      className={classes.select}
    >
      <SingleChoiceDropdown
        label={"Question Type"}
        value={questionTypesByName[questionType]?.id}
        handleValue={handleChangeQuestionType}
        items={dropdownItems}
      />
    </FormControl>
    <FormControl margin="normal">
      <Box
        component="a"
        href={LinksInfo.QUESTION_TYPE}
        target="_blank"
        rel="noreferrer"
        sx={{
          textDecorationColor: 'red',
          '&:hover': {
            textDecorationColor: 'blue',
          }
        }}
      >
        <Typography variant="body">
          {CommonTexts.LEARN_MORE}
        </Typography>
      </Box>
    </FormControl>
  </>;
}

export default SelectQuestionTypes