export const AppColors = {
  "PRIMARY": "#C4141B",
  "PRIMARY_WITH_OPACITY": "#1D397220",
  "PRIMARY_MAX_OPACITY": "#F5F6FA",
  "SECONDARY": "#7F8DAA",
  "BACKGROUND": "#F0F0F0",
  "BLACK": "#000000",
  "WHITE": "#FFFFFF",
  "RED": "#F0142F",
  "PIRMARY_WITH_OPACITY": "#1D397220",
  "SUBTEXT": "#707070",
  "BOX_FILL": "#f1f9ff",
  "ACCENT": "#004CE3",
  "BOX_SHADOW": "#00000029",
  "GREEN": "#3DD598",
  "SECONDARY_TITLE": "#5A607F",
  "SECONDARY_SUBTEXT": "#7E84A3",
  "GREY": "#95989A",
  "CBM_YELLOW": "#FFC20C",
  "CBM_SAND": "#B09C78",
  "CBM_SAND_OPACITY": "#B09C7840",
  "CBM_UMBRA": "#716951",
  "CBM_ORANGE": "#F39200",
  "CBM_PURPLE": "#8C5BA5",
  "CBM_BLUE": "#719BD1",
  "MANUSCRIPT": "#0039A6",
  "DISABLED_BG": "#b9bbbd"
}
//node_modules/webpack/lib/util/createHash.js