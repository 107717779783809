// eslint-disable-next-line no-unused-vars
const {Component} = require("react");

export default class FormModalStatus {

  constructor(title, description, privado, id, status, internalCode, dateCreation) {
    this.title = title;
    this.description = description;
    this.privado = privado;
    this.id = id;
    this.status = status;
    this.internalCode = internalCode;
    this.dateCreation = dateCreation;
  }

  get statusToJSON() {
    return {
      "id": `${this.id}`,
      "internalCode": this.internalCode,
      "slug": this.title,
      "dateCreation": this.dateCreation,
      "periodicity": [{
        "id": "cd633339-eb93-4ecd-8bd3-22096cee625a",
        "type": "IMMEDIATILLY",
        "isReminder": false,
        "periodicityCronExpression": "PERIOD_Lorem_ipsum",
        "hour": "00:00:00",
        "date": "11/11/2011",
        "maxReminders": 1
      }],
      "finishDate": null,
      "formStatus": this.status,
      "visibility": this.privado,
      "private": false,
      "personal": false,
      "formTranslation": [{
        "locale": "en",
        "title": this.title,
        "subtitle": "UK2 Lorem ipsum...",
        "description": this.description,
      }, {
        "locale": "es",
        "title": this.title,
        "subtitle": "ES2 Lorem ipsum...",
        "description": this.description,
      }]
    };
  }

}