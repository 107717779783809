import React from "react";
import { Grid } from "@mui/material"
import { AppColors } from "../resources/AppColors"
import TextUhda from "../components/TextUdha";
import { CommonTexts } from "../locale/en";
import { textType } from "../resources/AppTexts";

/**
 * @page
 * Page that shows the main page where there is only a text welcoming
 */
const HomePage = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item style={{ margin: "30vh" }}>
        <TextUhda type={textType.TITLE} color={AppColors.PRIMARY} text={CommonTexts.WELCOME_BACK} />
      </Grid>

    </Grid>
  )
}

export default HomePage;
