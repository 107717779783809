import { TextField, Autocomplete } from '@mui/material'

export function SearchBar({ entities, setFilteredValues, placeholder, searchKey, ...props }) {
  function handleSetValue(event, newValue) {
    if (!newValue) {
      setFilteredValues(entities)
      return
    }

    setFilteredValues([newValue])
  }

  if (!entities) return null

  return (
    <>
      {entities.length > 0 &&
        <Autocomplete
          id="searchbar-autocomplete"
          data-cy="searchbar-autocomplete"
          component="select"
          onChange={handleSetValue}
          options={entities}
          autoHighlight
          clearOnEscape
          getOptionLabel={(option) => option[searchKey] || ""}
          sx={{
            mb: 2,
            bgcolor: 'white',
            boxShadow: 3,
            py: 1.5,
            px: 1.5,
            borderRadius: 1,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant='standard'
              placeholder={placeholder}
            />
          )}
          {...props}
        />
      }
    </>
  )
}
