/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import { React, useContext, useEffect, useState } from "react";

import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { AppColors } from "./resources/AppColors";
import { BrowserRouter as Router, Link, Redirect, Route, Switch, } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  StyledEngineProvider,
} from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import Login from "./pages/LoginPage";
import StudiesPage from "./pages/StudiesPage";
import ForgotPassPage from "./pages/ForgotPassPage";
import ChangePassPage from "./pages/ChangePassPage";
import AuthState from "./context/authentication/authState";
import AuthContext from "./context/authentication/authContext";
// import EditFormPage from "./pages/EditFormPage";
import NavbarUhda from "./components/NavbarUhda";
import ListItemIcon from "@mui/material/ListItemIcon";
import translateIcon from "./img/translate_icon.svg"
import participantsIcon from "./img/participants_icon.svg";
import folderIcon from "./img/folder_icon.svg";
import tagIcon from "./img/tag_icon.svg";
import HomePage from "./pages/HomePage";
import CollaboratorsPage from "./pages/CollaboratorsPage";
import UsersPage from "./pages/UsersPage";
import NotFoundPage from "./pages/NotFoundPage";
import StudyPage from "./pages/StudyPage";
import { useTextStyles } from "./resources/AppTexts";
import MenuIcon from "@mui/icons-material/Menu";
import { CommonTexts, LabelsDrawer } from "./locale/en";
import AccountPage from "./pages/AccountPage";
import FormsPage from "./pages/FormsPage";
import DisseminationPage from "./pages/DisseminationPage";
import StudyParticipantsPage from "./pages/StudyParticipantsPage";
import SettingsPage from "./pages/SettingsPage";
import TeamMembersPage from "./pages/TeamMembersPage";
import ForgotPassSecondPage from "./pages/ForgotPassSecondPage";

import "./styles/app.scss";
import TranslatePage from "./pages/TranslatePage";
import TagsPage from "./pages/TagsPage";
import { StorageManager } from "./utils";
import { BASE_PATH } from "./resources/ApiUrls";
import MyAxiosInstance from "./utils/MyAxiosInstance";
import { useMatomo } from "@jonkoops/matomo-tracker-react"
import ScrollToTop from "./components/ScrollToTop";
import DashboardPage from "./pages/DashboardPage";
import customTheme from "./themes/customTheme";
import Notification from "./components/Notification/Notification";
import { FormPage2 } from "./pages/StudyPage/FormsPage/FormPage/FormPage2";
import { FormCreateOrEdit } from "pages/StudyPage/FormsPage/FormPage/FormCreateOrEdit";

// ! This global variables are the source of many issues probably
window.abierto = false;
window.abiertoStudy = true;
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: AppColors.BACKGROUND,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    marginLeft: "72px",
    flexGrow: 1,
    minHeight: "100vh !important",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  iconRoot: {
    textAlign: "center",
  },
  imageIcon: {
    height: "100%",
  },

  hide: {
    display: "none",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, drawer, drawerStudy, ...rest }) => {
  const storageManager = new StorageManager()
  const [roles, setRoles] = useState()
  // eslint-disable-next-line no-param-reassign
  if (drawer == null) drawer = true;
  // eslint-disable-next-line no-param-reassign
  if (drawerStudy == null) drawerStudy = false;
  const authContext = useContext(AuthContext);
  const { checkAuth } = authContext;
  const texts = useTextStyles();
  const [lateralBar, setLateralBar] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState(() => {
    try {
      let item = null;
      if (sessionStorage.length > 0) {
        item = sessionStorage.getItem("user");
      } else if (localStorage.length > 4) {
        item = localStorage.getItem("user");
      } else {
        item = null;
      }
      return item;
    } catch (error) {

      return "ERROR";
    }
  });

  const getUser = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/user/${storageManager.getId()}`);
      storageManager.storeAdmin(response.data.data.item.is_superadmin)
      storageManager.storePermissions(response.data.data.item.permissions)
      storageManager.storeRoles(response.data.data.item.roles)
      setRoles(response.data.data.item.roles)
      if (response.data.data.item.is_superadmin == 1) {
        setLateralBar([[LabelsDrawer.STUDIES, LabelsDrawer.TAGS, LabelsDrawer.TRANSLATIONS, LabelsDrawer.USERS], [folderIcon, tagIcon, translateIcon, participantsIcon], ["/studies", "/tags", "/locales", "/users",]])
      } else if (response.data.data.item.permissions.includes("access-locales-table")) {
        setLateralBar([[LabelsDrawer.STUDIES, LabelsDrawer.TRANSLATIONS], [folderIcon, translateIcon,], ["/studies", "/locales",]])
      } else {
        setLateralBar([[LabelsDrawer.STUDIES], [folderIcon], ["/studies"]])
      }
      // eslint-disable-next-line no-empty
    } catch (err) {

    }
  }

  const classes = useStyles();
  const [open, setOpen] = useState(); //false
  const [openStudy, setOpenStudy] = useState(); //false
  const [selected, setSelected] = useState(null);
  const handleDrawer = () => {
    if (window.location.pathname.includes("/study")) {
      if (openStudy) {
        window.abierto = false;
        window.abiertoStudy = false
        setOpen(false);
        setOpenStudy(false);
      } else {
        window.abierto = false;
        window.abiertoStudy = true
        setOpen(false);
        setOpenStudy(true);

      }
    } else {
      if (open) {
        window.abierto = false;
        setOpen(false);
      } else {
        window.abierto = true;
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    setOpen(drawer);
    setOpenStudy(drawerStudy)
  }, [drawer, drawerStudy]);

  useEffect(() => {
    if (window.location.pathname.includes("/study")) {
      setLateralBar([[LabelsDrawer.STUDIES], [folderIcon], ["/studies"]])
    } else if (storageManager.getAdmin() == 1) {
      setLateralBar([[LabelsDrawer.STUDIES, LabelsDrawer.TAGS, LabelsDrawer.TRANSLATIONS, LabelsDrawer.USERS], [folderIcon, tagIcon, translateIcon, participantsIcon], ["/studies", "/tags", "/locales", "/users",]])
    } else if (storageManager.getPermissions().includes("access-locales-table")) {
      setLateralBar([[LabelsDrawer.STUDIES, LabelsDrawer.TRANSLATIONS], [folderIcon, translateIcon,], ["/studies", "/locales",]])
    } else {
      setLateralBar([[LabelsDrawer.STUDIES], [folderIcon], ["/studies"]])
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const path = window.location.pathname
    window.addEventListener("popstate", (event) => {
      setSelected(path == "/studies" ? 0 : path == "/tags" ? 1 : path == "/locales" ? 2 : path == "/users" ? 3 : 0);
    });
    setSelected(path == "/studies" ? 0 : path == "/tags" ? 1 : path == "/locales" ? 2 : path == "/users" ? 3 : 0);
    getUser()
    checkAuth();
  }, []);

  return <>
    <NavbarUhda
      open={open}
      setOpen={setOpen}
      setSelected={setSelected}
      logged={user}
    />
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
    >
      <div className={classes.drawerHeader} />
      <Route
        {...rest}
        render={(props) =>
          user ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    </main>
    <Drawer
      anchor="left"
      open={drawer}
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >

      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawer} size="large">
          {<MenuIcon style={{ color: AppColors.PRIMARY }} />}
        </IconButton>
      </div>
      <Divider />
      {roles && lateralBar && <List>
        {lateralBar[0].map((text, index) => (
          <Link
            to={lateralBar[2][index]}
            onClick={() => {
              setSelected(index);
              window.abierto = false;
            }}
            style={{ color: AppColors.WHITE, textDecoration: "none" }}
          >
            <ListItem
              button
              key={text}
              selected={selected === index}
              style={{
                backgroundColor:
                  selected === index && AppColors.CBM_SAND_OPACITY,
                borderLeft:
                  selected === index && "5px solid " + AppColors.PRIMARY,
                paddingLeft:
                  (selected === index && "22px") ||
                  (selected !== index && "27px"),
              }}
            >
              <ListItemIcon>
                <Icon classes={classes.iconRoot} style={{ height: "1.4em", width: "2em", zIndex: "1", marginLeft: "-8px" }}>
                  <img
                    alt="icon"
                    className={classes.imageIcon}
                    src={lateralBar[1][index]}
                  />
                </Icon>
              </ListItemIcon>
              <ListItemText
                classes={{ primary: texts.subtitle_bold }}
                primary={text}
                style={{ color: AppColors.BLACK }}
              />
            </ListItem>
          </Link>
        ))}
      </List>}
    </Drawer>
    <AppBar position="relative" className={classes.appBar}
      style={{
        paddingLeft: "10em",
        top: "auto",
        bottom: 0,
        color: AppColors.WHITE,
        background: AppColors.PRIMARY,
      }}>
      <Box direction="row" display="flex" justifyContent="center" m={3}
        className="bottom-bar"
      >
        <Box mx="auto">
          <a style={{ color: AppColors.WHITE, marginLeft: open && window.innerWidth > 1000 ? "8em" : "1px" }}
            href="https://docs.google.com/document/u/1/d/e/2PACX-1vQ3NIwP7OMOI-3DeN-KBumIStXruEyp85ACtno3Qv5xLUMMyvN4XC_p72aQCGK1p0lFRzQ00xNZk0DA/pub"
            target="_blank" rel="noreferrer">{CommonTexts.ABOUT}</a>

        </Box>
        <Box mx="auto">
          <a style={{ color: AppColors.WHITE }}
            href='https://docs.google.com/document/u/1/d/e/2PACX-1vQkQFg4O7erLNHlJTlXpthfriz1iWRWOQjWAgxGgux2grq1MNOw0zp1dGlZRUvUyy6C2t8-m4sgYtrW/pub'
            target="_blank" rel="noreferrer">{CommonTexts.USER_GUIDE}</a>
        </Box>
        <Box mx="auto">
          <a style={{ color: AppColors.WHITE }}
            href='https://docs.google.com/document/u/1/d/e/2PACX-1vS6H9drn7R70m0Ftgjow7aejc8QGNuObZfvVAFzFqphoftVxA5qlDZz4664WURVoVbTSH-H-93YBgN0/pub'
            target="_blank" rel="noreferrer">{CommonTexts.TERMS}</a>
        </Box>
        <Box mx="auto">
          <a style={{ color: AppColors.WHITE }}
            href='https://docs.google.com/document/u/1/d/e/2PACX-1vQ6Sh5WmhnQjMMo28czb6kJbb00JVZnrAR1yB6Lngdg8d_lz_H1R2VxLDM1fMBmWzCAbL6cJVvc4Puc/pub'
            target="_blank" rel="noreferrer">{CommonTexts.PRIVACY}</a>
        </Box>
      </Box>
    </AppBar>
  </>;
};

function App() {
  const { trackPageView } = useMatomo()
  useEffect(() => {
    trackPageView()
  }, [])
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customTheme}>
        <AuthState>
          <Router>
            <ScrollToTop />
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot" component={ForgotPassPage} />
              <Route exact path="/change" component={ChangePassPage} />
              <Route path="/change/:id" component={ForgotPassSecondPage} />
              <PrivateRoute path="/account" component={AccountPage} />
              <PrivateRoute
                path="/study/:id/dashboard"
                component={() => <DashboardPage drawer={window.abierto} drawerStudy={window.abiertoStudy} />}
                drawer={false}
                drawerStudy={true}
              />
              <PrivateRoute
                path="/study/:id/forms"
                component={() => <FormsPage drawer={window.abierto} drawerStudy={window.abiertoStudy} />}
                drawer={false}
                drawerStudy={true}
              />
              <PrivateRoute
                path="/study/:id/participants"
                component={() => <StudyParticipantsPage drawer={window.abierto} drawerStudy={window.abiertoStudy} />}
                drawer={false}
                drawerStudy={true}
              />
              <PrivateRoute
                path="/study/:id/dissemination"
                component={() => <DisseminationPage drawer={window.abierto} drawerStudy={window.abiertoStudy} />}
                drawer={false}
                drawerStudy={true}
              />
              <PrivateRoute
                path="/study/:id/settings"
                component={() => <SettingsPage drawer={window.abierto} drawerStudy={window.abiertoStudy} />}
                drawer={false}
                drawerStudy={true}
              />
              <PrivateRoute
                exact
                path="/study"
                component={() => <SettingsPage drawer={window.abierto} drawerStudy={window.abiertoStudy} />}
                drawer={false}
                drawerStudy={true}
              />
              <PrivateRoute
                path="/study/:id/teamMembers"
                component={() => <TeamMembersPage drawer={window.abierto} drawerStudy={window.abiertoStudy} />}
                drawer={false}
                drawerStudy={true}
              />
              <PrivateRoute
                path="/study/:id/form/:id"
                component={() => <FormPage2 drawer={window.abierto} drawerStudy={window.abiertoStudy} />}
                drawer={false}
                drawerStudy={true}
              />

              <PrivateRoute
                exact
                path="/study/:id/form"
                component={() => <FormCreateOrEdit isCreateNewForm drawer={window.abierto} drawerStudy={window.abiertoStudy} />}
                drawer={false}
              />

              <PrivateRoute
                exact
                path="/study/:id"
                component={() => <StudyPage drawer={window.abierto} />}
                drawer={false}
              />

              <Route exact path="/" component={() => <Redirect to="/login" />} />
              <PrivateRoute path="/home" component={HomePage} drawer={true} />
              <PrivateRoute path="/studies" component={StudiesPage} />
              <PrivateRoute path="/tags" component={TagsPage} />
              <PrivateRoute path="/users" component={UsersPage} />
              <PrivateRoute path="/locales" component={TranslatePage} />
              <PrivateRoute path="/collaborators" component={CollaboratorsPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </Router>
          <Notification />
        </AuthState>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
