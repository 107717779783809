import { Box, FormControl, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SingleChoiceDropdown from "components/Dropdowns/SingleChoiceDropdown";
import RichTextEditorLexical from "components/RichTextEditor";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AppColors } from "resources/AppColors";
import { questionTypesByName } from "resources/QuestionConstants";
import { createEmptyStateLexical, findTranslation } from "utils/HelperFunctions";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
        backgroundColor: AppColors.BACKGROUND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
    width: "100%",
    marginTop: "16px"
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
        backgroundColor: AppColors.BACKGROUND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      backgroundColor: "transparent"

    },
    "&:before": {
      color: AppColors.WHITE,
    },
    "&:after": {
      borderBottomColor: AppColors.WHITE,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.WHITE,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
    width: "100%",
    marginTop: "16px"
  }
}));

export function FirstPageTranslateQuestionForm({
  selectedQuestion,
  selectedLocale,
  handleChangeLocale,
  questionTitleHTML,
  setQuestionTitleHTML,
  setQuestionTitlePlain,
  helperTextHTML,
  setHelperTextHTML,
  setHelperTextPlain,
  resetEditorState,
  setResetEditorState,

}) {
  const form = useSelector(({ form }) => form.data[0])

  const [disabledQuestionTitleJSON, setDisabledQuestionTitleJSON] = useState(createEmptyStateLexical())
  const [disabledQuestionTitleHTML, setDisabledQuestionTitleHTML] = useState(findTranslation(selectedQuestion.questionTranslations, form.defaultLocale.value)?.question || "")
  const [disabledQuestionTitlePlain, setDisabledQuestionTitlePlain] = useState("")

  const [disabledHelperTextJSON, setDisabledHelperTextJSON] = useState(createEmptyStateLexical())
  const [disabledHelperTextHTML, setDisabledHelperTextHTML] = useState(findTranslation(selectedQuestion.questionTranslations, form.defaultLocale.value)?.info || "")
  const [disabledHelperTextPlain, setDisabledHelperTextPlain] = useState("")

  const [questionTitleJSON, setQuestionTitleJSON] = useState(createEmptyStateLexical())
  const [helperTextJSON, setHelperTextJSON] = useState(createEmptyStateLexical())

  const classes = useStyles()

  return (
    <Box
      id="first-page-container"
      display="flex"
      justifyContent="space-around"
    >
      <Box
        id="left-section"
        sx={{ maxWidth: { xs: "45%", lg: "50%" } }}
      >
        <TextField
          defaultValue={selectedQuestion.variableName}
          disabled
          className={classes.textField}
          label={"Variable Name"}
        />
        <TextField
          defaultValue={questionTypesByName[selectedQuestion.type]?.label}
          disabled
          className={classes.textField}
          label={"Question Type"}
        />
        <Typography variant="h6" style={{ color: AppColors.PRIMARY, marginBottom: "-8px", marginTop: "16px", fontSize: "16px" }} >Question Title</Typography>
        <FormControl

          id="question-title"
          data-testId={"questionTitle"}
          margin="normal"
          style={{ width: "100%", marginTop: -1 }}>

          <RichTextEditorLexical
            jsonTextEditor={disabledQuestionTitleJSON}
            rawHtmlTextEditor={disabledQuestionTitleHTML}
            setJsonTextEditor={setDisabledQuestionTitleJSON}
            setRawHtmlTextEditor={setDisabledQuestionTitleHTML}
            setPlainTextEditor={setDisabledQuestionTitlePlain}
            isEditable={false}
          />
        </FormControl>

        <Typography variant="h6" style={{ color: AppColors.PRIMARY, marginBottom: "-8px", fontSize: "16px" }} >Helper Text</Typography>
        <FormControl

          data-testId={"questionHelper"}
          margin="normal"
          style={{ width: "100%", marginTop: -1 }}>
          <RichTextEditorLexical
            jsonTextEditor={disabledHelperTextJSON}
            rawHtmlTextEditor={disabledHelperTextHTML}
            setJsonTextEditor={setDisabledHelperTextJSON}
            setRawHtmlTextEditor={setDisabledHelperTextHTML}
            setPlainTextEditor={setDisabledHelperTextPlain}
            isEditable={false}
          />
        </FormControl>
      </Box>
      <Box
        id="right-section"
        sx={{ maxWidth: { xs: "45%", lg: "50%" } }}
      >
        <TextField
          defaultValue={form.defaultLocale.name}
          disabled
          className={classes.textField}
          label={"Original Locale"}
        />
        <FormControl
          className={classes.select}
        >
          <SingleChoiceDropdown
            label={"Current Locale"}
            value={selectedLocale}
            handleValue={handleChangeLocale}
            items={form?.locales}
          />
        </FormControl>
        <Typography variant="h6" style={{ color: AppColors.PRIMARY, marginBottom: "-8px", marginTop: "16px", fontSize: "16px" }}>Translated title</Typography>
        <FormControl

          id="question-title"
          data-testId={"questionTitle"}
          margin="normal"
          style={{ width: "100%", marginTop: -1 }}>
          <RichTextEditorLexical
            jsonTextEditor={questionTitleJSON}
            rawHtmlTextEditor={questionTitleHTML}
            setJsonTextEditor={setQuestionTitleJSON}
            setRawHtmlTextEditor={setQuestionTitleHTML}
            setPlainTextEditor={setQuestionTitlePlain}
            resetEditorState={resetEditorState}
            setResetEditorState={setResetEditorState}
          />
        </FormControl>
        <Typography variant="h6" style={{ color: AppColors.PRIMARY, marginBottom: "-8px", fontSize: "16px" }}>Translated helper text</Typography>
        <FormControl

          data-testId={"questionHelper"}
          margin="normal"
          style={{ width: "100%", marginTop: -1 }}>
          <RichTextEditorLexical
            jsonTextEditor={helperTextJSON}
            rawHtmlTextEditor={helperTextHTML}
            setJsonTextEditor={setHelperTextJSON}
            setRawHtmlTextEditor={setHelperTextHTML}
            setPlainTextEditor={setHelperTextPlain}
            resetEditorState={resetEditorState}
            setResetEditorState={setResetEditorState}
          />
        </FormControl>
      </Box>
    </Box>
  )
}