import { Box, TextField, Typography } from "@mui/material"
import { LabelsForms } from "locale/en"
import { questionTypes, QUESTION_CALCULATION } from "resources/QuestionConstants"

function CalculationQuestion({ numericalQuestionsValues, handleNumericalQuestionsValues, questionType }) {
  return (
    <>
      {questionType === questionTypes[QUESTION_CALCULATION].name
        && (
          <Box id="calculation-question">
            {/* <QuestionDropdown questions={questions} /> */}
            <TextField
              name="calculation"
              type="text"
              required
              onChange={handleNumericalQuestionsValues}
              label={"Calculation"}
              value={numericalQuestionsValues.calculation}
              variant='outlined'
              margin="normal"
              sx={{ width: "100%" }}
            />
            <Box mt={2}>
              <Typography
                variant="body"
                paragraph
              >
                {LabelsForms.CALCULATION_INFO_1}
              </Typography>
              <Typography
                variant="body"
                paragraph
              >
                {LabelsForms.CALCULATION_INFO_2}
              </Typography>
              <Typography
                variant="body"
              >
                {LabelsForms.CALCULATION_INFO_3}
              </Typography>
            </Box>
          </Box>
        )}
    </>
  )
}

export default CalculationQuestion