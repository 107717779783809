// eslint-disable-next-line no-unused-vars
const {Component} = require("react");

export default class StudyModal {
// eslint-disable-next-line camelcase
  constructor(username, password, slug, remember_me) {
    this.username = username;
    this.password = password;
    this.slug = slug;
    // eslint-disable-next-line camelcase
    this.remember_me = remember_me;
  }

  get createToJSON() {
    return {
      "username": this.username,
      "password": this.password,
      "slug": this.slug,
      "remember_me": this.remember_me
    };
  }

}
