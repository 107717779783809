import { Box, TextField } from "@mui/material";
import ConditionalQuestions from "components/NewQuestions/ConditionalQuestions/ConditionalQuestions";
import ChoiceQuestion from "components/NewQuestions/ChoiceQuestion";
import NumericQuestion from "components/NewQuestions/NumericQuestion";
import RangeQuestion from "components/NewQuestions/RangeQuestion";
import CalculationQuestion from "components/NewQuestions/CalculationQuestion";
import { makeStyles } from "@mui/styles";
import { AppColors } from "resources/AppColors";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
        backgroundColor: AppColors.BACKGROUND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },
}));

function SecondPageAddQuestionForm({
  variableName,
  questionType,
  choiceQuestions,
  setChoiceQuestions,
  hasConditional,
  questionConditionals,
  setQuestionConditionals,
  possibleConditionalQuestions,
  formTitle,
  conditionalOperator,
  handleChangeConditionalOperator,
  numericalQuestionsValues,
  handleNumericalQuestionsValues,
}) {
  const classes = useStyles()

  return (
    <>
      <Box width="100%" id="second-page-container">
        <Box display="flex" justifyContent="space-around" id="question-title">
          <TextField
            name="form"
            type="text"
            required
            disabled={true}
            label={"Survey"}
            value={formTitle}
            variant='outlined'
            margin="normal"
            className={classes.textField}
          />
          <TextField
            name="variableName"
            type="text"
            required
            disabled={true}
            label={"Variable Name"}
            value={variableName}
            variant='outlined'
            margin="normal"
            className={classes.textField}
          />
        </Box>
        <ConditionalQuestions
          hasConditional={hasConditional}
          questionConditionals={questionConditionals}
          setQuestionConditionals={setQuestionConditionals}
          conditionalOperator={conditionalOperator}
          handleChangeConditionalOperator={handleChangeConditionalOperator}
          possibleConditionalQuestions={possibleConditionalQuestions}
        />
        <NumericQuestion
          numericalQuestionsValues={numericalQuestionsValues}
          handleNumericalQuestionsValues={handleNumericalQuestionsValues}
          questionType={questionType}
        />
        <RangeQuestion
          numericalQuestionsValues={numericalQuestionsValues}
          handleNumericalQuestionsValues={handleNumericalQuestionsValues}
          questionType={questionType}
        />
        <CalculationQuestion
          numericalQuestionsValues={numericalQuestionsValues}
          handleNumericalQuestionsValues={handleNumericalQuestionsValues}
          questionType={questionType}
        />
        <ChoiceQuestion
          choiceQuestions={choiceQuestions}
          setChoiceQuestions={setChoiceQuestions}
          questionType={questionType}
        />
      </Box>
    </>
  )
}

export default SecondPageAddQuestionForm