/*
export const dashboardMock = [
    {
        "success": true,
        "code": 0,
        "locale": "en",
        "message": "OK",
        "data": {
            "items": [
                {
                    "id": 3,
                    "participant_id": 28,
                    "form_id": 90,
                    "saved_date": "2021-12-09 08:55:02",
                    "data_source": "data-source-data",
                    "created_at": "2021-12-09T07:55:02.000000Z",
                    "updated_at": "2021-12-09T07:55:02.000000Z",
                    "deleted_at": null,
                    "answers": [
                        {
                            "id": 5,
                            "form_answer_id": 3,
                            "question_id": 117,
                            "data": "33",
                            "created_at": "2021-12-09T07:55:02.000000Z",
                            "updated_at": "2021-12-09T07:55:02.000000Z",
                            "deleted_at": null
                        },
                        {
                            "id": 6,
                            "form_answer_id": 3,
                            "question_id": 113,
                            "data": "covid negatiu de moment",
                            "created_at": "2021-12-09T07:55:02.000000Z",
                            "updated_at": "2021-12-09T07:55:02.000000Z",
                            "deleted_at": null
                        }
                    ],
                    "participant": {
                        "id": 28,
                        "consent_form": 0,
                        "patient_id": 4,
                        "created_at": "2021-11-30T11:40:23.000000Z",
                        "updated_at": "2021-11-30T11:40:23.000000Z",
                        "study_id": 175,
                        "deleted_at": null,
                        "patient": {
                            "id": 4,
                            "user_id": 9,
                            "tags": null,
                            "created_at": "2021-09-10T12:14:55.000000Z",
                            "updated_at": "2021-09-10T12:14:55.000000Z",
                            "gender": null,
                            "birth_date": null,
                            "comorbidities": null,
                            "user": {
                                "id": 9,
                                "uuid": "971c21e8-4b0c-4e04-aabf-c0fa7c428e1b",
                                "username": "testing-user-2",
                                "name": null,
                                "surname": null,
                                "email": "dev2@universaldoctor.com",
                                "email_verified_at": null,
                                "anonymous": 0,
                                "api_token": null,
                                "change_password_required": 1,
                                "created_at": "2021-09-10T12:14:55.000000Z",
                                "updated_at": "2021-09-10T12:14:55.000000Z"
                            }
                        }
                    }
                }
            ]
        }
    }
]
*/

export const dashboardMock = [
  {
    "success": true,
    "code": 0,
    "locale": "en",
    "message": "OK",
    "data": {
      "item": [
        {
          "id": 70,
          "saved_date": "2021-12-21 10:49:02",
          "data_source": "data-source-data",
          "created_at": "2021-12-21T09:49:02.000000Z",
          "updated_at": "2021-12-21T09:49:02.000000Z",
          "user": null,
          "participant": null,
          "form": {
            "id": 90,
            "slug": "inclusion-form",
            "internal_code": "inclusion_form",
            "public_url": "https://forms.uhda.health/inclusion-form",
            "periodicity": null,
            "finish_date": "2022-03-09 00:00:00",
            "form_status": 1,
            "visibility": "0",
            "conditional": 0,
            "private": 0,
            "personal": 0,
            "date_creation": "1982-03-09 00:00:00",
            "created_at": "2021-11-19T11:10:23.000000Z",
            "updated_at": "2021-11-30T12:19:00.000000Z",
            "form_translations": [
              {
                "id": 177,
                "locale": "en",
                "title": "Inclusion form  ",
                "subtitle": "UK2 Lorem ipsum...",
                "description": "Form to use on the date of inclusion to collect some variables regarding general health of the participants. "
              },
              {
                "id": 178,
                "locale": "es",
                "title": "Inclusion form  ",
                "subtitle": "ES2 Lorem ipsum...",
                "description": "Form to use on the date of inclusion to collect some variables regarding general health of the participants. "
              }
            ]
          },
          "answers": [
            {
              "id": 178,
              "data": "33",
              "data_label": "33",
              "question": {
                "id": 117,
                "form_id": 90,
                "order": 4,
                "variable_name": "age",
                "standard_name": null,
                "mandatory": 1,
                "identificable": 0,
                "tags": null,
                "type": "NUMERIC",
                "min_value": 18,
                "max_value": 80,
                "interval": null,
                "units": "Years old",
                "default_answer": null,
                "validation": null,
                "conditional": null,
                "visibility": null,
                "library_question_id": null,
                "account_id": null,
                "question_translations": [
                  {
                    "id": 96,
                    "locale": "en",
                    "question": "Participant's age",
                    "subtitle": "",
                    "info": "",
                    "range_labels": null,
                    "error_message": null
                  }
                ],
                "answer_values": [
                  {
                    "id": 108,
                    "value": "",
                    "translations": [
                      {
                        "id": 108,
                        "locale": "en",
                        "text": ""
                      }
                    ]
                  },
                  {
                    "id": 109,
                    "value": "",
                    "translations": [
                      {
                        "id": 109,
                        "locale": "en",
                        "text": ""
                      }
                    ]
                  }
                ],
                "answer": -9999,
                "created_at": "2021-11-19T11:15:12.000000Z",
                "updated_at": "2021-11-19T11:31:32.000000Z"
              },
              "created_at": "2021-12-21T09:49:02.000000Z",
              "updated_at": "2021-12-21T09:49:02.000000Z"
            },
            {
              "id": 179,
              "data": "covid negatiu de moment",
              "data_label": "covid negatiu de moment",
              "question": {
                "id": 113,
                "form_id": 90,
                "order": 1,
                "variable_name": "patient-code",
                "standard_name": null,
                "mandatory": 1,
                "identificable": 0,
                "tags": null,
                "type": "FREE TEXT",
                "min_value": null,
                "max_value": null,
                "interval": null,
                "units": "",
                "default_answer": null,
                "validation": null,
                "conditional": "",
                "visibility": null,
                "library_question_id": null,
                "account_id": null,
                "question_translations": [
                  {
                    "id": 92,
                    "locale": "en",
                    "question": "Patient code",
                    "subtitle": "",
                    "info": "",
                    "range_labels": null,
                    "error_message": null
                  }
                ],
                "answer_values": [],
                "answer": -9999,
                "created_at": "2021-11-19T11:11:30.000000Z",
                "updated_at": "2021-11-19T11:34:46.000000Z"
              },
              "created_at": "2021-12-21T09:49:02.000000Z",
              "updated_at": "2021-12-21T09:49:02.000000Z"
            }
          ]
        },
        {
          "id": 71,
          "saved_date": "2021-12-21 10:49:03",
          "data_source": "data-source-data",
          "created_at": "2021-12-21T09:49:03.000000Z",
          "updated_at": "2021-12-21T09:49:03.000000Z",
          "user": null,
          "participant": null,
          "form": {
            "id": 90,
            "slug": "inclusion-form",
            "internal_code": "inclusion_form",
            "public_url": "https://forms.uhda.health/inclusion-form",
            "periodicity": null,
            "finish_date": "2022-03-09 00:00:00",
            "form_status": 1,
            "visibility": "0",
            "conditional": 0,
            "private": 0,
            "personal": 0,
            "date_creation": "1982-03-09 00:00:00",
            "created_at": "2021-11-19T11:10:23.000000Z",
            "updated_at": "2021-11-30T12:19:00.000000Z",
            "form_translations": [
              {
                "id": 177,
                "locale": "en",
                "title": "Inclusion form  ",
                "subtitle": "UK2 Lorem ipsum...",
                "description": "Form to use on the date of inclusion to collect some variables regarding general health of the participants. "
              },
              {
                "id": 178,
                "locale": "es",
                "title": "Inclusion form  ",
                "subtitle": "ES2 Lorem ipsum...",
                "description": "Form to use on the date of inclusion to collect some variables regarding general health of the participants. "
              }
            ]
          },
          "answers": [
            {
              "id": 180,
              "data": "33",
              "data_label": "33",
              "question": {
                "id": 117,
                "form_id": 90,
                "order": 4,
                "variable_name": "age",
                "standard_name": null,
                "mandatory": 1,
                "identificable": 0,
                "tags": null,
                "type": "NUMERIC",
                "min_value": 18,
                "max_value": 80,
                "interval": null,
                "units": "Years old",
                "default_answer": null,
                "validation": null,
                "conditional": null,
                "visibility": null,
                "library_question_id": null,
                "account_id": null,
                "question_translations": [
                  {
                    "id": 96,
                    "locale": "en",
                    "question": "Participant's age",
                    "subtitle": "",
                    "info": "",
                    "range_labels": null,
                    "error_message": null
                  }
                ],
                "answer_values": [
                  {
                    "id": 108,
                    "value": "",
                    "translations": [
                      {
                        "id": 108,
                        "locale": "en",
                        "text": ""
                      }
                    ]
                  },
                  {
                    "id": 109,
                    "value": "",
                    "translations": [
                      {
                        "id": 109,
                        "locale": "en",
                        "text": ""
                      }
                    ]
                  }
                ],
                "answer": -9999,
                "created_at": "2021-11-19T11:15:12.000000Z",
                "updated_at": "2021-11-19T11:31:32.000000Z"
              },
              "created_at": "2021-12-21T09:49:03.000000Z",
              "updated_at": "2021-12-21T09:49:03.000000Z"
            },
            {
              "id": 181,
              "data": "covid negatiu de moment",
              "data_label": "covid negatiu de moment",
              "question": {
                "id": 113,
                "form_id": 90,
                "order": 1,
                "variable_name": "patient-code",
                "standard_name": null,
                "mandatory": 1,
                "identificable": 0,
                "tags": null,
                "type": "FREE TEXT",
                "min_value": null,
                "max_value": null,
                "interval": null,
                "units": "",
                "default_answer": null,
                "validation": null,
                "conditional": "",
                "visibility": null,
                "library_question_id": null,
                "account_id": null,
                "question_translations": [
                  {
                    "id": 92,
                    "locale": "en",
                    "question": "Patient code",
                    "subtitle": "",
                    "info": "",
                    "range_labels": null,
                    "error_message": null
                  }
                ],
                "answer_values": [],
                "answer": -9999,
                "created_at": "2021-11-19T11:11:30.000000Z",
                "updated_at": "2021-11-19T11:34:46.000000Z"
              },
              "created_at": "2021-12-21T09:49:03.000000Z",
              "updated_at": "2021-12-21T09:49:03.000000Z"
            }
          ]
        },
        {
          "id": 72,
          "saved_date": "2021-12-21 12:07:59",
          "data_source": "data-source-data",
          "created_at": "2021-12-21T11:07:59.000000Z",
          "updated_at": "2021-12-21T11:07:59.000000Z",
          "user": null,
          "participant": null,
          "form": {
            "id": 90,
            "slug": "inclusion-form",
            "internal_code": "inclusion_form",
            "public_url": "https://forms.uhda.health/inclusion-form",
            "periodicity": null,
            "finish_date": "2022-03-09 00:00:00",
            "form_status": 1,
            "visibility": "0",
            "conditional": 0,
            "private": 0,
            "personal": 0,
            "date_creation": "1982-03-09 00:00:00",
            "created_at": "2021-11-19T11:10:23.000000Z",
            "updated_at": "2021-11-30T12:19:00.000000Z",
            "form_translations": [
              {
                "id": 177,
                "locale": "en",
                "title": "Inclusion form  ",
                "subtitle": "UK2 Lorem ipsum...",
                "description": "Form to use on the date of inclusion to collect some variables regarding general health of the participants. "
              },
              {
                "id": 178,
                "locale": "es",
                "title": "Inclusion form  ",
                "subtitle": "ES2 Lorem ipsum...",
                "description": "Form to use on the date of inclusion to collect some variables regarding general health of the participants. "
              }
            ]
          },
          "answers": [
            {
              "id": 182,
              "data": "33",
              "data_label": "33",
              "question": {
                "id": 117,
                "form_id": 90,
                "order": 4,
                "variable_name": "age",
                "standard_name": null,
                "mandatory": 1,
                "identificable": 0,
                "tags": null,
                "type": "NUMERIC",
                "min_value": 18,
                "max_value": 80,
                "interval": null,
                "units": "Years old",
                "default_answer": null,
                "validation": null,
                "conditional": null,
                "visibility": null,
                "library_question_id": null,
                "account_id": null,
                "question_translations": [
                  {
                    "id": 96,
                    "locale": "en",
                    "question": "Participant's age",
                    "subtitle": "",
                    "info": "",
                    "range_labels": null,
                    "error_message": null
                  }
                ],
                "answer_values": [
                  {
                    "id": 108,
                    "value": "",
                    "translations": [
                      {
                        "id": 108,
                        "locale": "en",
                        "text": ""
                      }
                    ]
                  },
                  {
                    "id": 109,
                    "value": "",
                    "translations": [
                      {
                        "id": 109,
                        "locale": "en",
                        "text": ""
                      }
                    ]
                  }
                ],
                "answer": -9999,
                "created_at": "2021-11-19T11:15:12.000000Z",
                "updated_at": "2021-11-19T11:31:32.000000Z"
              },
              "created_at": "2021-12-21T11:07:59.000000Z",
              "updated_at": "2021-12-21T11:07:59.000000Z"
            },
            {
              "id": 183,
              "data": "covid negatiu de moment",
              "data_label": "covid negatiu de moment",
              "question": {
                "id": 113,
                "form_id": 90,
                "order": 1,
                "variable_name": "patient-code",
                "standard_name": null,
                "mandatory": 1,
                "identificable": 0,
                "tags": null,
                "type": "FREE TEXT",
                "min_value": null,
                "max_value": null,
                "interval": null,
                "units": "",
                "default_answer": null,
                "validation": null,
                "conditional": "",
                "visibility": null,
                "library_question_id": null,
                "account_id": null,
                "question_translations": [
                  {
                    "id": 92,
                    "locale": "en",
                    "question": "Patient code",
                    "subtitle": "",
                    "info": "",
                    "range_labels": null,
                    "error_message": null
                  }
                ],
                "answer_values": [],
                "answer": -9999,
                "created_at": "2021-11-19T11:11:30.000000Z",
                "updated_at": "2021-11-19T11:34:46.000000Z"
              },
              "created_at": "2021-12-21T11:07:59.000000Z",
              "updated_at": "2021-12-21T11:07:59.000000Z"
            },
            {
              "id": 184,
              "data": "covid negatiu de moment",
              "data_label": "covid negatiu de moment",
              "question": {
                "id": 113,
                "form_id": 90,
                "order": 1,
                "variable_name": "patient-code",
                "standard_name": null,
                "mandatory": 1,
                "identificable": 0,
                "tags": null,
                "type": "FREE TEXT",
                "min_value": null,
                "max_value": null,
                "interval": null,
                "units": "",
                "default_answer": null,
                "validation": null,
                "conditional": "",
                "visibility": null,
                "library_question_id": null,
                "account_id": null,
                "question_translations": [
                  {
                    "id": 92,
                    "locale": "en",
                    "question": "Patient code",
                    "subtitle": "",
                    "info": "",
                    "range_labels": null,
                    "error_message": null
                  }
                ],
                "answer_values": [],
                "answer": -9999,
                "created_at": "2021-11-19T11:11:30.000000Z",
                "updated_at": "2021-11-19T11:34:46.000000Z"
              },
              "created_at": "2021-12-21T11:07:59.000000Z",
              "updated_at": "2021-12-21T11:07:59.000000Z"
            }
          ]
        },
        {
          "id": 79,
          "saved_date": "2022-01-10 10:20:39",
          "data_source": "data-source-data",
          "created_at": "2022-01-10T09:20:39.000000Z",
          "updated_at": "2022-01-10T09:20:39.000000Z",
          "user": {
            "id": 4,
            "uuid": "6051f123-5c7b-42cf-bbce-bb931044988a",
            "username": "testing-user",
            "name": "testing",
            "surname": "user",
            "email": "dev@universaldoctor.com",
            "email_verified_at": "2021-08-17T13:01:41.000000Z",
            "anonymous": 0,
            "created_at": "2021-08-17T13:01:30.000000Z",
            "updated_at": "2021-12-23T10:28:47.000000Z"
          },
          "participant": {
            "id": 60,
            "uuid": "9fa74fc3-be3e-4bef-8f7c-bab001447ec9",
            "consent_form": 1,
            "patient_id": null,
            "study_id": 178
          },
          "form": {
            "id": 90,
            "slug": "inclusion-form",
            "internal_code": "inclusion_form",
            "public_url": "https://forms.uhda.health/inclusion-form",
            "periodicity": null,
            "finish_date": "2022-03-09 00:00:00",
            "form_status": 1,
            "visibility": "0",
            "conditional": 0,
            "private": 0,
            "personal": 0,
            "date_creation": "1982-03-09 00:00:00",
            "created_at": "2021-11-19T11:10:23.000000Z",
            "updated_at": "2021-11-30T12:19:00.000000Z",
            "form_translations": [
              {
                "id": 177,
                "locale": "en",
                "title": "Inclusion form  ",
                "subtitle": "UK2 Lorem ipsum...",
                "description": "Form to use on the date of inclusion to collect some variables regarding general health of the participants. "
              },
              {
                "id": 178,
                "locale": "es",
                "title": "Inclusion form  ",
                "subtitle": "ES2 Lorem ipsum...",
                "description": "Form to use on the date of inclusion to collect some variables regarding general health of the participants. "
              }
            ]
          },
          "answers": [
            {
              "id": 207,
              "data": "33",
              "data_label": "33",
              "question": {
                "id": 117,
                "form_id": 90,
                "order": 4,
                "variable_name": "age",
                "standard_name": null,
                "mandatory": 1,
                "identificable": 0,
                "tags": null,
                "type": "NUMERIC",
                "min_value": 18,
                "max_value": 80,
                "interval": null,
                "units": "Years old",
                "default_answer": null,
                "validation": null,
                "conditional": null,
                "visibility": null,
                "library_question_id": null,
                "account_id": null,
                "question_translations": [
                  {
                    "id": 96,
                    "locale": "en",
                    "question": "Participant's age",
                    "subtitle": "",
                    "info": "",
                    "range_labels": null,
                    "error_message": null
                  }
                ],
                "answer_values": [
                  {
                    "id": 108,
                    "value": "",
                    "translations": [
                      {
                        "id": 108,
                        "locale": "en",
                        "text": ""
                      }
                    ]
                  },
                  {
                    "id": 109,
                    "value": "",
                    "translations": [
                      {
                        "id": 109,
                        "locale": "en",
                        "text": ""
                      }
                    ]
                  }
                ],
                "answer": -9999,
                "created_at": "2021-11-19T11:15:12.000000Z",
                "updated_at": "2021-11-19T11:31:32.000000Z"
              },
              "created_at": "2022-01-10T09:20:39.000000Z",
              "updated_at": "2022-01-10T09:20:39.000000Z"
            },
            {
              "id": 208,
              "data": "covid negatiu de moment",
              "data_label": "covid negatiu de moment",
              "question": {
                "id": 113,
                "form_id": 90,
                "order": 1,
                "variable_name": "patient-code",
                "standard_name": null,
                "mandatory": 1,
                "identificable": 0,
                "tags": null,
                "type": "FREE TEXT",
                "min_value": null,
                "max_value": null,
                "interval": null,
                "units": "",
                "default_answer": null,
                "validation": null,
                "conditional": "",
                "visibility": null,
                "library_question_id": null,
                "account_id": null,
                "question_translations": [
                  {
                    "id": 92,
                    "locale": "en",
                    "question": "Patient code",
                    "subtitle": "",
                    "info": "",
                    "range_labels": null,
                    "error_message": null
                  }
                ],
                "answer_values": [],
                "answer": -9999,
                "created_at": "2021-11-19T11:11:30.000000Z",
                "updated_at": "2021-11-19T11:34:46.000000Z"
              },
              "created_at": "2022-01-10T09:20:39.000000Z",
              "updated_at": "2022-01-10T09:20:39.000000Z"
            }
          ]
        }
      ]
    }
  }
]

export const dashboardMockGood = [
  {
    "id": 3,
    "participant": 28,
    "answer1": "[null]33",
    "answer3": "-",
    "answer4": "-",
    "answer5": "-",
    "answer6": "-",
    "answer2": "[null]covid negatiu de moment",
    "submitted_at": "09-12-2021 08:55",
    "user": "Anonymous"
  },
  {
    "id": 4,
    "participant": 28,
    "answer1": "-",
    "answer3": "-",
    "answer4": "-",
    "answer5": "-",
    "answer6": "-",
    "answer2": "-",
    "submitted_at": "09-12-2021 11:26",
    "user": "Anonymous"
  },
  {
    "id": 5,
    "participant": 28,
    "answer1": "[Label for the data]33",
    "answer3": "-",
    "answer4": "-",
    "answer5": "-",
    "answer6": "-",
    "answer2": "[covid negatiu de moment]covid negatiu de moment",
    "submitted_at": "09-12-2021 11:27",
    "user": "Anonymous"
  },
  {
    "id": 6,
    "participant": 28,
    "answer1": "[Label for the data]33",
    "answer3": "-",
    "answer4": "-",
    "answer5": "-",
    "answer6": "-",
    "answer2": "[covid negatiu de moment]covid negatiu de moment",
    "submitted_at": "09-12-2021 12:18",
    "user": "Anonymous"
  },
  {
    "id": 16,
    "participant": 42,
    "answer1": "[PATIENT_TEST]PATIENT_TEST",
    "answer2": "[2021-12-10]2021-12-10",
    "answer3": "[55]55",
    "answer4": "[No]0",
    "answer5": "[Yes]1",
    "answer6": "[Antigen]2",
    "submitted_at": "10-12-2021 11:10",
    "user": "Anonymous"
  },
  {
    "id": 17,
    "participant": 43,
    "answer1": "[TEST2]TEST2",
    "answer2": "[2021-12-14]2021-12-14",
    "answer3": "[44]44",
    "answer4": "[Yes]1",
    "answer5": "[Yes]1",
    "answer6": "[Antibody]3",
    "submitted_at": "10-12-2021 11:12",
    "user": "Anonymous"
  },
  {
    "id": 17,
    "participant": 43,
    "answer1": "[TEST2]TEST2",
    "answer2": "[2021-12-14]2021-12-14",
    "answer3": "[44]44",
    "answer4": "[Yes]1",
    "answer5": "[Yes]1",
    "answer6": "[Antibody]3",
    "submitted_at": "10-12-2021 11:12",
    "user": "Anonymous"
  },
  {
    "id": 17,
    "participant": 43,
    "answer1": "[TEST2]TEST2",
    "answer2": "[2021-12-14]2021-12-14",
    "answer3": "[44]44",
    "answer4": "[Yes]1",
    "answer5": "[Yes]1",
    "answer6": "[Antibody]3",
    "submitted_at": "10-12-2021 11:12",
    "user": "Anonymous"
  },
  {
    "id": 17,
    "participant": 43,
    "answer1": "[TEST2]TEST2",
    "answer2": "[2021-12-14]2021-12-14",
    "answer3": "[44]44",
    "answer4": "[Yes]1",
    "answer5": "[Yes]1",
    "answer6": "[Antibody]3",
    "submitted_at": "10-12-2021 11:12",
    "user": "Anonymous"
  },
  {
    "id": 17,
    "participant": 43,
    "answer1": "[TEST2]TEST2",
    "answer2": "[2021-12-14]2021-12-14",
    "answer3": "[44]44",
    "answer4": "[Yes]1",
    "answer5": "[Yes]1",
    "answer6": "[Antibody]3",
    "submitted_at": "10-12-2021 11:12",
    "user": "Anonymous"
  },
  {
    "id": 17,
    "participant": 43,
    "answer1": "[TEST2]TEST2",
    "answer2": "[2021-12-14]2021-12-14",
    "answer3": "[44]44",
    "answer4": "[Yes]1",
    "answer5": "[Yes]1",
    "answer6": "[Antibody]3",
    "submitted_at": "10-12-2021 11:12",
    "user": "Anonymous"
  }
]

export const dashboardMockEmpty = []
